import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Navigate, useRoutes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const LoginProtected = ({ element }) => {
  const location = useLocation();
  
  function isValidId(str) {
    // Regular expression to match a MongoDB ObjectId
    const objectIdRegex = /^[0-9a-fA-F]{24}$/;
    return objectIdRegex.test(str);
  }

    let path = location.pathname.split('/').filter((path=> path!=='')) // convert path to array of strings

    const lastElement = path.slice(-1).toString()     // getting last element of array

    if(isValidId(lastElement)){
      path.pop()                                        // checking if length of last element is 24
    }                                                   // it means it is id and removing this id from array 

    const pathe = path.toString().replaceAll(',', '/')  // again adding slashes / to the string


    const userRoutes = [
    'user/quiz',
    'user/evaluation-done',
    'user/subscription',
    'user/payment',
    'user/profile',
    'user/subscription/diamond',
    'user/quiz/insights',
    'user/dashboard',
    'user/quiz/my-quiz',
    'user/quiz/evaluation-done',
    'user/quiz/friends-quiz',
    'user/profile/edit',
    'user/quiz/re-evaluate',
  ];

  const adminRoutes = ['admin/revenue', 'admin/users', 'admin/dashboard', 'admin/profile', 'admin/profile/edit'];
  
  const user = useSelector((state) => state.user)

  if (user === null) {
    return <Navigate to='/' replace />;
  } else if(user.first_name === null){
    return <Navigate to='/complete-profile' replace />;
  } else {

    if (user.role === 'user') {
      if (userRoutes.includes(pathe)) {
        return element;
      } else {
       return <Navigate to='/error403' replace />;
      }
    }
    else if(user.role === 'admin'){
    
      if (adminRoutes.includes(pathe)) return element;
      else {
       return <Navigate to='/error403' replace />;
      }
    }
  }
};

LoginProtected.propTypes = {
  element: PropTypes.element,
};

export default LoginProtected;
