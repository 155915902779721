import React from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import TrialStatus from '../components/TrialStatus'

import SideBar from '../components/SideBar'

import Header from '../components/Header'

import { Outlet } from 'react-router-dom'

import { useSelector } from 'react-redux'


const Dashboard = () => {

  const user = useSelector((state)=>state.user)
  const isAdmin = user?.role === 'admin'
  return (
    <>
      <Header isAdmin={isAdmin}/>
      {user.active_subscription_id === ''?<div className='md:hidden text-center'><TrialStatus/></div>:null}
      <div className='md:hidden'><Breadcrumbs/></div>
      <div className='flex'>

        <SideBar isAdmin={isAdmin}/>
        <Outlet/>
      </div>
    </>
  )
}

export default Dashboard
