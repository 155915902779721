import React from 'react';
import CompleteProfileModalWindow from './CompleteProfileModalWindow';
import { ReactComponent as Question } from '../images/question-color.svg';
import { ReactComponent as Response } from '../images/response-color.svg';
import Chart from 'react-apexcharts';
import humbleIcon from '../images/humble_ic.png';
import SelfAwareIcon from '../images/selfaware.png';
import LittleProudIcon from '../images/littleproud.png';
import SelfDepricatingIcon from '../images/selfdepricating.png';
import NeedAttentionIcon from '../images/needattention.png';
import Header from '../components/Header';
import SideBar from '../components/SideBar';



const tableData = [
  {
    id: 1,
    title: 'How well does this describe you?',
    views: 95,
    responses: 80,
  },

];

const graphData = {
  series: [
    {
      name: '',
      data: [10, 41, 60, 51, 20, 270, 45, 76, 32],
    },
  ],

  options: {
    chart: {
      height: 350,
      type: 'area',
      toolbar: { show: false },
      zoom: {
        enabled: false,
      },
    },
    colors: ['#22CC9B', '#F8C300'],
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      fixed: {
        enabled: false,
        position: 'bottomRight',
        offsetX: 0,
        offsetY: 1,
    },
    },
    stroke: {
      curve: 'smooth',
      width: 2.5,
    },
   
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    },

    yaxis: {
      labels: {
        formatter: (val) => {
          return `${val}K`;
        },
        style: { colors: ['#AAA492'] },
      },
    },
  },
};

const CompleteProfile = () => {

  return (
    <>
    <CompleteProfileModalWindow show={true}/>
    <Header/>
    <SideBar/>
    
    <div className='w-[100vw] md:w-[calc(97.8vw-223px)] lg:w-[calc(97.8vw-217px)] 2xl:w-[calc(98.7vw-220px)] h-[calc(100vh-3.6rem)] md:h-[calc(100vh-4rem)] flex md:ml-60 bg-gray-100'>
      <div className='w-full h-[calc(100vh-4rem)] px-4 md:px-10 py-4 flex flex-col gap-6 overflow-y-scroll lg:overflow-auto'>
        <h2 className='font-semibold'>Overview</h2>
        <div className='grid grid-cols-2 lg:grid-cols-3 gap-3 lg:gap-5 relative'>
          <div className='box1'>
            <div className='lef flex gap-2 sm:gap-4 p-5 2xl:p-6 h-full rounded-lg bg-white items-center'>
              <div className='icon1 bg-[#7EB1FC] w-fit p-2 2xl:p-3 rounded-full'>
                <Question />
              </div>
              <div className='content flex flex-col gap-1 text-sm'>
                <h1 className='text-xl font-bold 2xl:text-2xl'>4</h1>
                <p className='text-gray-400 text-xs md:text-sm 2xl:text-base'>My Responses to Friends</p>
              </div>
            </div>
          </div>
          <div className='box2'>
            <div className='rit flex gap-2 sm:gap-4 p-5 2xl:p-6 h-full rounded-lg bg-white items-center'>
              <div className='icon1 bg-[#E091C9] p-2 2xl:p-3 w-fit rounded-full'>
                <Response />
              </div>
              <div className='content flex flex-col gap-1 text-sm'>
                <h1 className='text-xl font-bold 2xl:text-2xl'>2</h1>
                <p className='text-gray-400 text-xs md:text-sm 2xl:text-base'>My Friends’ Responses</p>
              </div>
            </div>
          </div>
          <div className='box3 row-span-5 row-start-2 lg:row-start-auto col-span-2 lg:col-span-1 '>
            <div className='sidebar w-full rounded-lg flex flex-col gap-3'>
              <div className='upper flex flex-col relative group p-5 rounded-lg bg-white gap-3'>
                
                <h3 className='font-semibold 2xl:text-lg '>Prominent Traits</h3>
                <div className='flex justify-between items-center '>
                  <div className='flex justify-start items-center gap-3'>
                    <img className='w-7' src={humbleIcon} alt='' />{' '}
                    <h4 className='text-sm 2xl:text-base'>Humble</h4>
                  </div>
                  <span className={`text-sm 2xl:text-base`}>65%</span>
                </div>
                <div className='flex justify-between items-center '>
                  <div className='flex justify-start items-center gap-3'>
                    <img className='w-7' src={SelfAwareIcon} alt='' />{' '}
                    <h4 className='text-sm 2xl:text-base'>Self-Aware</h4>
                  </div>
                  <span className={`text-sm 2xl:text-base`}>23%</span>
                </div>
                <div className='flex justify-between items-center '>
                  <div className='flex justify-start items-center gap-3'>
                    <img className='w-7' src={LittleProudIcon} alt='' />{' '}
                    <h4 className='text-sm 2xl:text-base'>Little Proud</h4>
                  </div>
                  <span className={`text-sm 2xl:text-base`}>67%</span>
                </div>
                <div className='flex justify-between items-center '>
                  <div className='flex justify-start items-center gap-3'>
                    <img className='w-7' src={SelfDepricatingIcon} alt='' />{' '}
                    <h4 className='text-sm 2xl:text-base'>Self-Deprecating</h4>
                  </div>
                  <span className={`text-sm 2xl:text-base`}>54%</span>
                </div>
                <div className='flex justify-between items-center '>
                  <div className='flex justify-start items-center gap-3'>
                    <img className='w-7' src={NeedAttentionIcon} alt='' />{' '}
                    <h4 className='text-sm 2xl:text-base'>Needs Attention</h4>
                  </div>
                  <span className={`text-sm 2xl:text-base`}>34%</span>
                </div>

                
              </div>


              {/* //////////////////////////////////////////////////////////////////// */}
              <div className='lower bg-white p-5 rounded-lg flex flex-col gap-3'>
                <p className='font-semibold 2xl:text-lg'>This Month</p>
                <div className='questionnaire-count rounded-lg flex flex-col gap-3 items-center p-6 border-2'>
                  <h1 className='text-2xl font-semibold'>3</h1>
                  <h3>Questionnaires</h3>
                </div>

                <div className='progres flex flex-col gap-2'>
                  <div className='flex justify-between items-center'>
                    <h3 className='2xl:text-lg'>Completion Rate</h3>
                    <span className='text-sm 2xl:text-lg'>{45}%</span>
                  </div>
                  <div className='mb-1 h-[0.5rem] w-full bg-gray-300 dark:bg-neutral-600 rounded-lg'>
                    <div
                      className='h-[0.5rem] btn-color-primary rounded-lg'
                      style={{ width: `${45}%` }}
                    ></div>
                  </div>
                </div>

                <div className='progres flex flex-col gap-2'>
                  <div className='flex justify-between items-center'>
                    <h3 className='2xl:text-lg'>Attempted</h3>
                    <span className='text-sm 2xl:text-lg'>56%</span>
                  </div>
                  <div className='mb-1 h-[0.5rem] w-full bg-gray-300 dark:bg-neutral-600 rounded-lg'>
                    <div
                      className='h-[0.5rem] bg-[#7EB1FC] rounded-lg'
                      style={{ width: `${56}%` }}
                    ></div>
                  </div>
                </div>

                
              </div>
            </div>
          </div>
          <div className='box4  col-span-2 row-span-5 rounded-lg flex flex-col gap-3 lg:gap-5'>
            <div className='tbl p-1 bg-white rounded-lg'>
              <div className=' flex justify-between items-center p-4'>
                <h2 className='font-semibold 2xl:text-lg'>Popular Questionnaire</h2>
                <button className='text-sm 2xl:text-base'>View all</button>
              </div>
              <table className=' text-[10px] md:text-sm 2xl:text-base w-full overflow-x-scroll text-left text-black bg-white'>
                <thead className='text-xs md:text-sm 2xl:text-lg text-gray-400  capitalize border-y'>
                  <tr>
                    <th scope='col' className='py-2 px-3 tracking-wider'>
                      Title
                    </th>
                    <th scope='col' className='py-2 px-3 tracking-wider'>
                      Views
                    </th>
                    <th scope='col' className='py-2 px-3 tracking-wider'>
                      Responses
                    </th>
                    <th scope='col' className='py-2 px-3 tracking-wider'>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(({ id, title, views, responses }) => {
                    return (
                      <tr
                        key={id}
                        className={`${
                          id % 2 === 0 ? 'bg-gray-100' : 'bg-white'
                        }`}
                      >
                        <td className='py-2 px-3'>{title}</td>
                        <td className='py-2 px-3'>5</td>
                        <td className='py-2 px-3'>2</td>
                        <td className='py-2 px-3'>
                          <button className='hover:text-[#22CC9B]'>View insights</button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className='graph bg-white rounded-lg p-6'>
              <div className='graph-header flex flex-col gap-2'>
                <p className='font-semibold 2xl:text-lg'>Respondents Frequency</p>
                <div className='country-select flex flex-col gap-1 '>
              <select
                className='p-2 outline-none border-[1px] w-full rounded-lg text-sm 2xl:text-base focus:shadow-green-500 shadow-sm'
                name='gender'
              > 
                <option className='w-20'>Select</option>
                <option className='w-20' value='male'>
                  Last 1 year
                </option>
                <option className='w-20' value='female'>
                Last 2 year
                </option>
                <option className='w-20' value='others'>
                Last 3 year
                </option>
              </select>
            </div>
              </div>
            <Chart options={graphData.options} series={graphData.series} height={250} type='area'/>
            </div>
          </div>
        </div>
      </div>
    </div>
        
      </>
  );
};

export default CompleteProfile;
