import { createSlice } from '@reduxjs/toolkit';

export const UserSlice = createSlice({
    name: 'user',
    initialState: null,
    reducers: {
        setUser: (state, action) => {
            return action.payload;
        }
    },
});

export const { setUser } = UserSlice.actions;

export const authUser = (state) => state.user;
export default UserSlice.reducer;
