
import loaderSVG from "../images/Loader_boxes.svg";

const Loader = () => {
    return (
        <div className="backdrop__filter flex items-center justify-center">
            <img
                src={loaderSVG}
                alt="Loading-bar"
                height={200}
                width={200}
            />
        </div>
    )
}

export default Loader
