import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {ReactComponent as ViewsIcon} from '../images/views.svg'
import {ReactComponent as ResponseIcon} from '../images/response_ic.svg'
import ModalWindow from '../components/ModalWindow'
import {ReactComponent as CopyBtn} from '../images/copy_btn.svg'
import { TbBrandWhatsapp } from "react-icons/tb";
import { BsTwitterX } from "react-icons/bs";
import { GrFacebookOption } from "react-icons/gr";
import { RiLinkedinFill } from "react-icons/ri";
import { FaInstagram } from "react-icons/fa";
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";
import Loader from '../components/Loader';
import {useDispatch, useSelector} from 'react-redux';
import {authUser, setUser} from '../redux/slices/UserSlice';
import axios from 'axios';
import {isDesktop, isMobile, osName} from "react-device-detect";
import '../pages/checkbox.css'
import { Link } from 'react-router-dom'
import CopyToClipboard from 'react-copy-to-clipboard'
import {setError} from "../redux/slices/LoginErrorSlice";
import {setEmail} from "../redux/slices/LoginPageSlice";
import {setQuestionnaireId} from "../redux/slices/QuestionnaireIdSlice";
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
  } from "@material-tailwind/react";
  import PropTypes from "prop-types";

const ReEvaluate = () => {
    
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isChecked, setIsChecked] = useState(false)
    const [disable, setDisable] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState({})
    const [pageRefresh, setPageRefresh] = useState(0)
    const {questionnaireId} = useParams()
    const user = useSelector(authUser)
    const [selectedValues, setSelectedValues] = useState({answers: []})
    const [currentPage, setCurrentPage] = useState(1);
    const [copied, setCopied] = useState(false);

    const handleRadioChange = (questionId, answer, title) => {
        // Find the index of the question in selectedValues.answers
        const questionIndex = selectedValues.answers.findIndex(
            (q) => q.questionId === questionId
        );

        // If the question is already in the array, update the answer
        if (questionIndex !== -1) {
            setSelectedValues((prevValues) => {
                const updatedAnswers = [...prevValues.answers];
                updatedAnswers[questionIndex] = {questionId, answer, title};
                return {answers: updatedAnswers};
            });
        } else {
            // If the question is not in the array, add it
            setSelectedValues((prevValues) => ({
                answers: [...prevValues.answers, {questionId, answer, title}],
            }));
        }

    };

    // ///////////////////////////// Accordion logic ///////////////////////////////////////////
    function Icon({ id, open }) {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>
        );
      }

      const [open, setOpen] = React.useState(0);
        const handleOpen = (value) => setOpen(open === value ? 0 : value);

    useEffect(() => {
        setIsChecked(true)
        setSelectedValues({answers: []})
        const saveUserStat = async () => {
            const payload = {
                isDesktop: isDesktop,
                isMobile: isMobile,
                osName: osName,
            }
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user-stat`,
                payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user?.token}`,
                         
                    },
                });
        };

        saveUserStat();

    }, []);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/questionnaire/${questionnaireId}/answers?pageNum=${currentPage}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${user?.token}`,
                        'Content-Type': 'application/json',
                         
                    },
                });

                if (response.ok) {
                    const result = await response.json();
                    setData(result.data)
                    setIsLoading(false)
                    
                    result.data.questions.map((question)=>{
                        let questionId = question.id
                        let answer = question.answers[0].answer
                        let title = question.myTitle
                
                        return(
                            setSelectedValues((prevValues) => ({
                                answers: [...prevValues.answers, {questionId, answer,title}],
                            }))
                        )
                    })

                    
                } else {
                }
            } catch (error) {
                console.log(error, 'error');
            } finally {
                setIsLoading(false);
            }
        };
        setIsLoading(true);
        fetchUserData();

    }, [navigate,pageRefresh]);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked)
    }
    const generatedLink = `${process.env.REACT_APP_PUBLIC_URL}/questionnaire/${questionnaireId}`

    const isAllQuestionsAnswered = () => {
        return data.questions.every(item =>
            selectedValues.answers.some(answer => answer.questionId === item.id)
        );
    };


    const submitValues = async () => {
        
        
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/questionnaire/${questionnaireId}/re-evaluate?pageNum=${currentPage}`,
                selectedValues, {
                    headers: {
                        'Content-Type': 'application/json',
                         Authorization: `Bearer ${user?.token}`,
                    },
                });

            if (response.status === 201) {
                if(currentPage !== data?.totalPages){
                    setSelectedValues({answers: []})
                    setPageRefresh(data.currentPage)
                    
                    // navigate(`/questionnaire/my-questionnaire/${questionnaireId}`)
                }else{
                    setModal(true)
                    // navigate('/questionnaire');
                }
            }

            if (response.status === 401 || response.status===400) {
                localStorage.clear();
                dispatch(setError('Authentication failed. Please login again.'));
                dispatch(setUser(null));
                dispatch(setEmail(""));
                dispatch(setQuestionnaireId(""));
                navigate("/");
            }

        } catch (error) {
            console.log(error, 'error')
        } finally {
            setIsLoading(false);
        }
        
        if(data.currentPage<data.totalPages){
            setCurrentPage(currentPage+1)
        }
        
    }
    /// ///////////////////////////////// Modal Logic ////////////////////////////////////////
    const [modal, setModal] = useState(false)
    const onClose = () => {
        setModal(false)
        setDisable(false)
        setIsChecked(true)
        navigate(`/user/quiz/evaluation-done/${questionnaireId}`)
    }

    const progress = ((data.currentPage) / data.totalPages) * 100;

  return (
    <>
    {isLoading ? <Loader/> :
        <div className='w-[100vw] md:w-[calc(97.8vw-223px)] lg:w-[calc(97.8vw-217px)] 2xl:w-[calc(98.7vw-220px)] h-[calc(100vh-3.6rem)] md:h-[calc(100vh-4rem)] flex md:ml-60 p-4 lg:p-0 bg-gray-100 flex-wrap'>
            <div
                className='w-full h-[calc(100vh-4rem)] flex flex-col gap-6 lg:gap-0 overflow-y-scroll no-scrollbar rounded-xl lg:rounded-none lg:flex-row'>
                
                {/* ////////////////////////////// right side content with Accordian /////////////////////////////////// */}
                <Accordion className='lg:hidden rounded-xl bg-white pb-0' open={open === 1} icon={<Icon id={1} open={open} />}>
                            <AccordionHeader className='font-semibold px-4 py-3 border-none text-base rounded-xl' onClick={() => handleOpen(1)}>Summary</AccordionHeader>
                            <AccordionBody>
                            <div className='bg-white rounded-xl lg:rounded-none px-4 w-full lg:w-[35%] flex flex-col gap-4'>

                        {/* /////////////////////////////////// Summary States /////////////////////////////////// */}
                        <div className='mt-4 mb-5'>
                            <div className='summary-states-container flex justify-around'>
                                <div className='views flex flex-col items-center'>
                                    <ViewsIcon/>
                                    <div className='flex items-center gap-1'>
                                        <span className='text-sm'>{data.visitorCount}</span>{' '}
                                        <span className='text-gray-500 text-xs'>({data.visitorPercentage}%)</span>
                                    </div>
                                    <span className='text-gray-500 text-sm'>Views</span>
                                </div>
                                <div className='response flex flex-col items-center'>
                                    <ResponseIcon/>
                                    <div className='flex items-center gap-1'>
                                        <span className='text-sm'>{data.completedCount}</span>{' '}
                                        <span className='text-gray-500 text-xs'>({data.completedPercentage}%)</span>
                                    </div>
                                    <span className='text-gray-500 text-sm'>Response</span>
                                </div>
                            </div>
                        </div>

                        {/* //////////////////////////////// Progress and Button //////////////////////////////////// */}
                        <div className='progress&Showbtn-Container flex flex-col gap-4'>
                            <div className='mb-1 h-[0.5rem] w-full bg-gray-300 dark:bg-neutral-600 rounded-lg'>
                                <div
                                    className={`h-[0.5rem] btn-color-primary rounded-lg`}
                                    style={{width: `${data.visitorPercentage}%`}}
                                ></div>
                            </div>

                            <button
                                onClick={() => navigate('/user/insights')}
                                type='button'
                                className={`focus:outline-none text-white mt-2 btn-color-primary btn-color-primary  font-medium rounded-lg text-sm px-5 py-2.5 mb-2`}
                            >
                                Show Insights
                            </button>
                        </div>

                        {/* /////////////////// Last div containing toggle slide and paragraph ///////////////////// */}
                        <div className='last-container flex flex-col gap-4 border-t-2 border-gray-300 pt-5 mt-5'>
                            <div className='flex items-center justify-between'>
                                <p className='font-semibold'>Share with friends</p>

                                {/* ///////////////////////////// Toggle button ///////////////////////////// */}
                                <label className='flex cursor-pointer select-none items-center'>
                                    <div className='relative'>
                                        <input
                                            type='checkbox'
                                            checked={isChecked}
                                            onChange={handleCheckboxChange}
                                            className='sr-only'
                                        />
                                        <div
                                            className={`box block h-6 w-10 rounded-full ${
                                                isChecked ? `btn-color-primary` : 'bg-gray-500'
                                            }`}
                                        ></div>
                                        <div
                                            className={`absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-white transition ${
                                                isChecked ? 'translate-x-full' : ''
                                            }`}
                                        ></div>
                                    </div>
                                </label>
                            </div>

                            {/* /////////////////////////////////////////////////////////////////////////////// */}
                            <div
                                className='secod-main-container w-full rounded-xl py-5 mx-auto flex flex-col gap-4'>
                                <div className='link-container  flex flex-col gap-1'>
                                    <p>Copy your unique link</p>
                                    <div className='copy-link flex items-center w-full relative'>
                                        <Link className='w-[80%]'>
                                            <input
                                                className='outline-none border-none w-[100%] overflow-scroll  bg-transparent text-sm text-primary-no-hover'
                                                type='text'
                                                value={generatedLink}
                                                readOnly
                                            />
                                        </Link>

                                        <CopyToClipboard text={generatedLink} onCopy={() => {
                                            true
                                            setCopied(true)
                                            }}>
                                            <CopyBtn className='absolute cursor-pointer top-0 right-2'/>
                                        </CopyToClipboard>
                                    </div>
                                    {copied&&<span className="text-xs font-bold self-end px-3">Copied</span>}
                                </div>

                                <div className='bottom-icons-container mt-2 flex flex-col gap-2'>
                                    <h2>Share your link</h2>
                                    <div className='icons flex gap-2'>
                                        <WhatsappShareButton url={generatedLink}>
                                            <div className={`whatsapp text-[#0A526C] hover:text-white text-lg w-[1.88rem] border-none outline-none flex justify-center items-center h-[1.88rem] bg-gray-100 icon-hover-primary rounded-full`}>
                                                <TbBrandWhatsapp className=''/>
                                            </div>
                                        </WhatsappShareButton>

                                        {/* <div className={`whatsapp text-black hover:text-white  w-[1.88rem] border-none outline-none flex justify-center items-center h-[1.88rem] bg-gray-100 icon-hover-primary rounded-full cursor-pointer`}>
                                            <FaInstagram  className=''/>
                                        </div> */}
                                        
                                        <FacebookShareButton url={generatedLink}>
                                        <div className={`whatsapp text-[#0A526C] hover:text-white  w-[1.88rem] border-none outline-none flex justify-center items-center h-[1.88rem] bg-gray-100 icon-hover-primary rounded-full`}>
                                                <GrFacebookOption className=''/>
                                            </div>
                                        </FacebookShareButton>

                                        <TwitterShareButton url={generatedLink}>
                                        <div className={`whatsapp text-[#0A526C] hover:text-white  w-[1.88rem] border-none outline-none flex justify-center items-center h-[1.88rem] bg-gray-100 icon-hover-primary rounded-full`}>
                                                <BsTwitterX className=''/>
                                            </div>
                                        </TwitterShareButton>

                                        <LinkedinShareButton url={generatedLink}>
                                        <div className={`whatsapp text-[#0A526C] hover:text-white  w-[1.88rem] border-none outline-none flex justify-center items-center h-[1.88rem] bg-gray-100 icon-hover-primary rounded-full`}>
                                                <RiLinkedinFill className=''/>
                                            </div>
                                        </LinkedinShareButton>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                            
                            </AccordionBody>
                </Accordion>
                


                {/* ////////////////////////////////// Left side content /////////////////////////////////////// */}
                <div className='left flex flex-col gap-3 lg:overflow-y-scroll no-scrollbar lg:p-7 w-full'>

                    {/* ////////////////////////////////// Heading /////////////////////////////////////////// */}
                    <h2 className='text-lg font-semibold'>
                        {data?.title}
                    </h2>

                    {/* ///////////////////////////////////// Progress bar //////////////////////////////////////// */}
                    <div className='progress flex justify-center items-center gap-3'>
                        <div className='mb-1 h-[0.5rem] w-full bg-gray-300 dark:bg-neutral-600 rounded-lg'>
                            <div
                                className={`h-[0.5rem] btn-color-primary rounded-lg`}
                                style={{width: `${progress}%`}}
                            ></div>
                        </div>
                        <span>
                        {data?.currentPage}/{data?.totalPages}
                        </span>
                    </div>

                    {/* ////////////////////////////// Questions ///////////////////////////////////// */}
                    <div className='overflow-hidden lg:overflow-visible bg-white rounded-xl py-2 px-4 flex flex-col gap-2'>
                        {/* //////////////////////////////// Single Question //////////////////////////// */}
                        {selectedValues.answers.map((ques, index) => {
                            return (
                                <div key={index}
                                     className='question flex flex-col gap-2 items-center border-b-2 py-2'>
                                    <p className='font-semibold break-words text-sm lg:text-base'>
                                        {ques.title}
                                    </p>
                                    <div className='flex gap-[0.4rem] lg:gap-3 items-center justify-center '>
                                        <span className='text-sm'>Inaccurate</span>
                                        <div className="bg-white dark:bg-gray-100 rounded-full w-[1rem] h-[1rem] lg:w-[1.3rem] lg:h-[1.3rem] flex flex-shrink-0 justify-center items-center relative">
                                            <input onChange={() => handleRadioChange(ques.questionId, '1', ques.title)} aria-labelledby="label2" type="radio" value='1' name={`radio${ques.questionId}`}  checked={ques.answer === '1'} className="checkbox appearance-none focus:opacity-100  focus:outline-none border rounded-full border-gray-400 absolute cursor-pointer w-full h-full checked:border-none" />
                                            <div className="check-icon hidden border-[6px] border-primary-color rounded-full w-full h-full z-1"></div>
                                        </div>
                                        <div className="bg-white dark:bg-gray-100 rounded-full w-[1rem] h-[1rem] lg:w-[1.3rem] lg:h-[1.3rem] flex flex-shrink-0 justify-center items-center relative">
                                            <input onChange={() => handleRadioChange(ques.questionId, '2', ques.title)} aria-labelledby="label2" type="radio" value='2' name={`radio${ques.questionId}`} checked={ques.answer === '2'} className="checkbox appearance-none focus:opacity-100  focus:outline-none border rounded-full border-gray-400 absolute cursor-pointer w-full h-full checked:border-none" />
                                            <div className="check-icon hidden border-[6px] border-primary-color rounded-full w-full h-full z-1"></div>
                                        </div>
                                        <div className="bg-white dark:bg-gray-100 rounded-full w-[1rem] h-[1rem] lg:w-[1.3rem] lg:h-[1.3rem] flex flex-shrink-0 justify-center items-center relative">
                                            <input onChange={() => handleRadioChange(ques.questionId, '3', ques.title)} aria-labelledby="label2" type="radio" value='3' name={`radio${ques.questionId}`} checked={ques.answer === '3'} className="checkbox appearance-none focus:opacity-100  focus:outline-none border rounded-full border-gray-400 absolute cursor-pointer w-full h-full checked:border-none" />
                                            <div className="check-icon hidden border-[6px] border-primary-color rounded-full w-full h-full z-1"></div>
                                        </div>
                                        <div className="bg-white dark:bg-gray-100 rounded-full w-[1rem] h-[1rem] lg:w-[1.3rem] lg:h-[1.3rem] flex flex-shrink-0 justify-center items-center relative">
                                            <input onChange={() => handleRadioChange(ques.questionId, '4', ques.title)} aria-labelledby="label2" type="radio" value='4' name={`radio${ques.questionId}`} checked={ques.answer === '4'} className="checkbox appearance-none focus:opacity-100  focus:outline-none border rounded-full border-gray-400 absolute cursor-pointer w-full h-full checked:border-none" />
                                            <div className="check-icon hidden border-[6px] border-primary-color rounded-full w-full h-full z-1"></div>
                                        </div>
                                        <div className="bg-white dark:bg-gray-100 rounded-full w-[1rem] h-[1rem] lg:w-[1.3rem] lg:h-[1.3rem] flex flex-shrink-0 justify-center items-center relative">
                                            <input onChange={() => handleRadioChange(ques.questionId, '5', ques.title)} aria-labelledby="label2" type="radio" value='5' name={`radio${ques.questionId}`} checked={ques.answer === '5'} className="checkbox appearance-none focus:opacity-100  focus:outline-none border rounded-full border-gray-400 absolute cursor-pointer w-full h-full checked:border-none" />
                                            <div className="check-icon hidden border-[6px] border-primary-color rounded-full w-full h-full z-1"></div>
                                        </div>
                                        <span className='text-sm'>Accurate</span>
                                    </div>

                                </div>
                            );
                        })}


                    </div>

                    <div className='continue-btn flex justify-center items-center'>

                        {currentPage!==data?.totalPages?
                        <button
                             type='button' onClick={() => submitValues()} disabled={!isAllQuestionsAnswered()}
                             className={`focus:outline-none w-28 text-white btn-color-primary btn-color-primary font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 ${isAllQuestionsAnswered() ? '' : 'opacity-50 cursor-not-allowed'}`}
                         >
                             Continue
                         </button>
                            :
                            <button
                             onClick={() => submitValues()}
                             type='button' disabled={!isAllQuestionsAnswered()}
                             className={`focus:outline-none w-28 text-white btn-color-primary btn-color-primary font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 `}
                         >
                             Submit
                         </button>
                            
                           
                        }

                        {/* ////////////////////////// Adding modal window ///////////////////////////////////////// */}

                        <ModalWindow show={modal} onClose={onClose} msgHeading='Great!'
                            msg='You are re-evaluated, now let’s ask your friends'
                            generatedLink={generatedLink}
                        />
                    </div>
                </div>



                {/* ////////////////////////////// right side content without Accordian /////////////////////////////////////////// */}
                <div className='right bg-white p-4 w-[35%] flex-col gap-4 border-l-2 hidden lg:block'>
                        <span className='font-semibold'>Summary</span>

                        {/* /////////////////////////////////// Summary States /////////////////////////////////// */}
                        <div className='mb-5 mt-5'>
                            <div className='summary-states-container flex justify-around'>
                                <div className='views flex flex-col items-center'>
                                    <ViewsIcon/>
                                    <div className='flex items-center gap-1'>
                                        <span className='text-sm'>{data.visitorCount}</span>{' '}
                                        <span className='text-gray-500 text-xs'>({data.visitorPercentage}%)</span>
                                    </div>
                                    <span className='text-gray-500 text-sm'>Views</span>
                                </div>
                                <div className='response flex flex-col items-center'>
                                    <ResponseIcon/>
                                    <div className='flex items-center gap-1'>
                                        <span className='text-sm'>{data.completedCount}</span>{' '}
                                        <span className='text-gray-500 text-xs'>({data.completedPercentage}%)</span>
                                    </div>
                                    <span className='text-gray-500 text-sm'>Response</span>
                                </div>
                            </div>
                        </div>

                        {/* //////////////////////////////// Progress and Button //////////////////////////////////// */}
                        <div className='progress&Showbtn-Container flex flex-col gap-4'>
                            <div className='mb-1 h-[0.5rem] w-full bg-gray-300 dark:bg-neutral-600 rounded-lg'>
                                <div
                                    className={`h-[0.5rem] btn-color-primary rounded-lg`}
                                    style={{width: `${data.visitorPercentage}%`}}
                                ></div>
                            </div>

                            <button
                                onClick={() => navigate(`/user/quiz/insights/${questionnaireId}`)}
                                type='button'
                                className={`focus:outline-none text-white btn-color-primary btn-color-primary  font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2`}
                            >
                                Show Insights
                            </button>
                        </div>

                        {/* /////////////////// Last div containing toggle slide and paragraph ///////////////////// */}
                        <div className='last-container flex flex-col gap-4 border-t-2 border-gray-300 pt-5 mt-5'>
                            <div className='flex items-center justify-between'>
                                <p className='font-semibold'>Share with friends</p>

                                {/* ///////////////////////////// Toggle button ///////////////////////////// */}
                                <label className='flex cursor-pointer select-none items-center'>
                                    <div className='relative'>
                                        <input
                                            type='checkbox'
                                            checked={isChecked}
                                            onChange={handleCheckboxChange}
                                            className='sr-only'
                                        />
                                        <div
                                            className={`box block h-6 w-10 rounded-full ${
                                                isChecked ? `btn-color-primary` : 'bg-gray-500'
                                            }`}
                                        ></div>
                                        <div
                                            className={`absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-white transition ${
                                                isChecked ? 'translate-x-full' : ''
                                            }`}
                                        ></div>
                                    </div>
                                </label>
                            </div>

                            {/* /////////////////////////////////////////////////////////////////////////////// */}
                            <div
                                className='secod-main-container w-full rounded-xl mb-14 py-5 mx-auto flex flex-col gap-4'>
                                <div className='link-container  flex flex-col gap-1'>
                                    <p>Copy your unique link</p>
                                    <div className='copy-link flex items-center w-full relative'>
                                        <Link className='w-[80%]'>
                                            <input
                                                className='outline-none border-none w-[100%] overflow-scroll  bg-transparent text-sm text-primary-no-hover'
                                                type='text'
                                                value={generatedLink}
                                                readOnly
                                            />
                                        </Link>

                                        <CopyToClipboard text={generatedLink} onCopy={() => {
                                            true
                                            setCopied(true)
                                            }}>
                                            <CopyBtn className='absolute cursor-pointer top-0 right-2'/>
                                        </CopyToClipboard>
                                    </div>
                                    {copied&&<span className="text-xs font-bold self-end px-3">Copied</span>}
                                </div>

                                <div className='bottom-icons-container flex flex-col gap-2'>
                                    <h2>Share your link</h2>
                                    <div className='icons flex gap-2'>
                                        <WhatsappShareButton url={generatedLink}>
                                            <div className={`whatsapp text-[#0A526C] hover:text-white text-lg w-[1.88rem] border-none outline-none flex justify-center items-center h-[1.88rem] bg-gray-100 icon-hover-primary rounded-full`}>
                                                <TbBrandWhatsapp className=''/>
                                            </div>
                                        </WhatsappShareButton>
                                        {/*<Instagram className='cursor-pointer'/>*/}
                                        <FacebookShareButton url={generatedLink}>
                                        <div className={`whatsapp text-[#0A526C] hover:text-white  w-[1.88rem] border-none outline-none flex justify-center items-center h-[1.88rem] bg-gray-100 icon-hover-primary rounded-full`}>
                                                <GrFacebookOption className=''/>
                                            </div>
                                        </FacebookShareButton>
                                        <TwitterShareButton url={generatedLink}>
                                        <div className={`whatsapp text-[#0A526C] hover:text-white  w-[1.88rem] border-none outline-none flex justify-center items-center h-[1.88rem] bg-gray-100 icon-hover-primary rounded-full`}>
                                                <BsTwitterX className=''/>
                                            </div>
                                        </TwitterShareButton>
                                        <LinkedinShareButton url={generatedLink}>
                                        <div className={`whatsapp text-[#0A526C] hover:text-white  w-[1.88rem] border-none outline-none flex justify-center items-center h-[1.88rem] bg-gray-100 icon-hover-primary rounded-full`}>
                                                <RiLinkedinFill className=''/>
                                            </div>
                                        </LinkedinShareButton>
                                        {/* <div className={`whatsapp text-black hover:text-white  w-[1.88rem] border-none outline-none flex justify-center items-center h-[1.88rem] bg-gray-100 icon-hover-primary rounded-full cursor-pointer`}>
                                                <FaInstagram  className=''/>
                                            </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>


            </div>
        </div>
    }
</>
  )
}


ReEvaluate.propTypes = {
    id: PropTypes.number,
    open: PropTypes.func,
  }
export default ReEvaluate
