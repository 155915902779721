import React, {useEffect, useState} from 'react';
import { ReactComponent as Question } from '../images/question-color.svg';
import { ReactComponent as Response } from '../images/response-color.svg';
import Chart from 'react-apexcharts';
import humbleIcon from '../images/humble_ic.png';
import SelfAwareIcon from '../images/selfaware.png';
import LittleProudIcon from '../images/littleproud.png';
import SelfDepricatingIcon from '../images/selfdepricating.png';
import NeedAttentionIcon from '../images/needattention.png';
import Loader from "../components/Loader";
import {useDispatch, useSelector} from "react-redux";
import {authUser, setUser} from "../redux/slices/UserSlice";
import {useNavigate} from "react-router-dom";
import {changeNavStatus} from "../redux/slices/NavStatusSlice";
import {setEmail} from "../redux/slices/LoginPageSlice";
import {setQuestionnaireId} from "../redux/slices/QuestionnaireIdSlice";
import {setError} from "../redux/slices/LoginErrorSlice";
import Select from 'react-select';

const tableData = [
  {
    id: 1,
    title: 'How well does this describe you?',
    views: 95,
    responses: 80,
  },
];

const DashboardContent = () => {

  const user = useSelector(authUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({})
  // const [year, setYear] = useState('')
  const [selectedYear, setSelectedYear] = useState({ value: '', label: 'This year' });


  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-dashboard?${selectedYear.value !== '' ? '&year='+selectedYear.value : ''}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user?.token}`,
            "Content-Type": "application/json",
             
          },
        });

        if (response.status === 401 || response.status===400) {
          localStorage.clear();
          dispatch(setError('Authentication failed. Please login again.'));
          dispatch(setUser(null));
          dispatch(setEmail(""));
          dispatch(setQuestionnaireId(""));
          navigate("/");
        }

        if (response.ok) {
          const result = await response.json();
          setData(result?.data);
          setIsLoading(false);
        } else {
          navigate("/");
        }
      } catch (error) {
        console.log(error);
      }
    };
    setIsLoading(true)
    fetchDashboardData()

  }, [selectedYear]);

  const handleYearChange = event => {
    setYear(event.target.value);
  };

  const bColor = 'var(--primary-color)';

  const graphData = {
    series: [
      {
        name: '',
        data: data?.views,
      },
    ],

    options: {
      chart: {
        height: 350,
        type: 'area',
        toolbar: { show: false },
        zoom: {
          enabled: false,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0,
          opacityFrom: 0.3,
          opacityTo: 0.1
        }
      },

      colors: ['var(--primary-color)'],
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        fixed: {
          enabled: false,
          position: 'bottomRight',
          offsetX: 0,
          offsetY: 1,
        },
      },
      stroke: {
        curve: 'smooth',
        width: 2.5,
      },

      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      },

      yaxis: {
        labels: {
          formatter: (val) => {
            return `${val}`;
          },
          style: { colors: ['#AAA492'] },
        },
      },
    },
  };


  const yearOptions = [
    { value: '', label: 'This year' },
    { value: new Date().getFullYear() - 1, label: 'Last 1 year' },
    { value: new Date().getFullYear() - 2, label: 'Last 2 year' },
    { value: new Date().getFullYear() - 3, label: 'Last 3 year' }
  ]

  return (
    <>
        {isLoading ? <Loader/> :(
    <div className='w-[100vw] md:w-[calc(97.8vw-223px)] lg:w-[calc(97.8vw-217px)] 2xl:w-[calc(98.7vw-220px)] h-[calc(100vh-3.6rem)] md:h-[calc(100vh-4rem)] flex md:ml-60 bg-gray-100'>
      <div className='w-full h-[calc(100vh-4rem)] px-4 md:px-10 py-4 flex flex-col gap-6 overflow-scroll no-scrollbar lg:overflow-auto'>
        <h2 className='font-semibold'>Overview</h2>
        <div className='grid grid-cols-2 lg:grid-cols-3 gap-3 lg:gap-5 relative'>
          <div className='box1'>
            <div className='lef flex gap-2 sm:gap-4 p-5 2xl:p-6 h-full rounded-lg bg-white items-center'>
              <div className='icon1 bg-[var(--primary-color)] w-fit p-2 2xl:p-3 rounded-full'>
                <Question />
              </div>
              <div className='content flex flex-col gap-1 text-sm'>
                <h1 className='text-xl font-bold 2xl:text-2xl'>{data.myResponseForMyFriendsQuestionnaire}</h1>
                <p className='text-gray-400 text-xs md:text-sm 2xl:text-base'>My Responses to Friends</p>
              </div>
            </div>
          </div>
          <div className='box2'>
            <div className='rit flex gap-2 sm:gap-4 p-5 2xl:p-6 h-full rounded-lg bg-white items-center'>
              <div className='icon1 bg-[#E091C9] p-2 2xl:p-3 w-fit rounded-full'>
                <Response />
              </div>
              <div className='content flex flex-col gap-1 text-sm'>
                <h1 className='text-xl font-bold 2xl:text-2xl'>{data.myFriendResponseForMyQuestionnaire}</h1>
                <p className='text-gray-400 text-xs md:text-sm 2xl:text-base'>My Friends’ Responses</p>
              </div>
            </div>
          </div>
          <div className='box3 row-span-5 row-start-2 lg:row-start-auto col-span-2 lg:col-span-1 '>
            <div className='sidebar w-full rounded-lg flex flex-col gap-3 lg:gap-5'>
              <div className='upper flex flex-col relative group p-5 rounded-lg bg-white gap-3'>
                
                <h3 className='font-semibold 2xl:text-lg '>Prominent Traits</h3>
                <div className='flex justify-between items-center '>
                  <div className='flex justify-start items-center gap-3'>
                    <img className='w-7' src={humbleIcon} alt='' />{' '}
                    <h4 className='text-sm 2xl:text-base'>Humble</h4>
                  </div>
                  <span className={`text-sm 2xl:text-base ${user.plan==='Gold'||user.plan==='Diamond'?'blur-none':'blur-sm select-none'}`}>{data.humbleTraitPercentage}%</span>
                </div>
                <div className='flex justify-between items-center '>
                  <div className='flex justify-start items-center gap-3'>
                    <img className='w-7' src={SelfAwareIcon} alt='' />{' '}
                    <h4 className='text-sm 2xl:text-base'>Self-Aware</h4>
                  </div>
                  <span className={`text-sm 2xl:text-base ${user.plan==='Gold'||user.plan==='Diamond'?'blur-none':'blur-sm select-none'}`}>{data.selfAwareTraitPercentage}%</span>
                </div>
                <div className='flex justify-between items-center '>
                  <div className='flex justify-start items-center gap-3'>
                    <img className='w-7' src={LittleProudIcon} alt='' />{' '}
                    <h4 className='text-sm 2xl:text-base'>Little Proud</h4>
                  </div>
                  <span className={`text-sm 2xl:text-base ${user.plan==='Gold'||user.plan==='Diamond'?'blur-none':'blur-sm select-none'}`}>{data.littleProudTraitPercentage}%</span>
                </div>
                <div className='flex justify-between items-center '>
                  <div className='flex justify-start items-center gap-3'>
                    <img className='w-7' src={SelfDepricatingIcon} alt='' />{' '}
                    <h4 className='text-sm 2xl:text-base'>Self-Deprecating</h4>
                  </div>
                  <span className={`text-sm 2xl:text-base ${user.plan==='Gold'||user.plan==='Diamond'?'blur-none':'blur-sm select-none'}`}>{data.selfDepreciatingTraitPercentage}%</span>
                </div>
                <div className='flex justify-between items-center '>
                  <div className='flex justify-start items-center gap-3'>
                    <img className='w-7' src={NeedAttentionIcon} alt='' />{' '}
                    <h4 className='text-sm 2xl:text-base'>Needs Attention</h4>
                  </div>
                  <span className={`text-sm 2xl:text-base ${user.plan==='Gold'||user.plan==='Diamond'?'blur-none':'blur-sm select-none'}`}>{data.needsAttentionTraitPercentage}%</span>
                </div>


{/* ////////////////////////////////////// Pop Over /////////////////////////////////////// */}
                <div
                    className={`flex flex-col gap-3 items-center w-52 sm:w-[70%] p-6 opacity-0 invisible ${user.plan !=='Gold' && user.plan !=='Diamond'&&'group-hover:opacity-100 group-hover:visible'} absolute top-[25%] left-[25%] right-[25%]  bg-white text-gray-800 border duration-300 border-gray-300 rounded-lg shadow-lg z-10`}>
                      <p className='text-center text-sm xl:text-base'>Please subscribe to unlock premium features</p>
                      <button
                      onClick={()=>navigate('/user/subscription')}
                          type='submit'
                          className={`focus:outline-none w-full mt-4 text-white  btn-color-primary font-medium rounded-lg text-xs xl:text-sm p-2 `}
                      >
                      Proceed to Payment
                      </button>
                </div>
              </div>

              {/* //////////////////////////////////////////////////////////////////// */}
              <div className='lower bg-white p-5 rounded-lg flex flex-col gap-3'>
                <p className='font-semibold 2xl:text-lg'>This Month</p>
                <div className='questionnaire-count rounded-lg flex flex-col gap-3 items-center p-6 border-2'>
                  <h1 className='text-2xl font-semibold'>1</h1>
                  <h3>Questionnaires</h3>
                </div>

                <div className='progres flex flex-col gap-2'>
                  <div className='flex justify-between items-center'>
                    <h3 className='2xl:text-lg'>Completion Rate</h3>
                    <span className='text-sm 2xl:text-lg'>{data.completedPercentage}%</span>
                  </div>
                  <div className='mb-1 h-[0.5rem] w-full bg-gray-300 dark:bg-neutral-600 rounded-lg'>
                    <div
                      className='h-[0.5rem] btn-color-primary rounded-lg'
                      style={{ width: `${data.completedPercentage}%` }}
                    ></div>
                  </div>
                </div>

                <div className='progres flex flex-col gap-2'>
                  <div className='flex justify-between items-center'>
                    <h3 className='2xl:text-lg'>Attempted</h3>
                    <span className='text-sm 2xl:text-lg'>{data.visitorPercentage}%</span>
                  </div>
                  <div className='mb-1 h-[0.5rem] w-full bg-gray-300 dark:bg-neutral-600 rounded-lg'>
                    <div
                      className='h-[0.5rem] bg-[#7EB1FC] rounded-lg'
                      style={{ width: `${data.visitorPercentage}%` }}
                    ></div>
                  </div>
                </div>

                {/*<div className='progres flex flex-col gap-2'>*/}
                {/*  <div className='flex justify-between items-center'>*/}
                {/*    <h3 className='2xl:text-lg'>Paused</h3>*/}
                {/*    <span className='text-sm 2xl:text-lg'>34%</span>*/}
                {/*  </div>*/}
                {/*  <div className='mb-1 h-[0.5rem] w-full bg-gray-300 dark:bg-neutral-600 rounded-lg'>*/}
                {/*    <div*/}
                {/*      className='h-[0.5rem] bg-[#F59080] rounded-lg'*/}
                {/*      style={{ width: '50%' }}*/}
                {/*    ></div>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
          <div className='box4  col-span-2 row-span-5 rounded-lg flex flex-col gap-3 lg:gap-5'>
            <div className='tbl p-1 bg-white rounded-lg'>
              <div className=' flex justify-between items-center p-4'>
                <h2 className='font-semibold 2xl:text-lg'>Popular Questionnaire</h2>
                <button className='text-sm 2xl:text-base default-text-primary-color'>View all</button>
              </div>
              <table className=' text-[10px] md:text-sm 2xl:text-base w-full overflow-x-scroll text-left text-black bg-white'>
                <thead className='text-xs md:text-sm 2xl:text-lg text-gray-400  capitalize border-y'>
                  <tr>
                    <th scope='col' className='py-2 px-3 tracking-wider'>
                      Title
                    </th>
                    <th scope='col' className='py-2 px-3 tracking-wider'>
                      Views
                    </th>
                    <th scope='col' className='py-2 px-3 tracking-wider'>
                      Responses
                    </th>
                    <th scope='col' className='py-2 px-3 tracking-wider'>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(({ id, title, selfEvaluationStatus, responses }) => {
                    return (
                      <tr
                        key={id}
                        className={`${
                          id % 2 === 0 ? 'bg-gray-100' : 'bg-white'
                        }`}
                      >
                        <td className='py-2 px-3'>{title}</td>
                        <td className='py-2 px-3'>{data.visitorCount}</td>
                        <td className='py-2 px-3'>{data.completedCount}</td>
                        <td className='py-2 px-3'>
                          <button onClick={() => { if (data?.questionnaire.selfEvaluationStatus === true) navigate(`/user/quiz/insights/${data.questionnaire.id}`) }} className='default-text-primary-color'>
                            View insights
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className='graph bg-white rounded-lg p-6'>
              <div className='graph-header flex flex-col gap-2 md:flex-row md:justify-between md:items-center'>
                <p className='font-semibold 2xl:text-lg'>Respondents Frequency</p>
                <div className='w-full md:w-60'>
              <Select
                styles={{
                  control: (base, state) => ({
                    ...base,
                    boxShadow: state.isFocused ? 0 : 0,
                    border: '1px solid #E5E7EB',
                    borderRadius: '8px',
                    padding: '5.5px',
                    borderColor: state.isFocused
                      ? 'brandColor'
                      : base.borderColor,
                    '&:hover': {
                      borderColor: state.isFocused ? bColor : base.borderColor,
                    },
                  }),

                  placeholder: (baseStyles) => ({
                    ...baseStyles,
                    color: '#9CA3AF',
                  }),
                  option: (base, { isFocused }) => {
                    return {
                      ...base,
                      padding: '2',
                      backgroundColor: isFocused ? '#1967D2' : 'white',
                      color: isFocused ? 'white' : 'black',
                    };
                  },
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    padding: '6px',
                  }),
                }}
                name='year'
                className='rounded-lg text-sm 2xl:text-base focus:shadow-green-500 shadow-sm'
                // defaultValue={genderOptions[0]}
                value={selectedYear}
                onChange={(selectedOption) => setSelectedYear(selectedOption)}
                options={yearOptions}
              />
            </div>
              </div>
            <Chart options={graphData.options} series={graphData.series} height={250} type='area'/>
            </div>
          </div>
        </div>
      </div>
    </div>
        )}
      </>
  );
};

export default DashboardContent;
