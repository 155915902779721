import { combineReducers } from '@reduxjs/toolkit';

import loginPageReducer from '../redux/slices/LoginPageSlice';
import userSlice from './slices/UserSlice';
import QuestionnaireIdSlice from './slices/QuestionnaireIdSlice';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import navStatusSlice from "./slices/NavStatusSlice";
import LoginError from "./slices/LoginErrorSlice";

const rootReducer = combineReducers({
    nav: navStatusSlice,
    email: loginPageReducer,
    user: userSlice,
    questionnaireId: QuestionnaireIdSlice,
    error: LoginError,
});

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
