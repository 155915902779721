import React from 'react'
import PropTypes from 'prop-types'
import { formatAmount } from '../utilityFunctions/Utils'


const ProgressBars = ({heading, progressValue, numbers}) => {
    return (
        <div className='progres flex flex-col gap-2'>
            <div className='flex justify-between items-center'>
                <h3 className='2xl:text-lg'>{heading}</h3>
                <div className='text-sm 2xl:text-lg'><span >{`${progressValue}%`}</span><span className="text-gray-400">{`(${formatAmount(numbers, 1)})`}</span></div>
            </div>
            <div className='mb-1 h-[0.5rem] w-full bg-gray-300 dark:bg-neutral-600 rounded-lg'>
                <div
                    className='h-[0.5rem] btn-color-primary rounded-lg'
                    style={{ width: `${progressValue}%` }}
                ></div>
            </div>
        </div>
    )
}

ProgressBars.propTypes = {
    heading: PropTypes.string,
    progressValue: PropTypes.string,
    numbers: PropTypes.number,
}

export default ProgressBars