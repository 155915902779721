import React from 'react'
import { useSelector } from 'react-redux';
import { authUser } from '../redux/slices/UserSlice';


const TrialStatus = () => {

  const user = useSelector(authUser);

  return (
    <div className='p-2 text-[#E9523A] bg-[#FDEEEB] font-semibold text-xs lg:text-sm rounded-lg'>
      <p>{user.active_subscription_id === ''? 'Subscription cancelled':'Trial expires in 3 days'}</p>
    </div>
  )
}

export default TrialStatus
