import React from 'react'

const Error404 = () => {
  return (
    <div className='flex items-center flex-col justify-center h-screen'>
      <h1 className=' text-center text-3xl font-bold'>404</h1>
      <h1 className=' text-center text-3xl font-bold'>Page not Found</h1>
    </div>
  )
}

export default Error404
