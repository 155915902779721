import ModalWindow from '../components/ModalWindow'
import React, {useEffect, useState} from 'react'
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../components/Loader";
import {useDispatch, useSelector} from "react-redux";
import {authUser, setUser} from "../redux/slices/UserSlice";
import {isDesktop, isMobile, osName} from "react-device-detect";
import {setEmail} from "../redux/slices/LoginPageSlice";
import {setQuestionnaireId} from "../redux/slices/QuestionnaireIdSlice";
import {setError} from "../redux/slices/LoginErrorSlice";

const MyFriendsQuestionnaire = () => {
    let currentPage = 1;
    const [isLoading, setIsLoading] = useState(true)
    const [pageRefresh, setPageRefresh] = useState(0)
    const [data, setData] = useState({})
    const [modal, setModal] = useState(false)
    const [modalWindowData, setModalWindowData] = useState({})
    const {questionnaireId} = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(authUser)

    const [selectedValues, setSelectedValues] = useState({answers: []})

    
    useEffect(() => {
        const saveUserStat = async () => {
            const payload = {
                isDesktop: isDesktop,
                isMobile: isMobile,
                osName: osName,
            }
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user-stat`,
                payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user?.token}`,
                         
                    },
                });
        };

        saveUserStat();

    }, []);


    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/questionnaire/${questionnaireId}/questions?pageNum=${currentPage}`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${user?.token}`,
                        "Content-Type": "application/json",
                         
                    },
                });

                if (response.status === 401 || response.status===400) {
                    localStorage.clear();
                    dispatch(setError('Authentication failed. Please login again.'));
                    dispatch(setUser(null));
                    dispatch(setEmail(""));
                    dispatch(setQuestionnaireId(""));
                    navigate("/");
                }

                if (response.ok) {
                    const result = await response.json();
            
                    if(result?.data?.friendsQuestionnaire.status === 'completed'){
                        navigate(`/user/quiz`);
                    }
                    setData(result?.data?.friendsQuestionnaire);

                } else {
                    navigate("/");
                }
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };
          

        setIsLoading(true);
        fetchUserData();

    }, [navigate, pageRefresh]);


    const progress = ((data.currentPage) / data.totalPages) * 100;


    const handleRadioChange = (questionId, answer) => {
        // Find the index of the question in selectedValues.answers
        const questionIndex = selectedValues.answers.findIndex(
            (q) => q.questionId === questionId
        );

        // If the question is already in the array, update the answer
        if (questionIndex !== -1) {
            setSelectedValues((prevValues) => {
                const updatedAnswers = [...prevValues.answers];
                updatedAnswers[questionIndex] = {questionId, answer};
                return {answers: updatedAnswers};
            });
        } else {
            // If the question is not in the array, add it
            setSelectedValues((prevValues) => ({
                answers: [...prevValues.answers, {questionId, answer}],
            }));
        }
    };

    const submitValues = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/questionnaire/${questionnaireId}/fill-answers`,
                selectedValues, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user?.token}`,
                         
                    },
                });

            if (response.status === 201) {
                if (data.currentPage !== data.totalPages) {
                    setSelectedValues({answers: []})
                    setPageRefresh(data.currentPage)
                    // navigate(`/questionnaire/my-questionnaire/${questionnaireId}`)
                } else {

                    setModalWindowData(response.data.data)
                    setModal(true)
                    // navigate('/questionnaire');
                }

            }
            if (response.status === 401 || response.status===400) {
                localStorage.clear();
                dispatch(setError('Authentication failed. Please login again.'));
                dispatch(setUser(null));
                dispatch(setEmail(""));
                dispatch(setQuestionnaireId(""));
                navigate("/");
            }
            // if (response.status === 401 || response.status===400) {
            //     // navigate('/');
            // }

        } catch (error) {
            console.log(error, 'error')
        } finally {
            setIsLoading(false);
        }
    }

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked)
    }

    const isAllQuestionsAnswered = () => {
        return data.questions.every(item =>
            selectedValues.answers.some(answer => answer.questionId === item.id)
        );
    };

    const onClose = () => {
        setModal(false)
        navigate(`/user/quiz`)
    }

    const generatedLink = `${process.env.REACT_APP_PUBLIC_URL}/questionnaire/${modalWindowData.id}`

    return (
        <>
            {isLoading ? <Loader/> :
                (<div className='w-[100vw] md:w-[calc(97.8vw-223px)] lg:w-[calc(97.8vw-217px)] 2xl:w-[calc(98.7vw-220px)] h-[calc(100vh-3.6rem)] md:h-[calc(100vh-4rem)] flex md:ml-60 overflow-y-scroll no-scrollbar p-4 pt-0 lg:p-0 bg-gray-100'>
                    <div
                        className='w-full h-[calc(100vh-4rem)] px-4 md:px-10 py-4 flex flex-col gap-2'>
                        <div className='first-div flex flex-col gap-2'>
                            {/* ////////////////////////// Profile Section ////////////////////////////////// */}
                            <div className='user-container  flex gap-4 items-center'>
                                <img
                                    className='w-8 h-8 lg:w-9 lg:h-9 rounded-full'
                                    src={data.createdBy.image}
                                    alt='John Doe'
                                />
                                <span
                                    className='user-name capitalize text-xs md:text-sm font-semibold overflow-hidden'>
              {data.createdBy.first_name} {data.createdBy.last_name}
            </span>
                            </div>

                            {/* //////////////////////////////////////////////////////////////////////////// */}
                            <p className='text-lg font-semibold'>
                                {data.name.replace(/\byou\b/gi, `${data.createdBy.first_name} ${data.createdBy.last_name}`)}
                                {/*{data.name} {data.createdBy.first_name} {data.createdBy.last_name}*/}
                            </p>
                            {/* ///////////////////////////////////////////////////////////////////////////// */}
                            <div className='progress flex justify-center items-center gap-3'>
                                <div className='mb-1 h-[0.5rem] w-full bg-gray-300 dark:bg-neutral-600 rounded-lg'>
                                    <div
                                        className={`h-[0.5rem] btn-color-primary rounded-lg`}
                                        style={{width: `${progress}%`}}
                                    ></div>
                                </div>
                                <span>
              {data.currentPage}/{data.totalPages}
            </span>
                            </div>
                        </div>

                        {/* ////////////////////////////////// Questionnaire //////////////////////////////////// */}

                        <div className='left flex flex-col no-scrollbar gap-3 w-full'>
                            <div className='overflow-hidden lg:overflow-visible bg-white rounded-xl py-2 px-4 flex flex-col gap-2'>
                                {/* //////////////////////////////// Single Question //////////////////////////// */}
                                {data?.questions.map((item, index) => {
                                    return (
                                        <div key={item.id}
                                             className='flex flex-col gap-2 items-center border-b-2 py-2'>
                                            <p className='font-semibold break-words text-sm lg:text-base'>
                                                {item.title}
                                            </p>
                                            <div className='flex gap-[0.4rem] lg:gap-3 items-center justify-center'>
                                                <span className='text-sm'>Inaccurate</span>
                                                <div className="bg-white dark:bg-gray-100 rounded-full w-[1rem] h-[1rem] lg:w-[1.3rem] lg:h-[1.3rem] flex flex-shrink-0 justify-center items-center relative">
                                                    <input onChange={() => handleRadioChange(item.id, '1')} aria-labelledby="label2" type="radio" value='1' name={`radio${item.id}`} className="checkbox appearance-none focus:opacity-100  focus:outline-none border rounded-full border-gray-400 absolute cursor-pointer w-full h-full checked:border-none" />
                                                    <div className="check-icon hidden border-[6px] border-primary-color rounded-full w-full h-full z-1"></div>
                                                </div>

                                                <div className="bg-white dark:bg-gray-100 rounded-full w-[1rem] h-[1rem] lg:w-[1.3rem] lg:h-[1.3rem] flex flex-shrink-0 justify-center items-center relative">
                                                    <input onChange={() => handleRadioChange(item.id, '2')} aria-labelledby="label2" type="radio" value='2' name={`radio${item.id}`} className="checkbox appearance-none focus:opacity-100  focus:outline-none border rounded-full border-gray-400 absolute cursor-pointer w-full h-full checked:border-none" />
                                                    <div className="check-icon hidden border-[6px] border-primary-color rounded-full w-full h-full z-1"></div>
                                                </div>
                                                <div className="bg-white dark:bg-gray-100 rounded-full w-[1rem] h-[1rem] lg:w-[1.3rem] lg:h-[1.3rem] flex flex-shrink-0 justify-center items-center relative">
                                                    <input onChange={() => handleRadioChange(item.id, '3')} aria-labelledby="label2" type="radio" value='3' name={`radio${item.id}`} className="checkbox appearance-none focus:opacity-100  focus:outline-none border rounded-full border-gray-400 absolute cursor-pointer w-full h-full checked:border-none" />
                                                    <div className="check-icon hidden border-[6px] border-primary-color rounded-full w-full h-full z-1"></div>
                                                </div>
                                                <div className="bg-white dark:bg-gray-100 rounded-full w-[1rem] h-[1rem] lg:w-[1.3rem] lg:h-[1.3rem] flex flex-shrink-0 justify-center items-center relative">
                                                    <input onChange={() => handleRadioChange(item.id, '4')} aria-labelledby="label2" type="radio" value='4' name={`radio${item.id}`} className="checkbox appearance-none focus:opacity-100  focus:outline-none border rounded-full border-gray-400 absolute cursor-pointer w-full h-full checked:border-none" />
                                                    <div className="check-icon hidden border-[6px] border-primary-color rounded-full w-full h-full z-1"></div>
                                                </div>
                                                <div className="bg-white dark:bg-gray-100 rounded-full w-[1rem] h-[1rem] lg:w-[1.3rem] lg:h-[1.3rem] flex flex-shrink-0 justify-center items-center relative">
                                                    <input onChange={() => handleRadioChange(item.id, '5')} aria-labelledby="label2" type="radio" value='5' name={`radio${item.id}`} className="checkbox appearance-none focus:opacity-100  focus:outline-none border rounded-full border-gray-400 absolute cursor-pointer w-full h-full checked:border-none" />
                                                    <div className="check-icon hidden border-[6px] border-primary-color rounded-full w-full h-full z-1"></div>
                                                </div>
                                                <span className='text-sm'>Accurate</span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            {/* /////////////////////////////// Submit button ////////////////////////////////////// */}
                            <div className='btn flex justify-center items-center'>
                                {data?.currentPage !== data?.totalPages ?
                                    <button
                                        onClick={() => submitValues()}
                                        type='button' disabled={!isAllQuestionsAnswered()}
                                        className={`focus:outline-none w-28 text-white btn-color-primary  font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 ${isAllQuestionsAnswered() ? '' : 'opacity-50 cursor-not-allowed'}`}
                                    >
                                        continue
                                    </button> : <button
                                        onClick={() => submitValues()}
                                        type='button' disabled={!isAllQuestionsAnswered()}
                                        className={`focus:outline-none w-28 text-white btn-color-primary  font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 ${isAllQuestionsAnswered() ? '' : 'opacity-50 cursor-not-allowed'}`}
                                    >
                                        Submit
                                    </button>}
                            </div>
                        </div>

                        {/* /////////////////////////////// Adding Modal Window //////////////////////////////// */}
                        {modalWindowData?.selfEvaluationStatus === true ?
                            <ModalWindow show={modal} onClose={onClose}
                                         msgHeading='Great!'
                                         msg='We appreciate your valuable feedback'
                                         feedbackText='Interested in getting feedback from your
friends about you?'
                                         generatedLink={generatedLink}/> :
                        <ModalWindow
                            show={modal}
                            onClose={onClose}
                            msgHeading='Thank you!!'
                            msg='We appreciate your valuable feedback'
                            showEvaluateButton={true}
                            feedbackText='Interested in getting feedback from your
friends about you?'         generatedLink={""}
                        />}


                    </div>
                </div>)}
        </>
    )
}

export default MyFriendsQuestionnaire
