import React from "react";
import "./sideBar.css";
import { ReactComponent as Dashboard } from "../images/dashboard_ic.svg";
import { ReactComponent as Payment } from "../images/payment_ic.svg";
import { ReactComponent as Profile } from "../images/profile_ic.svg";
import { ReactComponent as Question } from "../images/question_ic.svg";
import { ReactComponent as Subscription } from "../images/subscription.svg";
import { ReactComponent as LogoutIcon } from "../images/Logout.svg";
import { ReactComponent as UsersIcon } from '../images/users_ic.svg'
import { ReactComponent as RevenueIcon } from '../images/earnings_ic.svg'
import { changeNavStatus } from "../redux/slices/NavStatusSlice";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {setUser} from "../redux/slices/UserSlice";
import {setQuestionnaireId} from "../redux/slices/QuestionnaireIdSlice";
import {setEmail} from "../redux/slices/LoginPageSlice";

const SideBar = ({isAdmin}) => {
  const navState = useSelector((state) => state.nav.navStatus);
  const location = useLocation();
  const path = location.pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log(typeof(path));

  const pathArray = path.split('/').filter(path => path!=='')

  // console.log(path);

  const userDashboardItems = [
    {
      id: 1,
      name: "dashboard",
      icon: <Dashboard />,
      path: "/user/dashboard",
    },

    {
      id: 2,
      name: "Quiz",
      icon: <Question />,
      path: "/user/quiz",
    },

    {
      id: 3,
      name: "subscription",
      icon: <Subscription />,
      path: "/user/subscription",
    },

    {
      id: 4,
      name: "payment",
      icon: <Payment />,
      path: "/user/payment",
    },

    {
      id: 5,
      name: "profile",
      icon: <Profile />,
      path: "/user/profile",
    },

  ];

  const adminDashboardItems = [
    {
      id: 1,
      name: 'dashboard',
      icon: <Dashboard />,
      path: '/admin/dashboard'
    },

    {
      id: 2,
      name: 'users',
      icon: <UsersIcon />,
      path: '/admin/users'
    },

    {
      id: 3,
      name: 'revenue',
      icon: <RevenueIcon />,
      path: '/admin/revenue'
    },

    {
      id: 4,
      name: 'profile',
      icon: <Profile />,
      path: '/admin/profile'
    },
  ]

  return (
    // className={`sideBar-main w-52 sm:w-60 h-screen border-r-2 transition-all duration-300 ${
    //   navState ? `translate-x-[0]` : `translate-x-[-100%]`
    // } md:translate-x-[0] z-30`}
    <div
      className={`sideBar-main z-40 bg-white flex flex-col justify-between fixed top-[3.9rem] left-[-100%] w-52 sm:w-60 h-[calc(100vh-4rem)] border-r-2 border-t-2 overflow-y-scroll no-scrollbar transition-all duration-300 ${
        navState && "left-[0]"
      } md:left-0`}
    >
      <ul className="mx-4 py-5 flex-col">
        {!isAdmin?userDashboardItems.map((item) => {
          const itemPath = item.path.split('/').filter(path => path!=='')

          return (
            <NavLink
              onClick={() => dispatch(changeNavStatus())}
              to={item.path}
              key={item.id}
            >
              <li
                className={`flex items-center justify-start text-base capitalize font-[500] gap-5 mb-1 p-3 ${
                  pathArray.includes(itemPath[0]) && pathArray.includes(itemPath[1]) && "active"
                }`}
              >
                <span>{item.icon}</span>{" "}
                <p className="break-all">{item.name}</p>
              </li>
            </NavLink>
          );
        }):
        adminDashboardItems.map((item) => {
          const itemPath = item.path.split('/').filter(path => path!=='')
          return (
            <NavLink
              onClick={() => dispatch(changeNavStatus())}
              to={item.path}
              key={item.id}
            >
              <li
                className={`flex items-center justify-start text-base capitalize font-[500] gap-5 mb-1 p-3 ${
                  pathArray.includes(itemPath[0]) && pathArray.includes(itemPath[1]) && "active"
                }`}
              >
                <span>{item.icon}</span>{" "}
                <p className="break-all">{item.name}</p>
              </li>
            </NavLink>
          );
        })
        }
      </ul>

      <div
        onClick={() => {
          localStorage.clear();
          navigate("/");
          dispatch(changeNavStatus());
          dispatch(setUser(null));
          dispatch(setEmail(""));
          dispatch(setQuestionnaireId(""));
        }}
        className="logout-container mb-10 flex gap-5 ml-7 items-center  cursor-pointer"
      >
        <LogoutIcon />
        <span className="text-base capitalize font-[500]">Logout</span>
      </div>
    </div>
  );
};

SideBar.propTypes = {
  isAdmin: PropTypes.bool,
};

export default SideBar;
