import React, {useEffect, useState} from 'react';
import Chart from 'react-apexcharts';
import humbleIcon from '../images/humble_ic.png';
import SelfAwareIcon from '../images/selfaware.png';
import NoEmotionsIcon from '../images/no_emotions.svg';
import LittleProudIcon from '../images/littleproud.png';
import SelfDepricatingIcon from '../images/selfdepricating.png';
import NeedAttentionIcon from '../images/needattention.png';
import { ReactComponent as OpenClose } from '../images/downArrow.svg';
import { ReactComponent as LockIcon } from '../images/lock_ic.svg';
import { useSelector } from 'react-redux';
import {authUser, setUser} from '../redux/slices/UserSlice';
import { useNavigate } from 'react-router';

import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react';
import {setError} from "../redux/slices/LoginErrorSlice";
import {setEmail} from "../redux/slices/LoginPageSlice";
import {setQuestionnaireId} from "../redux/slices/QuestionnaireIdSlice";
import Loader from "../components/Loader";
import {useParams} from "react-router-dom";

const options = {
  states: {
    hover: {
        filter: {
            type: 'none',
        }
    },
},
  chart: {
    toolbar: { show: false },
    zoom: { enabled: false },
  },
  colors: [
    function ({ dataPointIndex }) {
      if (dataPointIndex == 0) {
        return '#7EB1FC';
      }
      if (dataPointIndex == 1) {
        return 'var(--primary-color)';
      }
      return '#CAE9E4'
    }
  ],
  legend: {
    show: false
  },

  dataLabels: {
    enabled: false,
  },

  tooltip: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: '65%',
      distributed: true,
    },
  },

  xaxis: {
      min: 0,
      max: 100,
    categories: ['Your Opinion', 'Your Friends’ Opinion', 'Other Users'],
    colors: ['#CD1818'],
    labels: {
      formatter: (val) => {
        return `${val}%`;
      },
      
      style: { colors: ['#AAA492'], fontSize: '12px', },
    },
  },

  yaxis: {
    labels: {
      style: { fontSize: '11px', },
    },
  },


  grid: {
    show: true,
    borderColor: '#90A4AE',
    strokeDashArray: 0,
    position: 'back',

    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },

  responsive: [
    {
      breakpoint: 600,
      options: {
        xaxis: {
       
        labels: {
          
          style: { fontSize: '8px' },
        },
      },

      yaxis: {
        labels: {
          style: { fontSize: '7px', },
        },
      },

      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '55%',
          distributed: true,
        },
      },

    
      }
      
    }
  ]
};

const Insights = () => {

  const navigate = useNavigate()
  const user = useSelector(authUser)
  const [open, setOpen] = React.useState(0);
  const [open2, setOpen2] = React.useState(0);
  const [open3, setOpen3] = React.useState(0);
  const [open4, setOpen4] = React.useState(0);
  const [open5, setOpen5] = React.useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [reliabilityIcon, setReliabilityIcon] = useState([]);
  const [communicationIcon, setCommunicationIcon] = useState([]);
  const [empathyIcon, setEmpathyIcon] = useState([]);
  const [initiativeIcon, setInitiativeIcon] = useState([]);
  const [selfConfidenceIcon, setSelfConfidenceIcon] = useState([]);
  const {questionnaireId} = useParams()

  const handleOpen = (value) => setOpen(open === value ? 0 : value);
  const handleOpen2 = (value) => setOpen2(open2 === value ? 0 : value);
  const handleOpen3 = (value) => setOpen3(open3 === value ? 0 : value);
  const handleOpen4 = (value) => setOpen4(open4 === value ? 0 : value);
  const handleOpen5 = (value) => setOpen5(open5 === value ? 0 : value);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/questionnaire/${questionnaireId}/insight`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${user?.token}`,
                'Content-Type': 'application/json',
              },
            }
        );

        if (response.status === 401 || response.status===400) {
          localStorage.clear();
          dispatch(setError('Authentication failed. Please login again.'));
          dispatch(setUser(null));
          dispatch(setEmail(""));
          dispatch(setQuestionnaireId(""));
          navigate("/");
        }

        if (response.ok) {
          const result = await response.json();
          setData(result?.data);
          //
          if (data.friendEvaluationReliabilityTrait === 'Humble'){
            setReliabilityIcon('humbleIcon')
          }
        } else {
          navigate('/');
        }
      } catch (error) {
        navigate('/');
      } finally {
        setIsLoading(false);
      }
    };

    // Call the asynchronous function
    fetchData();
  }, [navigate]);
//reliabilityOptions


  const series = [
    {
      data: [23, 45, 15],
    },
  ];
const reliabilitySeries = [
    {
      data: [data.selfEvaluationReliabilityPercentage, data.friendEvaluationReliabilityPercentage, data.otherUserReliabilityPercentage ? data.otherUserReliabilityPercentage : 0],
    },
  ];
const communicationSeries = [
    {
      data: [data.selfEvaluationCommunicationPercentage, data.friendEvaluationCommunicationPercentage, data.otherUserCommunicationPercentage ? data.otherUserCommunicationPercentage : 0],
    },
  ];
const empathySeries = [
    {
      data: [data.selfEvaluationEmpathyPercentage, data.friendEvaluationEmpathyPercentage, data.otherUserEmpathyPercentage ? data.otherUserEmpathyPercentage : 0],
    },
  ];
const initiativeSeries = [
    {
      data: [data.selfEvaluationInitiativePercentage, data.friendEvaluationInitiativePercentage, data.otherUserInitiativePercentage ? data.otherUserInitiativePercentage : 0],
    },
  ];
const selfConfidenceSeries = [
    {
      data: [data.selfEvaluationSelfConfidencePercentage, data.friendEvaluationSelfConfidencePercentage, data.otherUserSelfConfidencePercentage ? data.otherUserSelfConfidencePercentage : 0],
    },
  ];


  return (
      <>{isLoading ? (
          <Loader />
      ) : (
    <div className='w-[100vw] md:w-[calc(97.8vw-223px)] lg:w-[calc(97.8vw-217px)] 2xl:w-[calc(98.7vw-220px)] h-[calc(100vh-3.6rem)] md:h-[calc(100vh-4rem)] flex md:ml-60 bg-gray-100'>
      <div className='w-full h-[calc(100vh-4rem)] px-4 md:px-10 py-4 flex flex-col gap-10'>
        <div className='header flex-col flex md:flex-row justify-between md:items-center '>
          <h2 className='font-bold mb-3 md:mb-0'>{data.name}</h2>
          {
            user.plan!=='Diamond' && user.plan!=='Gold'&&
            <button
            type='button'
            onClick={()=>navigate('/user/subscription')}
            className={`focus:outline-none text-white btn-color-primary w-full md:w-auto btn-color-primary font-medium rounded-lg text-sm px-5 py-2.5 me-2`}
          >
            Unlock Detailed Insights
            </button>
          }
        </div>


        <div className='flex flex-col rounded-lg gap-6 overflow-scroll no-scrollbar'>
        <div className='flex flex-col'>
          <div className='accordian-container flex flex-col lg:flex-row w-full rounded-tl-lg rounded-tr-lg border-b bg-white'>
            <div className='left w-[20%] h-full flex items-center px-6 pt-6 sm:p-6'>
              <p className='font-semibold 2xl:text-xl lg:break-all'>Reliability</p>
            </div>
            <div className='w-[100%] lg:w-[80%] px-0 sm:px-4  border-l-0 border-r-0 lg:border-l lg:border-r relative'>
              <Chart
                options={options}
                series={reliabilitySeries}
                height={120}
                type='bar'
              />
              <div className=' absolute right-3 bottom-[50%] pl-3'>
              <LockIcon/>
              </div>
            </div>
            <div className='w-[40%] lg:w-[20%] mx-auto mb-4 lg:mb-0'>
            <div className={`right w-full flex flex-row lg:flex-col gap-2 justify-center items-center h-full ${user.plan==='Gold'||user.plan==='Diamond'?'blur-none':'blur-md select-none'}`}>
              <img className='h-8 lg:h-auto' src={(data.friendEvaluationReliabilityTrait === 'Humble' && humbleIcon)
                  || (data.friendEvaluationReliabilityTrait === 'Needs Attention' && NeedAttentionIcon)
                  || (data.friendEvaluationReliabilityTrait === 'Self Deprecating' && SelfDepricatingIcon)
                  || (data.friendEvaluationReliabilityTrait === 'Self-Aware' && SelfAwareIcon)
                  || (data.friendEvaluationReliabilityTrait === 'Little Proud' && LittleProudIcon)
                  || (data.friendEvaluationReliabilityTrait === '' && NoEmotionsIcon)
                  || (data.friendEvaluationReliabilityTrait === null && NoEmotionsIcon)} alt='' />
              <h2 className='font-semibold'>{data.friendEvaluationReliabilityTrait}</h2>
            </div>
            </div>
          </div>
          <div className='accordian '>
            <Accordion className='bg-white rounded-bl-lg rounded-br-lg' open={open === 0}>
              <AccordionHeader
                className='border-none p-0'
                onClick={() => handleOpen(1)}
              >
                <p className='flex justify-center items-center w-full'>
                  <OpenClose
                    className={`${
                      open !== 1 && 'rotate-180'
                    } transition-all duration-300`}
                  />
                </p>
              </AccordionHeader>
              <AccordionBody className='p-6 pt-3'>
              {
                user.plan!=='free'?<div className='border-b-[1.5px] border-gray-200 mb-2'>
                <h2 className='font-semibold'>What is reliability</h2>
                <p className='mb-4'>This trait reflects how trustworthy and dedicated you are in your actions and promises. It refers to the consistent ability and willingness to maintain steady and dedicated towards responsibilities, goals and relationships.
                Predictability, organizational skills, and persistence have their basis on your reliability and commitment.
                 </p>
              </div>:''}
                <h3 className='font-semibold mb-4'>Interpretation</h3>
                {
                  data.selfEvaluationReliabilityPercentage ===0?<div className={`${user.plan==='Gold'||user.plan ==='Diamond'?'blur-none':'blur-sm select-none'}`}>Your evaluation is pending</div>:
                  <div>
                    <div className={`${user.plan==='Gold'||user.plan ==='Diamond'?'blur-none':'blur-sm select-none'}`}>
                 {
                  user.plan==='Gold'||user.plan==='Diamond'?<div>
                 {
                  data.selfEvaluationReliabilityPercentage >=61 && data.selfEvaluationReliabilityPercentage<=100&&
                  <div>
                    <h2 className='font-semibold '>Your Self Score interpretation</h2>
                    <p className='mb-4'>You perceive yourself as more reliable and committed than the average person. You feel that the dedication in your approach to responsibilities, goals, and relationships is constant and that it influences the achievement of your success; along with your ability to plan and handle various tasks effectively. This commitment goes beyond mere mechanical dependability. You emotionally engage in what you set out to do, and this plays a significant role not only in your goals but also in your interpersonal relationships and beliefs. This makes you not easily deterred by challenges or setbacks; instead, you remain focused in the long term and overcome obstacles with determination and resilience.</p>
                  </div>
                 }

                  {
                  data.selfEvaluationReliabilityPercentage>= 31 && data.selfEvaluationReliabilityPercentage<= 60&&
                  <div>
                  <h2 className='font-semibold'>Your Self Score interpretation</h2>
                  <p className='mb-4'>You perceive yourself as a reliable person, though you might not label it as your strongest suit. Your results indicate a balanced approach to handling responsibilities in both your personal and professional life. Regarding organization, you have no trouble effectively managing tasks, but you prefer to take it step by step to avoid reaching a saturation point where your ability crumbles, causing you to lose momentum. You dont let emotional attachment sway your commitment; in this regard, you represent a balanced and pragmatic individual. However, this may lead to quick discouragement if you dont swiftly overcome an obstacle.
                 </p>
                  </div>
                  }

                 {
                  data.selfEvaluationReliabilityPercentage>=1 && data.selfEvaluationReliabilityPercentage<=30&&
                  <div>
                 <h2 className='font-semibold'>Your Self Score interpretation</h2>
                 <p className='mb-4'>You perceive yourself as a less reliable and committed person compared to others. Meeting deadlines, especially in routine or repetitive situations, might be challenging for you. This flexibility can be advantageous in environments that require adaptability and quick changes. You tend to have minimal emotional involvement with long-term goals, projects, or relationships, approaching them casually with a frequent inclination towards change. When managing tasks, you prefer to be spontaneous and fluid. Rigorous organization levels and meticulous planning are not things that come naturally to you;  prefering to improvise in most cases. While you are capable of overcoming obstacles, they have the potential to easily change your mind, leading to a tendency not to complete tasks initially set out.
                 </p>
                 </div>

                 }


                 {
                  data.friendEvaluationReliabilityTrait==='Self Deprecating'&&
                  <div>
                 <h2 className='font-semibold'>Your Friends Score interpretation (Self-deprecating)</h2>
                 <p className='mb-4'>That means that your friends see a higher level of reliability and commitment in you than you acknowledge in yourself. This is a beautiful reminder that others appreciate your dependability more than you might realize. Its time to recognize your strengths and acknowledge the trust others have in you. Embrace the fact that your actions leave a lasting, positive impact on those around you.
                 Its good to be humble and have a bit of modesty, but never to the point where you detach from reality and perceive yourself as less than you truly are. This false perception of reality can hinder you from making decisions that would ultimately be beneficial. In this way, you miss out on the good things in life, and the worst part is that you dont even realize it!
                 </p>
                 </div>

                }


                {
                data.friendEvaluationReliabilityTrait==='Humble'&&
                <div>
                 <h2 className='font-semibold'>Your Friends Score interpretation (Humble)</h2>
                 <p className='mb-4'>While you acknowledge your strengths in reliability and commitment, your friends are even more impressed by your consistency and dedication. They depend on you, knowing you will follow through on promises and uphold your values. They perceive you as a source of security and comfort for those around you. You are just a tad more dependable and committed than you perceive yourself. This can be a modest difference, but it indicates that your dedication is noticeable to others. Be happy with it and appreciate the trust your friends bestow upon you.
                 </p>
                 </div>

                }


                 {
                  data.friendEvaluationReliabilityTrait==='Self-Aware'&&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation (Self-aware)</h2>
                 <p className='mb-4'>You understand your strengths and limitations when it comes to reliability and commitment. You acknowledge with accuracy when you follow through and when you fall into deviations. Your friends certainly see the same and appreciate your honesty and transparency, recognizing your dedication while understanding that life presents unexpected challenges.
                 </p>
                  </div>

                 }


                {
                  data.friendEvaluationReliabilityTrait === 'Little Proud'&&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation</h2>
                 <p className='mb-4'>While your friends acknowledge your dependability, they might see a tendency to overestimate your own commitment. You might take on more than you can handle or set unrealistic expectations for yourself, leading to occasional missed deadlines or unfulfilled promises. This occasional slip-up does not negate your overall reliability, but perhaps a touch of perspective could allow you to manage your commitments more effectively.
                 </p>
                  </div>
                }


                 {
                  data.friendEvaluationReliabilityTrait === 'Needs Attention' &&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation</h2>
                  <p className='mb-4'>The image you have of yourself as someone who comes through on promises and dedicates themselves fully to achieving goals sadly is not shared among by those around you, they might paint a different picture and perceive you as unreliable or prone to easily abandoning commitments when things get tough. This disconnect could stem from overestimation of your own dedication or a tendency to take on more than you can handle.
                 </p>
                  </div>
                 }

                  </div>
                  :
                  <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Odio fugit ipsa hic quibusdam officia reiciendis dolorum doloremque est maxime obcaecati iure voluptate laborum perferendis commodi quam, illo unde sapiente qui cupiditate! Quas placeat accusantium temporibus laborum ipsum similique quod cupiditate?</p>
                 }
                </div>
                  </div>
                }
              </AccordionBody>
            </Accordion>
          </div>
        </div>


        <div className='flex flex-col'>
          <div className='accordian-container flex flex-col lg:flex-row w-full rounded-tl-lg rounded-tr-lg border-b bg-white'>
          <div className='left w-[20%] h-full flex items-center px-6 pt-6 sm:p-6'>
              <p className='font-semibold 2xl:text-xl lg:break-all'>Communication</p>
            </div>
            <div className='w-[100%] lg:w-[80%] px-0 sm:px-4 border-l-0 border-r-0 sm:border-l sm:border-r relative'>
              <Chart
                options={options}
                series={communicationSeries}
                height={120}
                type='bar'
              />
              <div className=' absolute right-3 bottom-[50%] pl-3'>
              <LockIcon/>
              </div>
            </div>
            <div className='w-[40%] lg:w-[20%] mx-auto mb-4 lg:mb-0'>
            <div className={`right w-full flex flex-row lg:flex-col gap-2 justify-center items-center h-full ${user.plan==='Gold'||user.plan==='Diamond'?'blur-none':'blur-md select-none'}`}>
              <img className='h-8 lg:h-auto' src={(data.friendEvaluationCommunicationTrait === 'Humble' && humbleIcon)
                  || (data.friendEvaluationCommunicationTrait === 'Needs Attention' && NeedAttentionIcon)
                  || (data.friendEvaluationCommunicationTrait === 'Self Deprecating' && SelfDepricatingIcon)
                  || (data.friendEvaluationCommunicationTrait === 'Self-Aware' && SelfAwareIcon)
                  || (data.friendEvaluationCommunicationTrait === 'Little Proud' && LittleProudIcon)
                  || (data.friendEvaluationCommunicationTrait === '' && NoEmotionsIcon)
                  || (data.friendEvaluationCommunicationTrait === null && NoEmotionsIcon)} alt='' />
              <h2 className='font-semibold'>{data.friendEvaluationCommunicationTrait}</h2>
            </div>
            </div>
          </div>
          <div className='accordian '>
            <Accordion className='bg-white rounded-bl-lg rounded-br-lg' open={open2 === 0}>
              <AccordionHeader
                className='border-none p-0'
                onClick={() => handleOpen2(2)}
              >
                <p className='flex justify-center items-center w-full'>
                  <OpenClose
                    className={`${
                      open2 !== 2 && 'rotate-180'
                    } transition-all duration-300`}
                  />
                </p>
              </AccordionHeader>
              <AccordionBody className='p-6 pt-3'>
              {
                user.plan!=='free'?
                <div className='border-b-[1.5px] border-gray-200 mb-2'>
                <h2 className='font-semibold'>What is Communication</h2>
                 <p className='mb-4'>This trait shows your ability to convey information, understand others, and maintain positive social interactions. Its a mixture of verbal and non-verbal communication, empathy, active listening, and conflict resolution. Your capacity to connect with others and navigate social dynamics have their basis on your Communication and Interpersonal Skills.
                </p>
                </div>
                :''
                }

                <h3 className='font-semibold mb-4'>Interpretation</h3>

                {
                  data.selfEvaluationCommunicationPercentage===0?<div className={`${user.plan==='Gold'||user.plan==='Diamond'?'blur-none':'blur-sm select-none'}`}>Your evaluation is pending</div>:
                  <div>
                <div className={`${user.plan==='Gold'||user.plan==='Diamond'?'blur-none':'blur-sm select-none'}`}>
               {

                user.plan==='Gold'||user.plan==='Diamond'?<div>


                {
                  data.selfEvaluationCommunicationPercentage>=61&& data.selfEvaluationCommunicationPercentage<=100&&
                  <div>
                    <h2 className='font-semibold '>Your Self Score interpretation</h2>
                <p className='mb-4'>You possess the ability to articulate your thoughts effectively and understand those of others, making you a valuable participant in social or professional settings. Empathy is the cornerstone of your interpersonal relationships; you genuinely show interest in your peers, allowing you to connect with them meaningfully.

                In conflict situations, you usually can comprehend both sides and find solutions with mutual benefits. Your non-verbal communication is also one of your strengths. You can interpret body language and have no problem in reading the room, enabling you to understand social dynamics easily.</p>
                  </div>

                }

                {
                  data.selfEvaluationCommunicationPercentage>=31&& data.selfEvaluationCommunicationPercentage<=60&&
                  <div>
                    <h2 className='font-semibold'>Your Self Score interpretation</h2>
                <p className='mb-4'>You exhibit the ability to engage in everyday conversations, although you may not always articulate your ideas in the best way. You are capable of understanding the emotions of others and grasping the general idea, although you may miss certain subtleties or deeper meanings in intimate conversations. Regarding non-verbal communication, you have no trouble picking up on facial expressions and body language, but reading the room may be challenging at times.

                In conflict situations, you tend to be more pragmatic and try to resolve things with minimal friction. You generally navigate disagreements without escalating them, although the outcomes might not always be as constructive as they could be.
                </p>
                  </div>

                }

                {
                  data.selfEvaluationCommunicationPercentage>=1&& data.selfEvaluationCommunicationPercentage<=30&&
                  <div>
                    <h2 className='font-semibold'>Your Self Score interpretation</h2>
                    <p className='mb-4'>Perhaps you may not be the best when it comes to translating thoughts into words, which can make effective communication a bit of a challenge. Occasionally, this may lead to misunderstandings, making the conversation feel awkward, and you may feel that you are not truly being understood by others.

                    In conflict situations, you may have a solution but find it difficult to communicate it in a way that makes both sides of the argument feel comfortable. Non-verbal communication can pose a challenge, causing you to not entirely grasp certain social cues and social dynamics.
                    </p>
                  </div>

                }

                {
                  data.friendEvaluationCommunicationTrait==='Self Deprecating'&&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation (Self-deprecating)</h2>
                    <p className='mb-4'>You are underestimating your ability to connect with others and express yourself clearly. Your friends definitely find you a good listener, someone who offers insightful perspectives, and someone who fosters open and honest communication. Effective communication is not just about speaking up, its also about actively listening and creating a safe space for dialogue. Take a moment to appreciate the positive impact you have through your effective communication and realize that your words and interactions have left a lasting impression on others.
                </p>
                  </div>
                }


                {
                  data.friendEvaluationCommunicationTrait==='Humble'&&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation (Humble)</h2>
                    <p className='mb-4'>You underestimate your skills to connect genuinely and communicate effectively and might do a better role as a listener, speaker, and facilitator of open dialogue than you give credit to yourself. Your honest and approachable nature creates a safe space for others to share and connect. Creating a positive atmosphere with your words might be a hidden talent you have and is noticed by those around you. Continue honing your communication skills while recognizing the positive impact you have on your interactions.
                </p>
                  </div>
                }


                {
                  data.friendEvaluationCommunicationTrait==='Self-Aware'&&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation (Self-aware)</h2>
                    <p className='mb-4'>You are keenly aware of your communication style and its impact on others. You recognize your strengths in expressing yourself clearly and building rapport, but also acknowledge areas where you can improve, we could say that you have your feet on the ground. Your friends value your open communication and share the opinion you have of yourself, so you are pretty good when it comes to recognizing your abilities, good job!
                </p>
                  </div>
                }

                {
                  data.friendEvaluationCommunicationTrait === 'Little Proud' &&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation</h2>
                <p className='mb-4'>You see yourself as a good communicator, capable of expressing yourself clearly and connecting with others, engaging in conversations, and valuing open communication. Your friends appreciate your communication skills, but they might perceive you as sometimes either dominating conversations or contributing little to them. Showing sometimes an enthusiasm and eagerness to share your thoughts can overshadow others but being overshadowed is as easy. Asking open-ended questions and showing genuine interest in what others have to say will result in better communication, its just a matter of practice.
                </p>
                  </div>
                }

                {
                  data.friendEvaluationCommunicationTrait === 'Needs Attention' &&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation</h2>
                <p className='mb-4'>Its difficult to get an idea expressed and communicated in such a way that everyone understands it, and maybe you can misunderstand conversations or be a little unclear more often than not. This gap between your opinion and that of those around you could arise from overlooking active listening, assuming your perspective is always understood, or neglecting non-verbal cues. Try to make a more conscious effort when engaging in conversation and that will help you understand were you are lacking.
                </p>
                  </div>
                }


               </div>
               :
               <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam fugit quo corporis sed veniam, cumque accusantium deserunt eligendi quae suscipit. Obcaecati quas eum nihil laboriosam, harum quisquam saepe, consectetur sint modi nobis dicta voluptatum pariatur assumenda architecto necessitatibus ipsum adipisci.</p>
               }
                </div>
                </div>}
              </AccordionBody>
            </Accordion>
          </div>
        </div>


        <div className='flex flex-col'>
          <div className='accordian-container flex flex-col lg:flex-row w-full rounded-tl-lg rounded-tr-lg border-b bg-white'>
            <div className='left w-[20%] h-full flex items-center px-6 pt-6 sm:p-6'>
              <p className='font-semibold 2xl:text-xl lg:break-all'>Empathy</p>
            </div>
            <div className='w-[100%] lg:w-[80%] px-0 sm:px-4 border-l-0 border-r-0 sm:border-l sm:border-r relative'>
              <Chart
                options={options}
                series={empathySeries}
                height={120}
                type='bar'
              />
              <div className=' absolute right-3 bottom-[50%] pl-3'>
              <LockIcon/>
              </div>
            </div>
            <div className='w-[40%] lg:w-[20%] mx-auto mb-4 lg:mb-0'>
            <div className={`right w-full flex flex-row lg:flex-col gap-2 justify-center items-center h-full ${user.plan==='Gold'||user.plan==='Diamond'?'blur-none':'blur-md select-none'}`}>
              <img className='h-8 lg:h-auto' src={(data.friendEvaluationEmpathyTrait === 'Humble' && humbleIcon)
                  || (data.friendEvaluationEmpathyTrait === 'Needs Attention' && NeedAttentionIcon)
                  || (data.friendEvaluationEmpathyTrait === 'Self Deprecating' && SelfDepricatingIcon)
                  || (data.friendEvaluationEmpathyTrait === 'Self-Aware' && SelfAwareIcon)
                  || (data.friendEvaluationEmpathyTrait === 'Little Proud' && LittleProudIcon)
                  || (data.friendEvaluationEmpathyTrait === '' && NoEmotionsIcon)
                  || (data.friendEvaluationEmpathyTrait === null && NoEmotionsIcon)} alt='' />
              <h2 className='font-semibold'>{data.friendEvaluationEmpathyTrait}</h2>
            </div>
            </div>
          </div>
          <div className='accordian '>
            <Accordion className='bg-white rounded-bl-lg rounded-br-lg' open={open3 === 0}>
              <AccordionHeader
                className='border-none p-0'
                onClick={() => handleOpen3(3)}
              >
                <p className='flex justify-center items-center w-full'>
                  <OpenClose
                    className={`${
                      open3 !== 3 && 'rotate-180'
                    } transition-all duration-300`}
                  />
                </p>
              </AccordionHeader>
              <AccordionBody className='p-6 pt-3'>
              {
                user.plan!=='free'?
                <div className='border-b-[1.5px] mb-2 border-gray-200'>
                  <h2 className='font-semibold'>What is Empathy</h2>
                 <p className='mb-4'>This trait refers to the ability to be naturally attuned to the emotions of others. Essentially, it involves understanding and feeling the perspectives and emotions of those around you. An empathetic nature allows you to connect deeply with others and make them feel understood and appreciated.
                </p>
                </div>
                :
                ''
              }

                <h3 className='font-semibold mb-4'>Interpretation</h3>


                {
                  data.selfEvaluationEmpathyPercentage === 0?<div className={`${user.plan==='Gold'||user.plan==='Diamond'?'blur-none':'blur-sm select-none'}`}>Your evaluation is pending</div>:
                  <div className={`${user.plan==='Gold'||user.plan==='Diamond'?'blur-none':'blur-sm select-none'}`}>
                {
                  user.plan==='Gold'||user.plan==='Diamond'?<div>


                {
                  data.selfEvaluationEmpathyPercentage>=61 && data.selfEvaluationEmpathyPercentage<=100&&
                  <div>
                    <h2 className='font-semibold '>Your Self Score interpretation</h2>
                    <p className='mb-4'>A natural ability to comfort and offer assistance allows you to understand what others are feeling, sometimes without the need for explicit communication. This enables you to establish meaningful connections with other people. Your social relationships are characterized by trust and mutual respect. These compassionate behaviors create a caring environment, encouraging others to be open and genuine with you. Your supportive nature is not just about responding to needs; its about anticipating them and being a steady source of comfort and aid.</p>
                  </div>
                }

                {
                  data.selfEvaluationEmpathyPercentage>=31 && data.selfEvaluationEmpathyPercentage<=60&&
                  <div>
                    <h2 className='font-semibold'>Your Self Score interpretation</h2>
                    <p className='mb-4'>You present a balanced approach when it comes to understanding and resonating with the emotions of others. You usually comprehend and offer help and comfort without letting it affect you personally or emotionally. Your social relationships are generally characterized by friendly and cooperative interactions. People perceive you as considerate and approachable, even though you may not feel that you are particularly empathetic. Your assistance towards others is selfless, but you may not be as proactive in recognizing the needs of others. You tend to be more practical and direct, preferring to provide tangible help rather than emotional support.
                </p>
                  </div>
                }

                {
                  data.selfEvaluationEmpathyPercentage>=1 && data.selfEvaluationEmpathyPercentage<=30&&
                  <div>
                    <h2 className='font-semibold'>Your Self Score interpretation</h2>
                    <p className='mb-4'>You may often feel that understanding others is challenging for you, and feeling what they feel is not something that comes naturally. It might be necessary for others to be explicit about their feelings. When offering help, you prefer practical solutions over emotionally engaging with the emotional aspects of problems, which you see as mostly an obstacle. This practical approach makes you valuable for specific issues but not as a source of emotional support. At times, this makes you feel unsure or uncomfortable, leading you to step back rather than engage in those situations. You might prefer interactions that are more task-oriented or less emotionally demanding.
                </p>
                  </div>
                }

                {
                  data.friendEvaluationEmpathyTrait==='Self Deprecating'&&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation (Self-deprecating)</h2>
                    <p className='mb-4'>You might miss out on recognizing the genuine care and compassion you offer to others. Your friends likely appreciate your willingness to listen without judgment, offer a helping hand, and provide emotional support. Do not underestimate the impact you have on peoples lives. Your ability to empathize and connect on a deeper level is a valuable gift. You understand and stand by others in both joy and difficulty. Embrace the warmth you bring to your relationships, knowing that your empathetic nature makes a positive difference in the lives of those around you. These things are better appreciated if shared and you are already doing the sharing part, just need to recognize it!
                </p>
                  </div>
                }

                {
                  data.friendEvaluationEmpathyTrait==='Humble'&&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation (Humble)</h2>
                    <p className='mb-4'>You downplay your ability to empathize and support others, but your friends likely feel your genuine care and concern. They appreciate and recognize your ability to listen without judgment, offer a shoulder to lean on, and provide practical support makes you a pillar of strength for those around you, a compassionate and supportive presence, capable of understanding and standing by others in both joy and difficulty. Do not underestimate your natural gift for empathy and embrace the positive influence you have on peoples lives.
                </p>
                  </div>
                }

                {
                  data.friendEvaluationEmpathyTrait==='Self-Aware'&&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation (Self-aware)</h2>
                    <p className='mb-4'>You possess a good understanding of your own emotional intelligence and your capacity for empathy, recognizing your ability to offer a supportive presence, while acknowledging that there is always room to grow in understanding and validating others emotions. This is shown in the opinion of your friends, that know what to expect from you when sharing their inner world.
                </p>
                  </div>
                }

                {
                  data.friendEvaluationEmpathyTrait === 'Little Proud' &&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation</h2>
                <p className='mb-4'>You see yourself as empathic and supportive, ready to offer a listening ear and a helping hand. On the other hand, your friends appreciate your support, but they might sometimes feel misunderstood or even feel emotionally unsupported. There are times in which people just want to heard and while offering practical advice is good, its not always what is needed, allowing space for others contributes to more balanced and engaging personal relationships.</p>
                  </div>
                }

                {
                  data.friendEvaluationEmpathyTrait === 'Needs Attention' &&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation</h2>
                <p className='mb-4'>While you might be helpful and often offer practical advice, those around you might perceive you as emotionally distant, maybe a little insensitive, or offering unsolicited advice instead of genuine support. This could have its origin in a misunderstanding of emotional cues or a tendency to prioritize problem-solving over emotional validation. Sometimes validating feelings before offering solutions can make others feel understood and will make you connect and support others in a better way.
                </p>
                  </div>
                }

                </div>
                :
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Suscipit dolorem ipsam facere, voluptates odit rem harum sit porro praesentium rerum, quis repudiandae tempore omnis nulla soluta. Fugiat nihil fuga voluptatem perferendis dolore, sed in nulla dolorum esse id voluptates eum.</p>
                }
                </div>}
              </AccordionBody>
            </Accordion>
          </div>
        </div>



        <div className='flex flex-col'>
          <div className='accordian-container flex flex-col lg:flex-row w-full rounded-tl-lg rounded-tr-lg border-b bg-white'>
            <div className='left w-[20%] h-full flex items-center px-6 pt-6 sm:p-6'>
              <p className='font-semibold 2xl:text-xl lg:break-all'>Initiative</p>
            </div>
            <div className='w-[100%] lg:w-[80%] px-0 sm:px-4 border-l-0 border-r-0 sm:border-l sm:border-r relative'>
              <Chart
                options={options}
                series={initiativeSeries}
                height={120}
                type='bar'
              />
              <div className=' absolute right-3 bottom-[50%] pl-3'>
              <LockIcon/>
              </div>
            </div>
            <div className='w-[40%] lg:w-[20%] mx-auto mb-4 lg:mb-0'>
            <div className={`right w-full flex flex-row lg:flex-col gap-2 justify-center items-center h-full ${user.plan==='Gold'||user.plan==='Diamond'?'blur-none':'blur-md select-none'}`}>
              <img className='h-8 lg:h-auto' src={(data.friendEvaluationInitiativeTrait === 'Humble' && humbleIcon)
                  || (data.friendEvaluationInitiativeTrait === 'Needs Attention' && NeedAttentionIcon)
                  || (data.friendEvaluationInitiativeTrait === 'Self Deprecating' && SelfDepricatingIcon)
                  || (data.friendEvaluationInitiativeTrait === 'Self-Aware' && SelfAwareIcon)
                  || (data.friendEvaluationInitiativeTrait === 'Little Proud' && LittleProudIcon)
                  || (data.friendEvaluationInitiativeTrait === '' && NoEmotionsIcon)
                  || (data.friendEvaluationInitiativeTrait === null && NoEmotionsIcon)} alt='' />
              <h2 className='font-semibold'>{data.friendEvaluationInitiativeTrait}</h2>
            </div>
            </div>
          </div>
          <div className='accordian '>
            <Accordion className='bg-white rounded-bl-lg rounded-br-lg' open={open4 === 0}>
              <AccordionHeader
                className='border-none p-0'
                onClick={() => handleOpen4(4)}
              >
                <p className='flex justify-center items-center w-full'>
                  <OpenClose
                    className={`${
                      open4 !== 4 && 'rotate-180'
                    } transition-all duration-300`}
                  />
                </p>
              </AccordionHeader>
              <AccordionBody className='p-6 pt-3'>

                {
                  user.plan!=='free'?
                  <div className='border-b-[1.5px] border-gray-200 mb-2'>
                    <h2 className='font-semibold'>What is Initiative</h2>
                    <p className='mb-4'>This trait reflects is about the predisposition to take charge and act independently, anticipating needs and challenges, blending forward thinking and proactiveness. A self-starting nature of initiative and taking the lead without being prompted, leadership and effective problem-solving have their basis here.
                </p>
                  </div>
                  :
                  ''
                }
                <h3 className='font-semibold mb-4'>Interpretation</h3>


                {
                  data.selfEvaluationInitiativePercentage===0?<div className={`${user.plan==='Gold'||user.plan==='Diamond'?'blur-none':'blur-sm select-none'}`}>Your evaluation is pending</div>:
                  <div className={`${user.plan==='Gold'||user.plan==='Diamond'?'blur-none':'blur-sm select-none'}`}>
                {

                  user.plan==='Gold'||user.plan==='Diamond'?<div>


                {
                  data.selfEvaluationInitiativePercentage>=61 && data.selfEvaluationInitiativePercentage<=100&&
                  <div>
                    <h2 className='font-semibold '>Your Self Score interpretation</h2>
                    <p className='mb-4'>A self-starter and a forward-thinker are some things that might describe you well. Identifying what needs to be done and taking action is something you do without being asked. You often have a self-motivated approach which makes you a valuable asset in professional and personal endeavors, often inspiring others to follow suit.
                    Envisioning the future allows you to prepare for it, and tackle challenges as they surge without letting them escalate and get out of hands. In relation to others, you are often the leader and have no problem in taking responsibility, showing confidence and decisiveness.</p>
                  </div>
                }

                {
                  data.selfEvaluationInitiativePercentage>=31 && data.selfEvaluationInitiativePercentage<=60&&
                  <div>
                    <h2 className='font-semibold'>Your Self Score interpretation</h2>
                <p className='mb-4'>A self-starter and a forward-thinker are some things that might describe you well. Identifying what needs to be done and taking action is something you do without being asked. You often have a self-motivated approach which makes you a valuable asset in professional and personal endeavors, often inspiring others to follow suit.
                Envisioning the future allows you to prepare for it, and tackle challenges as they surge without letting them escalate and get out of hands. In relation to others, you are often the leader and have no problem in taking responsibility, showing confidence and decisiveness.
                </p>
                  </div>
                }

                {
                  data.selfEvaluationInitiativePercentage>=1 && data.selfEvaluationInitiativePercentage<=30&&
                  <div>
                    <h2 className='font-semibold'>Your Self Score interpretation</h2>
                <p className='mb-4'>You are more reserved than others when it comes to taking decisions and leadership, showing generally a less pronounced inclination to take independent action, and preferring clear instructions and guidance when entering a new project. Contributing as part of a team might make you feel more comfortable than leading initiatives, this makes you suited for collaborative settings where supporting roles are needed to balance out the teamwork. You may also prefer familiar methods and solutions. However, your consistency and reliability in executing known tasks and procedures can be highly valued, especially in roles that require meticulousness and adherence.
                </p>
                  </div>
                }

                {
                  data.friendEvaluationInitiativeTrait==='Self Deprecating'&&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation (Self-deprecating)</h2>
                <p className='mb-4'>According to your friends, you are more proactive than you think. Your ability to take the lead and tackle challenges head-on is noticeable to others. Give yourself enough credit for your ability to take initiative and drive things forward. Your friends likely see you as someone who takes action, solves problems, and gets things done. Humility is important but do not shy away from taking ownership and leading the way when necessary. Trust your instincts and your ability to make things happen. Your proactive approach contributes to the well-being of those you interact with, and you do not even notice it!
                </p>
                  </div>
                }


                {
                  data.friendEvaluationInitiativeTrait==='Humble'&&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation (Humble)</h2>
                <p className='mb-4'>You might not fully appreciate your ability to take initiative and drive things forward. While not always seeking the spotlight, your friends notice your resourcefulness, problem-solving skills, and willingness to get things done. It takes some courage to take the lead, tackle challenges head-on and contribute to success, and thats something that people around you notice more often than you think! Continue taking initiative with your characteristic humility, knowing your contributions are valuable and appreciated.
                </p>
                  </div>
                }


                {
                  data.friendEvaluationInitiativeTrait==='Self-Aware'&&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation (Self-aware)</h2>
                <p className='mb-4'>You have a clear understanding of your drive and resourcefulness. You recognize your ability to take initiative and drive projects forward, but also acknowledge your limitations and the importance of collaborating with others in a realistic manner. This self-awareness is evidenced by the opinion of your friends, who respect you and know how you act as a leader and as a team member.
                </p>
                  </div>
                }


                {
                  data.friendEvaluationInitiativeTrait === 'Little Proud' &&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation</h2>
                <p className='mb-4'>You believe in taking charge and driving projects forward and you are likely self-motivated and enjoy seeing your ideas come to life, who does not? Those traits are noticed by those around you. However, your friends might sometimes feel like your drive and resourcefulness is not as high as you perceive them. Maybe you have been modest and have not shown your talents or maybe you give the wrong signals, whatever it is, now you can do a conscious effort to improve yourself.
                </p>
                  </div>
                }


                {
                  data.friendEvaluationInitiativeTrait === 'Needs Attention' &&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation</h2>
                <p className='mb-4'>Considering yourself a self-starter is great, but maybe you are sending the wrong signals. While you see yourself driving projects forward, brimming with ideas, and effortlessly taking charge, your friends might struggle to keep up and perceive you as impulsive or overbearing. This gap could arise from failing to delegate effectively or neglecting the planning and execution stages before jumping into action. With a more conscious effort you can turn your drive into a collaborative force that inspires
                </p>
                  </div>
                }

                </div>
                :
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Autem est pariatur consequatur fugiat? Iure reprehenderit, vel eaque voluptates corrupti eveniet laboriosam dolorum itaque iusto provident. Totam soluta a, pariatur reiciendis nisi dolor adipisci, saepe expedita fuga, repellat voluptate possimus eum.</p>
                }
                </div>}
              </AccordionBody>
            </Accordion>
          </div>
        </div>


        <div className='flex flex-col'>
          <div className='accordian-container flex flex-col lg:flex-row w-full rounded-tl-lg rounded-tr-lg border-b bg-white'>
            <div className='left w-[20%] h-full flex items-center px-6 pt-6 sm:p-6'>
              <p className='font-semibold 2xl:text-xl lg:break-all'>Self-Confidence</p>
            </div>
            <div className='w-[100%] lg:w-[80%] px-0 sm:px-4 border-l-0 border-r-0 sm:border-l sm:border-r relative'>
              <Chart
                options={options}
                series={selfConfidenceSeries}
                height={120}
                type='bar'
              />
              <div className=' absolute right-3 bottom-[50%] pl-3'>
              <LockIcon/>
              </div>
            </div>
            <div className='w-[40%] lg:w-[20%] mx-auto mb-4 lg:mb-0'>
            <div className={`right w-full flex flex-row lg:flex-col gap-2 justify-center items-center h-full ${user.plan==='Gold'||user.plan==='Diamond'?'blur-none':'blur-md select-none'}`}>
              <img className='h-8 lg:h-auto' src={(data.friendEvaluationSelfConfidenceTrait === 'Humble' && humbleIcon)
                  || (data.friendEvaluationSelfConfidenceTrait === 'Needs Attention' && NeedAttentionIcon)
                  || (data.friendEvaluationSelfConfidenceTrait === 'Self Deprecating' && SelfDepricatingIcon)
                  || (data.friendEvaluationSelfConfidenceTrait === 'Self-Aware' && SelfAwareIcon)
                  || (data.friendEvaluationSelfConfidenceTrait === 'Little Proud' && LittleProudIcon)
                  || (data.friendEvaluationSelfConfidenceTrait === '' && NoEmotionsIcon)
                  || (data.friendEvaluationSelfConfidenceTrait === null && NoEmotionsIcon)} alt='' />
              <h2 className='font-semibold'>{data.friendEvaluationSelfConfidenceTrait}</h2>
            </div>
            </div>
          </div>
          <div className='accordian '>
            <Accordion className='bg-white rounded-bl-lg rounded-br-lg' open={open5 === 0}>
              <AccordionHeader
                className='border-none p-0'
                onClick={() => handleOpen5(5)}
              >
                <p className='flex justify-center items-center w-full'>
                  <OpenClose
                    className={`${
                      open5 !== 5 && 'rotate-180'
                    } transition-all duration-300`}
                  />
                </p>
              </AccordionHeader>
              <AccordionBody className='p-6 pt-3'>

                {
                  user.plan!=='free'?
                  <div>
                    <h2 className='font-semibold'>What is Self-Confidence</h2>
                 <p className='mb-4'>This trait refers to your belief in your own abilities and judgment, regarding your decision making and autonomy. Being fundamental for your resilience, and that general feeling that you are doing the right thing, fostering an inner environment of security.
                </p>
                  </div>
                  :
                  ''
                }
                <h3 className='font-semibold mb-4'>Interpretation</h3>


                {
                  data.selfEvaluationSelfConfidencePercentage===0?<div className={`${user.plan==='Gold'||user.plan==='Diamond'?'blur-none':'blur-sm select-none'}`}>Your evaluation is pending</div>:
                  <div className={`${user.plan==='Gold'||user.plan==='Diamond'?'blur-none':'blur-sm select-none'}`}>
                {

                  user.plan==='Gold'||user.plan==='Diamond'?<div>


                {
                  data.selfEvaluationSelfConfidencePercentage>=61 && data.selfEvaluationSelfConfidencePercentage<=100&&
                  <div>
                    <h2 className='font-semibold '>Your Self Score interpretation</h2>
                <p className='mb-4'>A positive and assertive attitude is the result of the confidence that you have in yourself, that allows you to approach situations with little or no stress. Challenges are less likely to deter you and you often bounce back from setbacks with a renewed focus and determination. You already know that challenges are doable with the right mindset.
                Your confidence is not only beneficial to yourself but also to others since you are usually comfortable in situations where assertiveness and clarity are required. This positions you as a reliable and influential figure in group dynamics, whether in your personal relationships or professional life.</p>
                  </div>
                }

                {
                  data.selfEvaluationSelfConfidencePercentage>=31 && data.selfEvaluationSelfConfidencePercentage<=60&&
                  <div>
                    <h2 className='font-semibold'>Your Self Score interpretation</h2>
                <p className='mb-4'>Regarding confidence in yourself, you could say that you are balanced. This means that you can recognize your potential and value without being blinded by overconfidence, approaching tasks and decisions with reasonable caution, and a willingness to seek input when needed. Socially, you consider your voice as valid as others, a characteristic that makes you appear friendly, and people feel comfortable sharing quality moments with you. This is also reflected in the professional environment, making you an adaptable and cooperative team member.
                </p>
                  </div>
                }

                {
                  data.selfEvaluationSelfConfidencePercentage>=1 && data.selfEvaluationSelfConfidencePercentage<=30&&
                  <div>
                    <h2 className='font-semibold'>Your Self Score interpretation</h2>
                <p className='mb-4'>Your approach to perceiving and relying on your own abilities is more cautious than that of other people and is usually more marked by self-doubt. In situations that require assertiveness, you may feel hesitant or indecisive, which results in reluctance to take on new challenges. Your contribution in social or professional environments may be affected because you may hold back from expressing your opinions, concerned about how they will be received. Although this may limit your ability to demonstrate your true potential, it can also make you a more cautious and measured person.
                </p>
                  </div>
                }

                {
                  data.friendEvaluationSelfConfidenceTrait==='Self Deprecating'&&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation (Self-deprecating)</h2>
                <p className='mb-4'>Come on, do not be overly critical of your abilities and underestimate your potential. Your friends see your strengths and talents more clearly than you do. Trust their assessments and believe in your own capabilities. See this as a reminder that you are more capable and resilient than you might give yourself credit for. Trust in your abilities, and let your self-confidence guide you to even greater achievements. Stepping outside your comfort zone and taking calculated risks can unlock new opportunities and achievements. Self-confidence is not arrogance, its believing in yourself and your ability to grow.
                </p>
                  </div>
                }

                {
                  data.friendEvaluationSelfConfidenceTrait==='Humble'&&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation (Humble)</h2>
                <p className='mb-4'>Your self-awareness shines a little more than you think. Your friends see a confident individual, someone who navigates challenges with resilience. Although you might struggle with self-doubt, questioning your abilities and fearing failure, those around you recognize your potential and willingness to learn and grow. Humility is acknowledging your journey while believing in your ability to reach your goals. Let your quiet confidence shine through with a healthy dose of trust in your own capabilities.
                </p>
                  </div>
                }

                {
                  data.friendEvaluationSelfConfidenceTrait==='Self-Aware'&&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation (Self-aware)</h2>
                <p className='mb-4'>You maintain a healthy balance between self-confidence and self-awareness. You know your strengths and you might falter, accepting both with honesty without indulging in excessive pride or self-deprecation. This self-assurance resonates with your friends, who see a confident journey unfolding and trust you to embrace both the sunlit peaks and the winding valleys along the way.
                </p>
                  </div>
                }

                {
                  data.friendEvaluationSelfConfidenceTrait === 'Little Proud' &&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation</h2>
                <p className='mb-4'>You hold strong self-belief and trust your abilities. You are comfortable taking risks and embracing challenges. While your friends admire your confidence, they might occasionally perceive you as someone who does not have a good image of themselves. Maybe you just have not had the opportunity to show it, or maybe you could take better care of yourself, either way, something is not in tune regarding the opinion you have of yourself and the way you present yourself to others.
                </p>
                  </div>
                }

                {
                  data.friendEvaluationSelfConfidenceTrait === 'Needs Attention' &&
                  <div>
                    <h2 className='font-semibold'>Your Friends Score interpretation</h2>
                <p className='mb-4'>Firmly believing in your abilities is something natural to you, but it seems that you are not projecting the right image to others. Those around you might glimpse a different truth, sensing tremor and a subtle shadow of self-doubt. This uncertainty can create distance in your relationships. Whether this is true or not, there is something in your vibes that does not reflect your inner world, confidence is about believing in yourself and offering the world your unique gifts and paradoxically, the less you worry about it, the more it shows!
                </p>
                  </div>
                }

                </div>
                :
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima incidunt dignissimos doloremque nulla totam dolor sit exercitationem aspernatur! Unde, non nostrum. Officia minima accusamus, sint ducimus nesciunt esse rem ea distinctio, exercitationem placeat excepturi, dicta vitae deserunt nam ex libero.</p>
                }
                </div>}
              </AccordionBody>
            </Accordion>
          </div>
        </div>

        </div>
      </div>
    </div>
      )}</>
      );
};

export default Insights;
