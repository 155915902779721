import React from 'react'
import './App.css';
import Welcome from './pages/Welcome';
import Dashboard from './pages/Dashboard';
import EmailSent from './pages/EmailSent';
import { Routes, Route } from 'react-router-dom';
import CompleteProfile from './pages/CompleteProfile';
import SharedQuestionnaire from './pages/SharedQuestionnaire';
import Auth from './pages/Auth';
import Error404 from './pages/Error404';
import Error403 from './pages/Error403';
import LoginProtected from './LoginProtected';
import Questionnaire from './pages/Questionnaire';
import DashboardContent from './pages/DashboardContent';
import MyQuestionnaire from './pages/MyQuestionnaire';
import MyFriendsQuestionnaire from './pages/MyFriendsQuestionnaire';
import EvaluationDone from './pages/EvaluationDone';
import Subscription from './pages/Subscription';
import Payment from './pages/Payment';
import Profile from './pages/Profile';
import EditProfile from './pages/EditProfile';
import DiamondSubscribed from './pages/DiamondSubscribed';
import Insights from './pages/Insights';
import Revenue from './admin-pages/Revenue';
import Users from './admin-pages/Users';
import AdminDashboardContent from './admin-pages/AdminDashboardContent'
import { useSelector } from 'react-redux';
import PaymentSuccess from "./pages/PaymentSuccess";
import ReEvaluate from './pages/ReEvaluate';
// import EvaluationDone from "./pages/EvaluationDone";


function App () {
  const user = useSelector((state)=>state.user)
  
  const isAdmin = user?.role === 'admin'


  return (
    <div className='bg-gray-100'>
      <Routes>
{/* ///////////////////////// Private Routes //////////////////////////// */}
        <Route index element={<Welcome />} />
        <Route exact path='/' element={<Welcome />} />
        <Route exact path='/auth' element={<Auth />} />
        <Route exact path='/welcome' element={<Welcome />} />
        <Route exact path='/questionnaire/:questionnaireId' element={<SharedQuestionnaire />} />
        <Route exact path='/payment-success' element={<PaymentSuccess />} />
        <Route exact path="/auth" element={<Auth />} />
        <Route exact path='/email-sent' element={<EmailSent />} />
        <Route exact path='/complete-profile' element={<CompleteProfile />} />
        <Route exact path='/user/questionnaire/:questionnaireId' element={<SharedQuestionnaire />} />

        {/* /////////////////////// Protected Routes /////////////////////////////// */}
        <Route path='/user' element={<LoginProtected element={<Dashboard/>}/>} >
          <Route path='dashboard' index element={<DashboardContent/>} />
          <Route path='quiz' element={<LoginProtected element={<Questionnaire/>} />}/>
          <Route path='quiz/my-quiz/:questionnaireId' element={<LoginProtected element={<MyQuestionnaire/>} />} />
          <Route path='quiz/evaluation-done/:questionnaireId' element={<LoginProtected element={<EvaluationDone/>} />} />
          <Route path='subscription' element={<LoginProtected element={<Subscription/>} />} />
          <Route path='payment' element={<LoginProtected element={<Payment/>} />} />
          <Route path='profile' element={<Profile />} />
          <Route path='profile/edit' element={<EditProfile/>} />
          <Route path='subscription/diamond' element={<LoginProtected element={<DiamondSubscribed/>} />} />
          <Route path='quiz/insights/:questionnaireId' element={<LoginProtected element={<Insights/>} />} />
          <Route path='quiz/re-evaluate/:questionnaireId' element={<LoginProtected element={<ReEvaluate/>} />} />
          <Route path='quiz/friends-quiz/:questionnaireId' element={<LoginProtected element={<MyFriendsQuestionnaire/>} />} />
        </Route>
        <Route path='/admin' element={<LoginProtected element={<Dashboard/>}/>} >
          <Route path='dashboard' index element={<AdminDashboardContent />} />
          <Route path='profile' element={<Profile />} />
          <Route path='profile/edit' element={<EditProfile/>} />
          <Route path='revenue' element={<LoginProtected element={<Revenue/>}/>} />
          <Route path='users' element={<LoginProtected element={<Users/>}/>} />
        </Route>
        <Route path='*' element={<Error404 />} />
        <Route path='/error403' element={<Error403 />} />
      </Routes>
    </div>
  )
}

export default App
