import React, {useEffect, useState, useMemo} from 'react';
import RevenueCards from './RevenueCards';
import { IoSearch } from 'react-icons/io5';
import Pagination from '../components/Pagination';
import {useSelector} from "react-redux";
import {authUser} from "../redux/slices/UserSlice";
import {useNavigate} from "react-router-dom";
import Loader from "../components/Loader";
import { format, parse  } from 'date-fns';
import { useDispatch } from 'react-redux';
import { setUser } from '../redux/slices/UserSlice';
import { setEmail } from '../redux/slices/LoginPageSlice';
import { setError } from '../redux/slices/LoginErrorSlice';
import { BiSolidDownArrow } from "react-icons/bi";
import { formatAmount } from '../utilityFunctions/Utils';


const Revenue = () => {

  const dispatch = useDispatch()
  const user = useSelector(authUser);
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1)
  const [data, setData] = useState()
  const [cardsData, setCardsData] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  useEffect(()=> {
    const fetchUsers = async () => {
      setIsLoading(true)
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/revenue?pageNum=${currentPage}${searchValue !== '' ? '&search='+searchValue : ''}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        });

        if (response.status === 401 || response.status===400) {
          localStorage.clear();
          dispatch(setError('Authentication failed. Please login again.'));
          dispatch(setUser(null));
          dispatch(setEmail(""));
          navigate("/");
      }

        if (response.ok) {
          const result = await response.json();
          setData(result.data);
          // return result.data
          const cardsData = [
            {
              id: 1,
              value: result.data?.allRevenue,
              text: 'Total Revenue',
              secondValue: result.data?.currentMonthRevenueInPercentage,
              secondText: 'this month'
            },
            {
              id: 2,
              value: result.data.currentMonthRevenue,
              text: 'This Month',
              secondValue: result.data.currentMonthRevenueInPercentage,
              secondText: `${(new Date()).getDate()} Days`
            },
            {
              id: 3,
              value: result.data.totalGoldSubscriptionPayment,
              text: 'Gold Subscriptions',
              secondValue: result.data.currentMonthGoldSubscriptionInPercentage,
              secondText: 'This month'
            },
            {
              id: 4,
              value: result.data.totalDiamondSubscriptionPayment,
              text: 'Diamond Subscriptions',
              secondValue: result.data.currentMonthDiamondSubscriptionInPercentage,
              secondText: 'This Month'
            },
          ];
          setCardsData(cardsData)
          setIsLoading(false)
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchUsers()


  },[navigate,currentPage])

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch(); // Call your search function or perform any action here
    }
  };

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearch = async () => {
    setIsLoading(true)
    setCurrentPage(1)
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/revenue?pageNum=${currentPage}&search=${searchValue}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });

      if (response.status === 401 || response.status===400) {
        localStorage.clear();
        dispatch(setError('Authentication failed. Please login again.'));
        dispatch(setUser(null));
        dispatch(setEmail(""));
        navigate("/");
    }

      if (response.ok) {
        const result = await response.json();
        setData(result.data);
        // return result.data
        const cardsData = [
          {
            id: 1,
            value: result.data?.allRevenue,
            text: 'Total Revenue',
            secondValue: result.data?.currentMonthRevenueInPercentage,
            secondText: 'this month'
          },
          {
            id: 2,
            value: result.data.currentMonthRevenue,
            text: 'This Month',
            secondValue: result.data.currentMonthRevenueInPercentage,
            secondText: `${(new Date()).getDate()} Days`
          },
          {
            id: 3,
            value: result.data.totalGoldSubscriptionPayment,
            text: 'Gold Subscriptions',
            secondValue: result.data.currentMonthGoldSubscriptionInPercentage,
            secondText: 'This month'
          },
          {
            id: 4,
            value: result.data.totalDiamondSubscriptionPayment,
            text: 'Diamond Subscriptions',
            secondValue: result.data.currentMonthDiamondSubscriptionInPercentage,
            secondText: 'This Month'
          },
        ];
        setCardsData(cardsData)
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error);
    }
  };


    ////////////////////////////////////////// Sorting Logic ///////////////////////////////////////////////
    const useSortableData = (items, config = null) => {
      const [sortConfig, setSortConfig] = useState(config);
    
      const sortedItems = useMemo(() => {
        let sortableItems = items;
        if (sortConfig !== null) {
          sortableItems.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
              return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
              return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
          });
        }
        return sortableItems;
      }, [items, sortConfig]);
    
      const requestSort = (key) => {
        let direction = 'ascending';
        if (
          sortConfig &&
          sortConfig.key === key &&
          sortConfig.direction === 'ascending'
        ) {
          direction = 'descending';
        }
        setSortConfig({ key, direction });
      };
    
      return { items: sortedItems, requestSort, sortConfig };
    };
  
  
    const { items, requestSort, sortConfig } = useSortableData(data?.payments);
  
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
  
    const [userArrow, setUserArrow] = useState(false)
    const [stDateArrow, setStDateArrow] = useState(false)
    const [enDateArrow, setEnDateArrow] = useState(false)
    const [amountArrow, setAmountArrow] = useState(false)

  return (
      <>
        {isLoading ? (
            <Loader />
        ) : (
    <div className='w-[100vw] md:w-[calc(97.8vw-223px)] lg:w-[calc(97.8vw-217px)] 2xl:w-[calc(98.7vw-220px)] h-[calc(100vh-3.6rem)] md:h-[calc(100vh-4rem)] flex md:ml-60 bg-gray-100 overflow-y-scroll'>
    <div className='w-full h-[calc(100vh-4rem)] px-4 md:px-10 py-4 flex flex-col gap-7'>
      <div className='flex flex-col gap-6'>


        {/* /////////////////////////// Top part with cards //////////////////////////////// */}
        <p className='font-semibold'>Overview</p>

        {/* /////////////////////////////////////////////////////////////////////////////////// */}
        <div className='cards-container w-full grid grid-cols-2 lg:grid-cols-4 gap-3 md:gap-5'>
           {cardsData.map(({ id, value, text, secondText, secondValue }) => {
            return <RevenueCards key={id} value={value} text={text} secondaryText={secondText} secondaryValue={secondValue} id={id} />;
          })}
        </div>

        {/* //////////////////////////////// Bottom Part /////////////////////////////////////////// */}
        <div className='flex flex-col gap-3 mt-4 pb-4'>
          <h3 className='font-bold mb-2'>Payments</h3>

          {/* <div className='Search  flex items-center justify-start'>
            <div className='relative shadow-md rounded-xl text-gray-600'>
              <input
                className=' h-11 px-5 pr-8 w-40 sm:w-64 rounded-xl text-sm focus:outline-none'
                type='search'
                name='search'
                placeholder='Search'
                value={searchValue}
                onKeyDown={handleKeyPress}
                onChange={handleInputChange}
              />
              <button
                type='submit'
                className='absolute right-0 top-[25%] mr-4'
              >
                <IoSearch size={22} />
              </button>
            </div>
          </div> */}


          {/* ////////////////////////////////// Table /////////////////////////////////////// */}
          <div className='overflow-x-auto relative shadow-md sm:rounded-lg'>
            <div className='relative shadow-md sm:rounded-lg'>
              <table className='text-[10px] md:text-sm 2xl:text-base w-full overflow-x-scroll no-scrollbar text-left text-black bg-white'>
                <thead className='text-xs md:text-sm 2xl:text-lg text-gray-400  capitalize bg-[#CEF0E6]'>
                  <tr>
                    <th scope='col' className='py-3 px-6'>
                      ID
                    </th>
                    <th scope='col' className='py-3 px-6'>
                    <span className='mr-2'>Users</span>
                        <button onClick={()=>{
                          requestSort('userId')
                          setUserArrow(!userArrow)
                          }} className={`${getClassNamesFor('userId')} transition-all duration-200 ${userArrow&&'rotate-180'} `}><BiSolidDownArrow size={12}/>
                        </button>
                    </th>
                    <th scope='col' className='py-3 px-6 flex'>
                    <span className='mr-2'>Start Date</span>
                        <button onClick={()=>{
                          requestSort('start_date')
                          setStDateArrow(!stDateArrow)
                          }} className={`${getClassNamesFor('start_date')} transition-all duration-200 ${stDateArrow&&'rotate-180'} `}><BiSolidDownArrow size={12}/>
                        </button>
                    </th> 
                    <th scope='col' className='py-3 px-6 '>
                    <span className='mr-2'>End Date</span>
                        <button onClick={()=>{
                          requestSort('end_date')
                          setEnDateArrow(!enDateArrow)
                          }} className={`${getClassNamesFor('end_date')} transition-all duration-200 ${enDateArrow&&'rotate-180'} `}><BiSolidDownArrow size={12}/>
                        </button>
                    </th>
                    <th scope='col' className='py-3 px-5'>
                    <span className='mr-2'>Amount</span>
                        <button onClick={()=>{
                          requestSort('amount')
                          setAmountArrow(!amountArrow)
                          }} className={`${getClassNamesFor('amount')} transition-all duration-200 ${amountArrow&&'rotate-180'} `}><BiSolidDownArrow size={12}/>
                        </button>
                    </th>
                    <th scope='col' className='py-3 px-6'>
                      Subscription
                    </th>
                  </tr>
                </thead>
                <tbody>
                {
                  data.payments.length !== 0 ? (
                      data.payments.map((item, index) => {
                        const displayIndex = (currentPage - 1) * 10 + index + 1;
                        const parsedStartDate = parse(item.start_date, 'MM/dd/yyyy', new Date());
                        const formattedStartDate = format(parsedStartDate, 'MM-dd-yyyy');
                        const parsedEndDate = parse(item.start_date, 'MM/dd/yyyy', new Date());
                        const formattedEndDate = format(parsedEndDate, 'MM-dd-yyyy');

                        return (
                            <tr
                                key={displayIndex}
                                className={`${
                                    displayIndex % 2 === 0 ? 'bg-gray-100' : 'bg-white'
                                }`}
                            >
                              <td className='py-3 px-6'>{displayIndex}</td>
                              <td className='py-3 pr-8 text-left'>
                                <div className='profile-container flex gap-2 items-center'>
                                  <img
                                      className='w-11 h-11 rounded-full m-[0.125rem]'
                                      src={item.user.image}
                                      alt=''
                                  />
                                  <div className='div'>
                                    <p className='capitalize'>{`${item.user.first_name} ${item.user.last_name}`}</p>
                                  </div>
                                </div>
                              </td>
                              <td className='py-3 px-6'>{formattedStartDate}</td>
                              <td className='py-3 px-6'>{item.subscription === 'Diamond'?'-':formattedEndDate}</td>
                              <td className='py-3 px-6'>{`$${formatAmount(item.amount, 1)}`}</td>
                              <td className='py-3 px-6'>
                                <p
                                    className={`p-2 capitalize ${
                                        item.subscription === 'Gold' && 'bg-[#FBC223]'
                                    } ${
                                        item.subscription === 'Diamond' && 'bg-[#26c7f2]'
                                    } text-white rounded-md px-7 w-28 text-center`}
                                >
                                  {item.subscription}
                                </p>
                              </td>
                            </tr>
                        );
                      })
                  ) : (
                      <tr className='bg-white'>
                        <td className='text-center text-lg py-3' colSpan={6}>
                          No Payments Found
                        </td>
                      </tr>
                  )
                }

                </tbody>
              </table>
            </div>
          </div>
          <div className='pagination flex items-center gap-3 mt-4'>
                          <p className='text-sm'>Showing {currentPage}/{data?.totalPages}</p>
                          <Pagination
                              className='pagination-bar'
                              currentPage={currentPage}
                              totalCount={data?.totalPayments}
                              pageSize={10}
                              onPageChange={(page) => setCurrentPage(page)}
                          />
                      </div>
        </div>


{/* ///////////////////////////////////// Pagination //////////////////////////////////////////// */}
                      


        {/* //////////////////////////////////////////////////////////////////////////////////// */}
      </div>
    </div>
  </div>
        )}
      </>
  )
}

export default Revenue
