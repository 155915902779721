import { createSlice } from '@reduxjs/toolkit';

export const LoginPageSlice = createSlice({
    name: 'email',
    initialState: '',
    reducers: {
        setEmail: (state, action) => {
            return action.payload;
        }
    },
});

export const { setEmail } = LoginPageSlice.actions;

export const selectEmail = (state) => state.email;
export default LoginPageSlice.reducer;
