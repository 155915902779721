import React, { useEffect, useState } from "react";
import { ReactComponent as Question } from "../images/question-color.svg";
import { ReactComponent as UsresIcon } from "../images/users_ic_light.svg";
import { ReactComponent as EarningsIcon } from "../images/earnings_ic_light.svg";
// import { ReactComponent as WorldMap } from '../images/world.svg';
import { ReactComponent as GreenArrow } from "../images/greenArrowUp.svg";
import { ReactComponent as RedArrow } from "../images/redArrowDown.svg";
import ProgressBars from "./ProgressBars";
import Chart from "react-apexcharts";
// import { ReactComponent as Response } from '../images/response-color.svg';
import Worldmap from "./WorldMap";
import { setError } from "../redux/slices/LoginErrorSlice";
import { authUser, setUser } from "../redux/slices/UserSlice";
import { setEmail } from "../redux/slices/LoginPageSlice";
import { setQuestionnaireId } from "../redux/slices/QuestionnaireIdSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import Select from 'react-select';
import { renderToString } from 'react-dom/server';
import { formatAmount } from "../utilityFunctions/Utils";

const AdminDashboardContent = () => {
  

  const user = useSelector(authUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dashboardData, setDashboardData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [visitsLoading, setVisitsLoading] = useState(false);
  const [interactivityLoading, setInteractivityLoading] = useState(false);
  const [interactivityYear, setInteractivityYear] = useState({ value: '', label: 'This year' });
  const [respondentsYear, setRespondentsYear] = useState({ value: '', label: 'This year' });
  const [locationYear, setLocationYear] = useState({ value: '', label: 'This year' });


  const yearOptions = [
    { value: '', label: 'This year' },
    { value: new Date().getFullYear() - 1, label: 'Last 1 year' },
    { value: new Date().getFullYear() - 2, label: 'Last 2 year' },
    { value: new Date().getFullYear() - 3, label: 'Last 3 year' }
  ]

  const bColor = 'var(--primary-color)';

  // ///////////////////////// Year dropdown handlers ///////////////////////////////
  const interactivityYearChange = event => {
    setInteractivityYear(event.target.value);
  };

  const respondentsYearChange = event => {
    setRespondentsYear(event.target.value);
  };

  const locationYearChange = event => {
    setLocationYear(event.target.value);
  };

///////////////////////////////////// users data ////////////////////////////////////
const [usersDetailsData, setUsersDetailsData] = useState(null);

///////////////////////////////////// users Visits Data ////////////////////////////////////
const [usersVisitsData, setUsersVisitsData] = useState(null);

///////////////////////////////////// users Interactivity data ////////////////////////////////////
const [usersInteractivityData, setUsersInteractivityData] = useState(null);


// ///////////////////////////////// User details useEffect ////////////////////////////////
  useEffect(()=>{
    const fetchUsersDetailsData = async () =>{
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/dashboard/user-data`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user?.token}`,
              "Content-Type": "application/json",
            },
          }
        )
  
        if (response.status === 401 || response.status===400) {
          localStorage.clear();
          dispatch(setError("Authentication failed. Please login again."));
          dispatch(setUser(null));
          dispatch(setEmail(""));
          dispatch(setQuestionnaireId(""));
          navigate("/");
        }

        if (response.ok) {
          const result = await response.json();
          setUsersDetailsData(result?.data);
          setIsLoading(false)
        } else {
          navigate("/");
        }
      } catch (error) {
        console.log(error);
      }
    }
  
    fetchUsersDetailsData();
  }, [])


  // ///////////////////////////////// User visits useEffect ////////////////////////////////
  useEffect(()=>{
    setIsLoading(true)
    const fetchUsersVisitsData = async () =>{
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/dashboard/user-visits?${locationYear.value !== '' ? '&year='+locationYear.value : ''}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user?.token}`,
              "Content-Type": "application/json",
            },
          }
        )
  
        if (response.status === 401 || response.status===400) {
          localStorage.clear();
          dispatch(setError("Authentication failed. Please login again."));
          dispatch(setUser(null));
          dispatch(setEmail(""));
          dispatch(setQuestionnaireId(""));
          navigate("/");
        }

        if (response.ok) {
          const result = await response.json();
          setUsersVisitsData(result?.data);
          setIsLoading(false)
        } else {
          navigate("/");
        }
      } catch (error) {
        console.log(error);
      }
    }
  
    fetchUsersVisitsData();
  }, [locationYear])


  // ///////////////////////////////// User interactivity useEffect ////////////////////////////////
  useEffect(()=>{
    setIsLoading(true)
    const fetchInteractivityData = async () =>{
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/dashboard/user-interactivity?${interactivityYear.value !== '' ? '&year='+interactivityYear.value : ''}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user?.token}`,
              "Content-Type": "application/json",
            },
          }
        )
  
        if (response.status === 401 || response.status===400) {
          localStorage.clear();
          dispatch(setError("Authentication failed. Please login again."));
          dispatch(setUser(null));
          dispatch(setEmail(""));
          dispatch(setQuestionnaireId(""));
          navigate("/");
        }

        if (response.ok) {
          const result = await response.json();
          setUsersInteractivityData(result?.data);
          setIsLoading(false)
        } else {
          navigate("/");
        }
      } catch (error) {
        console.log(error);
      }
    }
  
    fetchInteractivityData();
  }, [interactivityYear])
  
  //////////////////////////////// Subscription Type Chart ////////////////////////////////////
  const series = [
    usersDetailsData?.freeUserCount,
    usersDetailsData?.goldUserCount,
    usersDetailsData?.diamondUserCount,
  ];
  const userOptions = [
    usersDetailsData?.freeUsersCountInPercentage,
    usersDetailsData?.goldUsersCountInPercentage,
    usersDetailsData?.diamondUsersInPercentage,
  ];

  const options = {
    chart: {
      type: "donut",
    },

    colors: ["#C1C9C7", "#FBC223", "#0EBFEE"],

    fill: {
      colors: ["#C1C9C7", "#FBC223", "#0EBFEE"],
    },

    plotOptions: {
      pie: {
        donut: {
          size: "40%",
        },
      },
    },
    legend: {
      position: "bottom",
      offsetX: -23,

      formatter: function (seriesName, opts) {
        return `<div className='legend-row'><span class='legend-marker' style='background:${
          opts.w.globals.colors[opts.seriesIndex]
        }'></span><span class='legend-text'>${seriesName}</span><br/><span class='legend-subtext'>${
          userOptions[opts.seriesIndex]
        }%</span></div>`;
      },

      itemPadding: {
        horizontal: 35,
        vertical: 0,
      },
      markers: {
        width: 10,
        height: 10,
        fillColors: ["#C1C9C7", "#FBC223", "#0EBFEE"],
        radius: 3,
        // customHTML: function() {
        //   return `<span class="custom-marker pl-16 text-gray-400 text-xs">(23K)</span>`
        // }
      },
    },
    labels: ["Free", "Gold", "Diamond"],

    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      // fillSeriesColor: true
    }
  };

  //////////////////////////////// Device Type Chart ////////////////////////////////////

  const series2 = [
    usersVisitsData?.totalMobileVisits,
    usersVisitsData?.totalDesktopVisits,
  ];
  const deviceOptions = [
    usersVisitsData?.mobileViewsInPercentage,
    usersVisitsData?.desktopViewsInPercentage,
  ];
  const options2 = {
    chart: {
      type: "donut",
    },
    
    colors: ["#7EB1FC", "#53D1CE"],
    fill: {
      colors: ["#7EB1FC", "#53D1CE"],
    },

    plotOptions: {
      pie: {
        donut: {
          size: "85%",
          labels: {
            show: true,
            fontSize: 20,

            total: {
              label: ["Total Visitors"],
              fontSize: 12,
              color: 'var(--primary-color)',
              show: true,
            },
          },
        },
      },
    },
    legend: {
      position: "bottom",
      offsetX: -23,

      formatter: function (seriesName, opts) {
        return `<div className='legend-row flex flex-col'><span class='legend-marker' style='background:${
          opts.w.globals.colors[opts.seriesIndex]
        }'></span><span class='legend-text'>${seriesName}</span><br/><span class='legend-subtext'>${
          deviceOptions[opts.seriesIndex]
        }%</span></div>`;
      },

      itemPadding: {
        horizontal: 25,
        vertical: 0,
      },
      markers: {
        width: 10,
        height: 10,
        fillColors: ["#7EB1FC", "#53D1CE"],
        radius: 3,
      },
    },
    labels: ["Mobile", "Desktop"],

    dataLabels: {
      enabled: false,
    },
    
  };

  // ///////////////////////// User Interactivity Graph Data ////////////////////////////////////////
  const graphData = {
    series: [
      {
        name: "Total Responses",
        data: usersInteractivityData?.questionnaireResponseByMonth,
      },
      {
        name: "Total Link Views",
        data: usersInteractivityData?.questionnaireViewsByMonth,
      },

    ],
    options: {

      legend: {
        position: "bottom",
        // formatter: function (seriesName, opts) {
        //   return `<div className='legend-row flex flex-col'><span class='legend-marker' style='background:${
        //     opts.w.globals.colors[opts.seriesIndex]
        //   }'></span><span className='legend-text'>${seriesName}</span><br/><span className='legend-subtext'>${
        //     // deviceOptions[opts.seriesIndex]
        //       usersInteractivityData.totalQuestionnaireViewsInPercentage
        //   }%</span></div>`;
        // },

        horizontalAlign: 'start',

        itemMargin: {
          vertical: 20,
      },
      fontSize: '13px',
        markers: {
          width: 10,
          height: 10,
          fillColors: ["var(--primary-color)", "#E9523A"],
          radius: 3,
          offsetX: -2,
          // customHTML: function() {
          //   return `<span class="custom-marker pl-16 text-gray-400 text-xs">${`(${usersInteractivityData.totalQuestionnaireResponse})`}</span>`
          // }
        },
      },

      chart: {
        height: 350,
        type: "area",
        toolbar: { show: false },
        zoom: {
          enabled: false,
        },
      },
      colors: ["var(--primary-color)", "#E9523A"],
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        shared: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },

      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0,
          opacityFrom: 0.4,
          opacityTo: 0.1
        }
      },

      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },

      yaxis: {
        labels: {
          formatter: (val) => `${val}`,
          style: { colors: ["#AAA492"] },
        },
      },
    },
  };

  // ///////////////////////////////// Respondent frequency Graph Data ///////////////////////////////////////////

  const graphData2 = {
    series: [
      {
        name: "Total Responses",
        data: usersInteractivityData?.questionnaireResponseByMonth,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
        toolbar: { show: false },
        zoom: {
          enabled: false,
        },
      },
      colors: ["var(--primary-color)"],
      dataLabels: {
        enabled: false,
      },

      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0,
          opacityFrom: 0.3,
          opacityTo: 0.1
        }
      },

      tooltip: {
        enabled: true,
        shared: false,
        style: {
          fontSize: '12px',  
        },
      
        // custom: function({series, seriesIndex, dataPointIndex, w}) {
        //   return renderToString(<div className="bg-gray-500 px-4 rounded-lg text-lg text-white">{series[seriesIndex][dataPointIndex]}</div>)  
        // }
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },

      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },

      yaxis: {
        labels: {
          formatter: (val) => {
            return `${val}`;
          },
          style: { colors: ["#AAA492"] },
        },
      },
    },
  };

  return (
    <div>
      {isLoading&& <Loader />}
    
      <div className="w-[100vw] md:w-[calc(97.8vw-223px)] lg:w-[calc(97.8vw-217px)] 2xl:w-[calc(98.7vw-220px)] h-[calc(100vh-3.6rem)] md:h-[calc(100vh-4rem)] flex md:ml-60 bg-[#F9F9F9]">
          <div className="w-full h-[calc(100vh-4rem)] px-4 md:px-10 py-4 p-4 flex flex-col gap-6 overflow-y-scroll">
            <h3>Overview</h3>
            <div className="main-container grid grid-cols-3 gap-3 md:gap-5">


              
              {usersDetailsData&& 
              <div className="box1 grid  grid-cols-2 lg:grid-cols-3 gap-3 lg:gap-5 col-span-3">
                <div className="inner1 rounded-lg ">
                  <div className="grid grid-cols-[auto_1fr] gap-x-2 sm:gap-x-4 grid-rows-2 pb-0 p-5 2xl:p-6 h-full rounded-lg bg-white">
                    <div>
                      <div className="icon1 bg-[#7EB1FC] w-fit h-fit p-1 md:p-2 2xl:p-3 rounded-full">
                        <Question />
                      </div>
                    </div>
                    <div className=" pl-0 flex flex-col gap-1 text-sm">
                      <h1 className="text-lg md:text-xl font-bold 2xl:text-2xl">
                        {formatAmount(usersDetailsData?.questionnairesCount, 1)}
                      </h1>
                      <p className="text-gray-400 text-xs md:text-sm 2xl:text-base break-all">
                        Total Questionnaire
                      </p>
                    </div>
                    {/* <div className=" col-span-2 lg:col-start-2 flex gap-2 items-center ">
                      
                        <GreenArrow />
                      <span className='text-[#22CC9B] font-semibold text-xs md:text-base'>{`+3.5%`}</span>
                      <span className='text-gray-400 text-xs md:text-sm'>
                      (This month)
                      </span>
                    </div> */}
                  </div>
                </div>

                <div className="inner2 rounded-lg ">
                  <div className="grid grid-cols-[auto_1fr] gap-x-2 sm:gap-x-4 grid-rows-2 pb-0 p-5 2xl:p-6 h-full rounded-lg bg-white">
                    <div>
                      <div className="icon1 bg-[#E091C9] w-fit h-fit p-1 md:p-2 2xl:p-3 rounded-full">
                        <UsresIcon />
                      </div>
                    </div>
                    <div className=" pl-0 flex flex-col gap-1 text-sm">
                      <h1 className="text-lg md:text-xl font-bold 2xl:text-2xl">
                        {formatAmount(usersDetailsData?.totalUsersCount, 1)}
                      </h1>
                      <p className="text-gray-400 text-xs md:text-sm 2xl:text-base break-all">
                        Total Users
                      </p>
                    </div>
                    <div className=" col-span-2 lg:col-start-2 flex gap-2 items-center ">
                      {usersDetailsData?.userDifferenceInPercentage > 0 ? (
                        <GreenArrow />
                      ) : (
                        <RedArrow />
                      )}
                      <span
                        className={
                          usersDetailsData?.userDifferenceInPercentage > 0
                            ? "text-[var(--primary-color)] font-semibold text-xs md:text-base"
                            : "text-[#E9523A] font-semibold text-xs md:text-base"
                        }
                      >
                        {usersDetailsData?.userDifferenceInPercentage}%
                      </span>
                      <span className="text-gray-400 text-xs md:text-sm">
                        (This month)
                      </span>
                    </div>
                  </div>
                </div>

                <div className="inner3 bg-green-300 rounded-lg col-span-2 lg:col-span-1">
                  <div className="grid grid-cols-[auto_1fr] gap-x-2 sm:gap-x-4 grid-rows-2 pb-0 p-5 2xl:p-6 h-full rounded-lg bg-white">
                    <div>
                      <div className="icon1 bg-[var(--primary-color)] w-fit h-fit p-1 md:p-2 2xl:p-3 rounded-full">
                        <EarningsIcon />
                      </div>
                    </div>
                    <div className=" pl-0 flex flex-col gap-1 text-sm">
                      <h1 className="text-lg md:text-xl font-bold 2xl:text-2xl">
                        ${formatAmount(usersDetailsData?.allRevenue, 1)}
                      </h1>
                      <p className="text-gray-400 text-xs md:text-sm 2xl:text-base break-all">
                        Total Earnings
                      </p>
                    </div>
                    <div className=" col-span-2 lg:col-start-2 flex gap-2 items-center ">
                      {usersDetailsData?.revenueDifferenceInPercentage > 0 ? (
                        <GreenArrow />
                      ) : (
                        <RedArrow />
                      )}
                      <span
                        className={
                          usersDetailsData?.revenueDifferenceInPercentage > 0
                            ? "text-[var(--primary-color)] font-semibold text-xs md:text-base"
                            : "text-[#E9523A] font-semibold text-xs md:text-base"
                        }
                      >{`${usersDetailsData?.revenueDifferenceInPercentage}%`}</span>
                      <span className="text-gray-400 text-xs md:text-sm">
                        (This month)
                      </span>
                    </div>
                  </div>
                </div>
              </div>}

              {/* //////////////////////////////// Main Charts ////////////////////////////////////////////// */}
              {
                usersVisitsData&&
                <div className="box2 flex flex-col gap-3 lg:gap-5 rounded-lg  col-span-3 lg:col-span-2">
                {isLoading?<Loader/>:
                <div className="chart1 bg-white rounded-lg p-6 pb-0">

                  <div className="graph-header flex flex-col gap-2 md:flex-row md:justify-between md:items-center">
                    <p className="font-semibold 2xl:text-lg">
                      User Interactivity
                    </p>
                      
                      <div className=' w-full md:w-60'>
              <Select
                styles={{
                  control: (base, state) => ({
                    ...base,
                    boxShadow: state.isFocused ? 0 : 0,
                    border: '1px solid #E5E7EB',
                    borderRadius: '8px',
                    padding: '5.5px',
                    borderColor: state.isFocused
                      ? 'brandColor'
                      : base.borderColor,
                    '&:hover': {
                      borderColor: state.isFocused ? bColor : base.borderColor,
                    },
                  }),

                  placeholder: (baseStyles) => ({
                    ...baseStyles,
                    color: '#9CA3AF',
                  }),
                  option: (base, { isFocused }) => {
                    return {
                      ...base,
                      padding: '2',
                      backgroundColor: isFocused ? '#1967D2' : 'white',
                      color: isFocused ? 'white' : 'black',
                    };
                  },
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    padding: '6px',
                  }),
                }}
                name='year'
                className='rounded-lg text-sm 2xl:text-base focus-border-primary'
                // defaultValue={genderOptions[0]}
                value={interactivityYear}
                onChange={(selectedOption) => setInteractivityYear(selectedOption)}
                options={yearOptions}
              />
            </div>
                    
                  </div>
                  <div>
                    
                  <Chart
                    options={graphData.options}
                    series={graphData.series}
                    height={250}
                    type="area"
                  />
                  </div>
                </div>}

                {/* /////////////////////////////////////////////////////////////////////////////////// */}
                {usersInteractivityData&&
                <div className="chart2 bg-white rounded-lg p-6">
                  <div className="graph-header flex flex-col gap-2 md:flex-row md:justify-between md:items-center">
                    <p className="font-semibold 2xl:text-lg">
                      Respondents Frequency
                    </p>
                    <div className="country-select flex flex-col gap-1 ">
                    <div className=' w-full md:w-60'>
              <Select
                styles={{
                  control: (base, state) => ({
                    ...base,
                    boxShadow: state.isFocused ? 0 : 0,
                    border: '1px solid #E5E7EB',
                    borderRadius: '8px',
                    padding: '5.5px',
                    borderColor: state.isFocused
                      ? 'brandColor'
                      : base.borderColor,
                    '&:hover': {
                      borderColor: state.isFocused ? bColor : base.borderColor,
                    },
                  }),

                  placeholder: (baseStyles) => ({
                    ...baseStyles,
                    color: '#9CA3AF',
                  }),
                  option: (base, { isFocused }) => {
                    return {
                      ...base,
                      padding: '2',
                      backgroundColor: isFocused ? '#1967D2' : 'white',
                      color: isFocused ? 'white' : 'black',
                    };
                  },
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    padding: '6px',
                  }),
                }}
                name='year'
                className='rounded-lg text-sm 2xl:text-base focus-border-primary'
                // defaultValue={genderOptions[0]}
                value={interactivityYear}
                onChange={(selectedOption) => setInteractivityYear(selectedOption)}
                options={yearOptions}
              />
            </div>
                    </div>
                  </div>
                  <Chart
                    options={graphData2.options}
                    series={graphData2.series}
                    height={250}
                    type="area"
                  />
                </div>}

                {/* ////////////////////////////////////////////////////////////////////////////////////////// */}
                <div className="mapchart flex rounded-lg bg-white p-6 flex-col gap-4">
                  <div className="graph-header flex flex-col gap-2 md:flex-row md:justify-between md:items-center">
                    <p className="font-semibold 2xl:text-lg">
                      Visits by Location
                    </p>
                    <div className="country-select flex flex-col gap-1 ">
                    <div className=' w-full md:w-60'>
              <Select
                styles={{
                  control: (base, state) => ({
                    ...base,
                    boxShadow: state.isFocused ? 0 : 0,
                    border: '1px solid #E5E7EB',
                    borderRadius: '8px',
                    padding: '5.5px',
                    borderColor: state.isFocused
                      ? 'brandColor'
                      : base.borderColor,
                    '&:hover': {
                      borderColor: state.isFocused ? bColor : base.borderColor,
                    },
                  }),

                  placeholder: (baseStyles) => ({
                    ...baseStyles,
                    color: '#9CA3AF',
                  }),
                  option: (base, { isFocused }) => {
                    return {
                      ...base,
                      padding: '2',
                      backgroundColor: isFocused ? '#1967D2' : 'white',
                      color: isFocused ? 'white' : 'black',
                    };
                  },
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    padding: '6px',
                  }),
                }}
                name='year'
                className='rounded-lg text-sm 2xl:text-base focus-border-primary'
                // defaultValue={genderOptions[0]}
                value={locationYear}
                onChange={(selectedOption) => setLocationYear(selectedOption)}
                options={yearOptions}
              />
            </div>
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row gap-2">
                    <div className=" w-full md:w-[65%]">
                      <Worldmap countriesData={usersVisitsData?.filteredCountriesVisitCount}/>
                    </div>

                    <div className="progresses flex flex-col gap-2  p-2 w-full md:w-[35%]">
                      {usersVisitsData.filteredContinentNameVisitCount.map(
                        ({ id, heading, number, progressValue }) => {
                          return (
                            <ProgressBars
                              key={id}
                              heading={heading}
                              progressValue={progressValue}
                              numbers={number}
                            />
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>
              }

              {/* /////////////////////////////////////// Right bar ///////////////////////////////////////////////////// */}
              <div className="box3 row-start-2 lg:row-start-auto col-span-3 lg:col-span-1 flex flex-col gap-3  lg:gap-5">
                {/* //////////////////////////// Subscription type ////////////////////////////////////////////  */}
                
                  {usersDetailsData&&<div className="rightin1 w-full rounded-lg bg-white p-5">
                  <h2 className="font-semibold 2xl:text-lg">
                    Subscription Type
                  </h2>
                  <Chart
                    className="pt-2"
                    options={options}
                    series={series}
                    height={240}
                    type="donut"
                  />
                </div>}
                

                {/* ///////////////////////////////////////// Device Type //////////////////////////////////////////// */}
                {usersVisitsData&&<div className=" rightin2 w-full bg-white rounded-lg p-5">
                  <h2 className="font-semibold 2xl:text-lg">Device Type</h2>
                  <Chart
                    className="pt-2"
                    options={options2}
                    series={series2}
                    height={240}
                    type="donut"
                  />
                </div>}

                {/* /////////////////////////////////////// Operating Systems ///////////////////////////////////////////////  */}
                {usersVisitsData&&<div className=" rightin3 flex flex-col gap-3 w-full bg-white rounded-lg p-5">
                  <p className="font-semibold 2xl:text-lg">Operating System</p>

                  <div className="progres flex flex-col gap-2">
                    <div className="flex justify-between items-center">
                      <h3 className="2xl:text-lg">Android</h3>
                      <div className="text-sm 2xl:text-lg">
                        <span>{usersVisitsData?.androidViewsInPercentage}%</span>
                        <span className="text-gray-400">
                          ({formatAmount(usersVisitsData?.totalAndroidVisits, 1)})
                        </span>
                      </div>
                    </div>
                    <div className="mb-1 h-[0.5rem] w-full bg-gray-300 dark:bg-neutral-600 rounded-lg">
                      <div
                        className="h-[0.5rem] btn-color-primary rounded-lg"
                        style={{
                          width: `${usersVisitsData?.androidViewsInPercentage}%`,
                        }}
                      ></div>
                    </div>
                  </div>

                  <div className="progres flex flex-col gap-2">
                    <div className="flex justify-between items-center">
                      <h3 className="2xl:text-lg">iOS</h3>
                      <div className="text-sm 2xl:text-lg">
                        <span>{usersVisitsData?.iOsViewsInPercentage}%</span>
                        <span className="text-gray-400">
                          ({formatAmount(usersVisitsData?.totalIOSVisits, 1)})
                        </span>
                      </div>
                    </div>
                    <div className="mb-1 h-[0.5rem] w-full bg-gray-300 dark:bg-neutral-600 rounded-lg">
                      <div
                        className="h-[0.5rem] bg-[#7EB1FC] rounded-lg"
                        style={{
                          width: `${usersVisitsData?.iOsViewsInPercentage}%`,
                        }}
                      ></div>
                    </div>
                  </div>

                  <div className="progres flex flex-col gap-2">
                    <div className="flex justify-between items-center">
                      <h3 className="2xl:text-lg">Windows</h3>
                      <div className="text-sm 2xl:text-lg">
                        <span>{usersVisitsData?.windowsViewsInPercentage}%</span>
                        <span className="text-gray-400">
                          ({formatAmount(usersVisitsData?.totalWindowsVisits, 1)})
                        </span>
                      </div>
                    </div>
                    <div className="mb-1 h-[0.5rem] w-full bg-gray-300 dark:bg-neutral-600 rounded-lg">
                      <div
                        className="h-[0.5rem] bg-[#F59080] rounded-lg"
                        style={{
                          width: `${usersVisitsData?.windowsViewsInPercentage}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>}

              </div>


            </div>
          </div>
        </div>
    
        
        
      
    </div>
  );
};

export default AdminDashboardContent;
