import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {authUser} from "../redux/slices/UserSlice";
import Loader from "../components/Loader";

const Payment = () => {
  const user = useSelector(authUser)
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchPaymentsData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/payment`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user?.token}`,
            "Content-Type": "application/json",
             
          },
        });

        if (response.ok) {
          const result = await response.json();
          setData(result?.data);
          setIsLoading(false);
        } else {
          navigate("/");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchPaymentsData();
  }, []);


  return (
      <>
        {isLoading ? (
            <Loader />
        ) : (
            <div className='w-[100vw] md:w-[calc(97.8vw-223px)] lg:w-[calc(97.8vw-217px)] 2xl:w-[calc(98.7vw-220px)] h-[calc(100vh-3.6rem)] md:h-[calc(100vh-4rem)] flex md:ml-60 bg-gray-100 no-scrollbar overflow-y-scroll '>
              <div className='w-full h-[calc(100vh-4rem)] px-4 md:px-10 py-4'>
                <div className='flex flex-col gap-6'>
                  <p className='font-semibold'>Payment History</p>
                  <div className='overflow-x-auto relative shadow-md sm:rounded-lg'>
                    <div className='relative shadow-md sm:rounded-lg'>
                      <table className='text-[10px] md:text-sm 2xl:text-base w-full overflow-x-scroll no-scrollbar text-left text-black bg-white'>
                        <thead className='text-xs md:text-sm 2xl:text-lg text-gray-400 capitalize bg-[#CEF0E6]'>
                        <tr>
                          <th scope='col' className='py-3 px-6'>
                            ID
                          </th>
                          <th scope='col' className='py-3 px-6'>
                            Start Date
                          </th>
                          <th scope='col' className='py-3 px-6'>
                            End Date
                          </th>
                          <th scope='col' className='py-3 px-6'>
                            Amount
                          </th>
                          <th scope='col' className='py-3 px-6'>
                            Subscription
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.length === 0 ? (
                            <tr className='bg-white'>
                              <td className='text-center text-lg py-3' colSpan={5}>
                                No Payments Found
                              </td>
                            </tr>
                        ) : (
                            data.map((item, index) => (
                                <tr
                                    key={index}
                                    className={`${
                                        index % 2 === 0 ? 'bg-gray-100' : 'bg-white'
                                    }`}
                                >
                                  <td className='py-3 px-6'>{index + 1}</td>
                                  <td className='py-3 px-6'>{item.start_date}</td>
                                  <td className='py-3 px-6'>{item.end_date}</td>
                                  <td className='py-3 px-6'>${item.amount}.00</td>
                                  <td className='py-3 px-6'>
                                    <p
                                        className={`p-2 bg-[#C1C9C7] ${
                                            item.subscription === 'Gold' && 'bg-[#FBC223]'
                                        } ${
                                            item.subscription === 'Diamond' && 'bg-blue-400'
                                        } text-black rounded-md px-7 w-28 text-center`}
                                    >
                                      {item.subscription}
                                    </p>
                                  </td>
                                </tr>
                            ))
                        )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        )}
      </>)
};

export default Payment;
