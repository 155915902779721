import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import PropTypes from "prop-types";

const WorldMap = ({ countriesData }) => {

  const [countries, setCountries] = useState([
    ['', '', ''],
  ])

  // const data = [
  //   ["Country", "Popularity"],
  //   ["Pakistan", 200],
  //   ["United States", 300],
  //   ["Poland", 400],
  // ];


useEffect(()=>{
    const data = countriesData.map(country =>[country.heading, parseFloat(country.progressValue), country.number])
    setCountries([...countries, ...data])
},[])

  const options = {
    colorAxis: {minValue: 0, maxValue : 100, colors: ['#53D1CE', '#53D1CE']},
    backgroundColor: "#FFFFFF",
    defaultColor: "var(--primary-color)",
    legend: "none",
  };

  return (
    <Chart
      // chartEvents={[
      //   {
      //     eventName: "select",
      //     callback: ({ chartWrapper }) => {
      //       const chart = chartWrapper.getChart();
      //       const selection = chart.getSelection();
      //       if (selection.length === 0) return;
      //       const region = countries[selection[0].row + 1];
      //       console.log("Selected : " + region);
      //     },
      //   },
      // ]}
      chartType="GeoChart"
      width="100%"
      height="340px"
      data={countries}
      options={options}
    />
  );
};

WorldMap.propTypes = {
    countriesData: PropTypes.array,
};

export default WorldMap;
