import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import './breadcrumbs.css'
import {useSelector} from "react-redux";
import {authUser} from "../redux/slices/UserSlice";
export default function Breadcrumbs () {
  const location = useLocation()
  const user = useSelector(authUser)
  let currentLink = ''

    function isValidObjectId(str) {
        // A valid ObjectId should be a 24-character hexadecimal string
        const objectIdRegex = /^[0-9a-fA-F]{24}$/;
        return objectIdRegex.test(str);
    }

    const urls = location.pathname.split('/').filter(crumb => crumb !== '');

    const lastElementOfUrl = urls[urls.length - 1];

    const crumbs = urls

    .map((crumb,index) => {
        if(crumb === 'user'){
            return true;
        }
        if(crumb === 'admin'){
            return true;
        }
        if(isValidObjectId(crumb)){
            return true;
        }

        if(index === urls.length - 2){
            if(isValidObjectId(lastElementOfUrl)){
                if(crumb === 'my-quiz' || crumb === 'friends-quiz' || crumb === 'evaluation-done' || crumb === 'insights' || crumb === 're-evaluate')  {
                    currentLink += `/${crumb}/${lastElementOfUrl}`
                }else{
                    currentLink += `/${crumb}`
                }
            }else{
                currentLink += `/${crumb}`
            }
        }else{
            currentLink += `/${crumb}`
        }

      return (
        <div className='crumb' key={crumb}>
          <Link to={`/${user.role}${currentLink}`}>{crumb}</Link>
        </div>
      )
    })

  return (
    <div className='breadcrumbs p-3 text-sm md:text-base lg:p-0'>
      {crumbs}
    </div>
  )
}