import React from 'react'
import { ReactComponent as StarDiamond } from '../images/starDiamond.svg'
import {useNavigate} from "react-router-dom";

const DiamondSubscribed = () => {
  const navigate = useNavigate()
  return (
    <div className='w-full h-[calc(100vh-4rem)] flex md:justify-end bg-gray-100'>
      <div className='w-full md:w-[68%] lg:w-[77%] xl:w-[81%] 2xl:w-[84.5%] h-[calc(100vh-4rem)] p-4 flex flex-col gap-10'>
        <div className='w-full bg-[#E2F3F8] p-6 rounded-xl flex flex-col justify-center items-center gap-5'>
          <StarDiamond />
          <h3 className='font-bold'>Diamond Plan</h3>
          <p>
            Thank you for being a lifetime member of the platform Enjoy an
            uninterrupted experience.
          </p>
        </div>
        <div className='flex justify-center'>
          <button
            type='button' onClick={()=> navigate('/user/payment')}
            className='border-primary-color text-primary-color border-[1.5px] rounded-lg py-1 px-4 hover:btn-color-primary'
            data-te-ripple-init
          >
            View Old Payment History
          </button>
        </div>
      </div>
    </div>
  )
}

export default DiamondSubscribed
