import { createSlice } from '@reduxjs/toolkit';

export const LoginError = createSlice({
    name: 'error',
    initialState: '',
    reducers: {
        setError: (state, action) => {
            return action.payload;
        }
    },
});

export const { setError } = LoginError.actions;

export const selectError = (state) => state.error;
export default LoginError.reducer;
