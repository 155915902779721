import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as RedArrowDown } from '../images/redArrowDown.svg';
import { ReactComponent as GreenArrowUp } from '../images/greenArrowUp.svg';
import { formatAmount } from '../utilityFunctions/Utils';

const RevenueCards = ({value, text, secondaryValue, secondaryText, id}) => {
  return (
    <div className={` flex flex-col justify-center items-center py-4 px-4 md:py-7 2xl:py-9 gap-2 rounded-xl bg-white`}>
            <h3 className='text-base lg:text-2xl 2xl:text-3xl font-bold'>{`$${formatAmount(value, 1)}`}</h3>
            <p className='text-xs lg:text-sm 2xl:text-base capitalize text-gray-400'>{text}</p>
            <div className=' col-span-2 lg:col-start-2 flex gap-2 items-center '>
                  {secondaryValue<1? <RedArrowDown/>:<GreenArrowUp/>}
                  <span className={`${secondaryValue<1?'text-[#E9523A]':'text-primary-no-hover'} font-semibold text-xs md:text-base`}>{`${secondaryValue}%`}</span>
                  <span className='text-gray-400 capitalize text-xs md:text-sm'>
                    ({secondaryText})
                  </span>
            </div>
        </div>
  )
}

RevenueCards.propTypes = {
    value: PropTypes.number,
    secondaryValue: PropTypes.string,
    text: PropTypes.string,
    secondaryText: PropTypes.string,
    id: PropTypes.number
}

export default RevenueCards
