import React, { useEffect, useState } from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../components/Loader";
import {authUser, setUser} from "../redux/slices/UserSlice";
import {setQuestionnaireId} from "../redux/slices/QuestionnaireIdSlice";

const SharedQuestionnaire = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(authUser)
    const {questionnaireId} = useParams();
    

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchQuestionnaireData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/questionnaire/${questionnaireId}`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${user?.token}`,
                        "Content-Type": "application/json",
                         
 
                    },
                });
                if (response.ok) {
                    const result = await response.json();
        
                    if(result?.data.createdBy.id === user?.id) {
                        navigate(`/user/quiz/my-quiz/${questionnaireId}`);
                    }else{
                        navigate(`/user/quiz/friends-quiz/${questionnaireId}`);
                    }
                } else {
                    navigate("/");
                }
            } catch (error) {
                console.log(error,'error')
                navigate("/");
            }
        };

        dispatch(setQuestionnaireId(questionnaireId));
        localStorage.setItem("questionnaireId",questionnaireId)

        if(user === null){
            navigate("/");
        }else{
            fetchQuestionnaireData();
        }

    }, [navigate, dispatch]);

    return (
        <div>
            {isLoading && <Loader />}
        </div>
    );
};

export default SharedQuestionnaire;
