import { useDispatch } from 'react-redux';
import { setUser } from '../redux/slices/UserSlice';
import { setEmail } from '../redux/slices/LoginPageSlice';
import { setError } from '../redux/slices/LoginErrorSlice';


export const checkAuthorization = (response) => {

     const dispatch = useDispatch()

console.log('Authorization function called');

    if (response.status === 401 || response.status===400) {
        localStorage.clear();
        dispatch(setError('Authentication failed. Please login again.'));
        dispatch(setUser(null));
        dispatch(setEmail(''));
        return true
    }else{
        return false
    }
}

export const formatAmount = (num, digits) => {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup.slice().reverse().find(function(item) {
        return num >= item.value;
      });
      return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0"
}