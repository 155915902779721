import React from 'react';
import { useState } from 'react';
import Logo from '../images/Logo.png';
import { ReactComponent as FreeBadge } from '../images/free_ic.svg';
import { ReactComponent as GoldBadge } from '../images/gold_ic.svg';
import { ReactComponent as DiamondBadge } from '../images/diamond_ic.svg';
import { IoClose } from 'react-icons/io5';
import { FaBarsStaggered } from 'react-icons/fa6';
import { changeNavStatus } from '../redux/slices/NavStatusSlice';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from './Breadcrumbs';
import PropTypes from 'prop-types';
import { authUser } from '../redux/slices/UserSlice';
import { useNavigate } from 'react-router-dom';
import TrialStatus from './TrialStatus';
// import { Link } from 'react-router-dom';

const Header = ({ isAdmin }) => {

// /////////////////////// Profile Dropdown logic //////////////////////////////////

  // const [isOpen, setIsOpen] = useState(false);

  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };
const navigate = useNavigate();
  const user = useSelector(authUser);
  const navState = useSelector((state) => state.nav.navStatus);


  const dispatch = useDispatch();
  return (
    <div className='h-16 flex bg-white border-b-2 sticky top-0 z-40'>
      {/* ////////////////////////// Logo at left //////////////////////////////////// */}
      <div className='logo-container w-[50%] sm:w-60 px-5 md:px-10 md:border-r-2 h-full flex items-center gap-4'>
        <div
          onClick={() => dispatch(changeNavStatus())}
          className='hamburger flex items-center md:hidden cursor-pointer'
        >
          {navState ? (
            <IoClose size={16} />
          ) : (
            <FaBarsStaggered className='rotate-180' size={16} />
          )}
        </div>
        <img className='w-24 h-6 md:w-32 md:h-8' src={Logo} alt='' />
      </div>

      {/* //////////////////////////// Left side of right portion //////////////////////////// */}
      <div className='flex flex-1 items-center justify-end md:justify-between px-5 md:px-8'>


        {/* /////////////////////////////////// Bread crumbs //////////////////////////////////////////// */}
        <div className='hidden md:block'><Breadcrumbs /></div>

        {/* ////////////////////// Right portion ////////////////////////////// */}
        <div className='right flex gap-3 sm:gap-14'>
           

          {isAdmin && (
            <div onClick={()=>navigate('/admin/profile')} className='flex cursor-pointer items-center justify-center gap-4'>
              <h3 className='font-semibold break-words text-sm text-end lg:text-base'>{`${user.first_name} ${user.last_name}`}</h3>
              <img
                className='w-9 h-9 2xl:w-[2.6rem] 2xl:h-[2.6rem] rounded-full '
                src={user.image}
                alt='Admin Profile'
                id='profile-pic'
              />
            </div>
          )}

          {!isAdmin && (
            <div onClick={()=>navigate('/user/profile')} className='right flex items-center relative cursor-pointer gap-2'>
              {user.active_subscription_id === ''?<div className='mr-7 hidden md:block'><TrialStatus/></div>:null}
              <p className='font-semibold break-words text-sm text-end lg:text-base'>
                {user.first_name !== null &&
                  `${user.first_name} ${user.last_name}`}
              </p>
              <img
                // onClick={toggleDropdown}
                className='w-9 h-9 2xl:w-[2.6rem] 2xl:h-[2.6rem] rounded-full'
                src={user ? user.image : ''}
                alt=''
              />
              {/* {isOpen && (
            <div className="absolute right-0 top-12 mt-2 w-48 z-50 bg-white rounded-lg shadow-lg py-2">
              <Link
              onClick={toggleDropdown}
              to={'/user/subscription'}
                className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
              >
                Subscription
              </Link>
              <Link
              onClick={toggleDropdown}
              to={'/user/payment'}
                className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
              >
                Payment
              </Link>
            </div>
          )} */}
              <span className='absolute w-2 h-2 rounded-full right-1 bottom-1'>
                {user.plan === 'Gold' && <GoldBadge />}
                {user.plan === 'free' && <FreeBadge />}
                {user.plan === 'Diamond' && <DiamondBadge />}
              </span>
            </div>



                    
          )}
        </div>
      </div> 
    </div>
  );
};

Header.propTypes = {
  isAdmin: PropTypes.bool,
};

export default Header;
