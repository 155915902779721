import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  navStatus: false
}

const navStatusSlice = createSlice({

  name: 'navStatus',

  initialState,

  reducers: {
    changeNavStatus: (state) => {
      return { ...state, navStatus: !state.navStatus }
    }
  }
})

export const { changeNavStatus } = navStatusSlice.actions
export default navStatusSlice.reducer
