import React from "react";
import { ReactComponent as CloseIcon } from "../images/close_ic.svg";
import { ReactComponent as CopyBtn } from "../images/copy_btn.svg";
import { TbBrandWhatsapp } from "react-icons/tb";
import { BsTwitterX } from "react-icons/bs";
import { GrFacebookOption } from "react-icons/gr";
import { RiLinkedinFill } from "react-icons/ri";
import { FaInstagram } from "react-icons/fa";
import { useState } from "react";
import Logo from "../images/Ellipse.png";
import Logo2 from "../images/warningEclips.png";
import Thumb from "../images/image 26.png";
import Warning from "../images/Warning Emoji 1.png";
import CopyToClipboard from "react-copy-to-clipboard";
import {Link, useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton} from "react-share";
import { RiFeedbackFill } from "react-icons/ri";

const ModalWindow = ({
  isShareModal,
  show,
  onClose,
  onYes,
  onNo,
  msgHeading,
  msg,
  generatedLink,
  feedbackText,
  showEvaluateButton,
  confirmation

}) => {


  const shareUrlOnInstagram = () => {
    window.open(`https://www.instagram.com/?url=${generatedLink}`, '_blank');
  }

  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  
  if (show) {
    return (
      <div className='fixed left-0 bottom-0 w-full h-screen fill-inherit bg-black backdrop-blur-[1px] z-50 bg-opacity-30 flex justify-center items-center'>
        <div className='w-96 2xl:w-[28rem] rounded-3xl mx-4 px-2 bg-white relative'>
          {
            confirmation&&
            
            <div className="p-9 flex flex-col gap-10">
              <div className='icon flex justify-center'>
              <div className='w-fit relative'>
                <img src={Logo2} alt='' />
                <img
                  className='absolute top-[25%] left-[25%]'
                  src={Warning}
                  alt=''
                />
              </div>
            </div>
            <p className="font-semibold text-center text-lg">Are you sure you want to cancel subscription?</p>

            <div className="flex justify-center items-center gap-4">
              <button className="bg-gray-100 modal-btn-color-primary px-4 py-3 rounded-lg w-[50%]" onClick={() => onYes()}>Yes</button>
              <button className="bg-gray-100 modal-btn-color-primary px-4 py-3 rounded-lg w-[50%]" onClick={() => onNo()}>No</button>
            </div>
          </div>
          }
          <div className='close-icon-container'>
              <CloseIcon
              onClick={() => onClose()}
              className='absolute top-4 right-4 cursor-pointer'
            />
          </div>
          {
            confirmation?null:
            <div className='content mt-14 mb-5 flex flex-col gap-1 items-center'>
            <div className='icon flex justify-center'>
              <div className='w-fit relative'>
                <img src={Logo} alt='' />
                {
                  isShareModal?<div className="absolute top-[34%] left-[34%]"><RiFeedbackFill size={25}/></div>:
                  <img
                  className='absolute top-[25%] left-[25%]'
                  src={Thumb}
                  alt=''
                />}
              </div>
            </div>
            {/* ///////////////////////////////// */}
            <h3 className=' font-bold text-2xl mt-2'>{msgHeading}</h3>
            <p className='text-gray-400 text-center'>{msg}</p>

            {/* /////////////////////// feedback msg //////////////////////// */}
            <p className='font-bold  w-[80%] text-center mt-5'>{feedbackText}</p>
          </div>
          }

          {generatedLink !== "" && confirmation !== true ? (
            <div className="secod-main-container bg-gray-100 w-[85%] rounded-xl mb-14 py-5 mx-auto flex flex-col gap-4">
              <div className="link-container  flex flex-col gap-1 items-center">
                <p>Copy your unique link</p>
                <div className='flex items-center justify-between gap-2 w-[75%] relative'>
                  <Link className="w-full">
                    <input
                      className='outline-none border-none w-full bg-transparent text-sm text-primary-no-hover'
                      type='text'
                      value={generatedLink}
                      readOnly
                    />
                  </Link>

                  <CopyToClipboard
                    text={generatedLink}
                    onCopy={() => {
                      true
                      setCopied(true)
                    }}
                  >
                    <CopyBtn className='cursor-pointer' />
                  </CopyToClipboard>
                  
                </div>
                {copied&&<span className="text-xs font-bold self-end px-8">Copied</span>}
              </div>

              <div className='bottom-icons-container flex flex-col gap-1 items-center'>
                <h2>Share your link</h2>
                <div className='icons flex gap-3'>
                  <WhatsappShareButton url={generatedLink}>
                  <div className="whatsapp text-[#0A526C] hover:text-white  w-[1.88rem] border-none outline-none flex justify-center items-center h-[1.88rem] bg-gray-200 icon-hover-primary rounded-full cursor-pointer">
                    <TbBrandWhatsapp className=''/>
                  </div>
                  </WhatsappShareButton>
                  {/* <div onClick={shareUrlOnInstagram} className="whatsapp text-black hover:text-white  w-[1.88rem] border-none outline-none flex justify-center items-center h-[1.88rem] bg-gray-200 icon-hover-primary rounded-full cursor-pointer">
                    <FaInstagram  className=''/>
                  </div> */}
                  <FacebookShareButton url={generatedLink}>
                  <div className="whatsapp text-[#0A526C] hover:text-white  w-[1.88rem] border-none outline-none flex justify-center items-center h-[1.88rem] bg-gray-200 icon-hover-primary rounded-full cursor-pointer">
                    <GrFacebookOption className=''/>
                  </div>
                  </FacebookShareButton>
                  <TwitterShareButton url={generatedLink}>
                  <div className="whatsapp text-[#0A526C] hover:text-white  w-[1.88rem] border-none outline-none flex justify-center items-center h-[1.88rem] bg-gray-200 icon-hover-primary rounded-full cursor-pointer">
                    <BsTwitterX className=''/>
                  </div>
                  </TwitterShareButton>
                  <LinkedinShareButton url={generatedLink}>
                  <div className="whatsapp text-[#0A526C] hover:text-white  w-[1.88rem] border-none outline-none flex justify-center items-center h-[1.88rem] bg-gray-200 icon-hover-primary rounded-full cursor-pointer">
                    <RiLinkedinFill  className=''/>
                  </div>
                  </LinkedinShareButton>
                  
                </div>
              </div>
            </div>
          ) : null}

          {showEvaluateButton && confirmation !== true && <div className='flex justify-center items-center'>
            <button
              type='button' onClick={()=> navigate('/user/quiz')}
              className='focus:outline-none w-full text-white btn-color-primary font-medium rounded-lg text-sm p-2 mb-10 mx-10  '
            >
              Evaluate Yourself First
            </button>
            </div>
          }

        </div>
      </div>
    )
  }
}

ModalWindow.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onYes: PropTypes.func,
  onNo: PropTypes.func,
  msgHeading: PropTypes.string,
  msg: PropTypes.string,
  generatedLink: PropTypes.string,
  feedbackText: PropTypes.string,
  showEvaluateButton: PropTypes.bool,
  confirmation: PropTypes.bool,
  isShareModal: PropTypes.bool
  
}

export default ModalWindow;
