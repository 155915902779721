import React, { useEffect, useState } from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../components/Loader";
import {authUser, setUser} from "../redux/slices/UserSlice";

const PaymentSuccess = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(authUser)
    const token = user?.token;
    const [isLoading, setIsLoading] = useState(true);
    const [isConditionFulfilled, setIsConditionFulfilled] = useState(false);
    const [attempts, setAttempts] = useState(0);

    const maxAttempts = 5;

    const fetchData = async () => {
        try {
            // Simulating an asynchronous operation
            await new Promise(resolve => setTimeout(resolve, 1000));

            // Your condition logic (mocked with a simple check on attempts)
            if (attempts >= maxAttempts) {

                // Get Latest Data

                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-profile`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${user?.token}`,
                        "Content-Type": "application/json",
                         
                    },
                });


                if (response.ok) {
                    const result = await response.json();
                    if (result.data.plan !== user.plan ) {

                        if (user.plan === 'Gold' && result.data.plan === 'Diamond'){
                            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/remove-gold-subscription`, {
                                method: "GET",
                                headers: {
                                    Authorization: `Bearer ${user?.token}`,
                                    "Content-Type": "application/json",
                                },
                            });
                        }
                        const data = {
                            ...result.data,
                            token: token,
                        };
                        dispatch(setUser(data));
                        setIsConditionFulfilled(true);
                        navigate("/user/subscription");
                    }
                }
            } else {
                // If the condition is not fulfilled, update attempts and retry
                setAttempts(attempts + 1);
                fetchData();
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        // Call the fetchData function
        fetchData();
    }, [attempts, isConditionFulfilled]);


    // useEffect(() => {
    //     const fetchQuestionnaireData = async () => {
    //         const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-profile`, {
    //             method: "GET",
    //             headers: {
    //                 Authorization: `Bearer ${user?.token}`,
    //                 "Content-Type": "application/json",
    //             },
    //         });
    //
    //         if (response.ok) {
    //             const result = await response.json();
    //             if (result.data.plan !== user.plan ) {
    //                 const data = {
    //                     ...result.data,
    //                     token: token,
    //                 };
    //                 dispatch(setUser(data));
    //
    //                 navigate("/user/subscription");
    //             }
    //
    //         }
    //     };
    //
    //     fetchQuestionnaireData();
    //
    // }, [navigate, dispatch]);

    return (
        <div>
            {isLoading && <Loader />}
        </div>
    );
};

export default PaymentSuccess;
