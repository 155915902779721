import React from 'react'

const Error403 = () => {
  return (
    <div className='flex items-center flex-col justify-center h-screen'>
    <h1 className=' text-center text-3xl font-bold'>403</h1>
    <h1 className=' text-center text-3xl font-bold'>You are not allowed to access this page</h1>
  </div>
  )
}

export default Error403
