import { createSlice } from '@reduxjs/toolkit';

export const QuestionnaireIdSlice = createSlice({
    name: 'questionnaireId',
    initialState: '',
    reducers: {
        setQuestionnaireId: (state, action) => {
            return action.payload;
        }
    },
});

export const { setQuestionnaireId } = QuestionnaireIdSlice.actions;

export const selectQuestionnaireId = (state) => state.questionnaireId;
export default QuestionnaireIdSlice.reducer;
