import React from 'react'
import PropTypes from 'prop-types'
import { formatAmount } from '../utilityFunctions/Utils'

const UsersCard = ({value, text}) => {
    return (
        <div className={`card flex flex-col justify-center items-center py-4 px-4 md:py-7 2xl:py-9 gap-2 rounded-xl md:col-auto bg-white`}>
            <h3 className='text-xl lg:text-2xl 2xl:text-3xl font-bold'>{`${formatAmount(value, 1)}`}</h3>
            <p className='text-xs lg:text-sm 2xl:text-base text-gray-400 capitalize'>{text}</p>
        </div>
    )
}

UsersCard.propTypes = {
    value: PropTypes.number,
    text: PropTypes.string
}
export default UsersCard