import React, {useEffect, useState, useMemo} from 'react';
import UsersCard from './UsersCard';
import { IoSearch } from 'react-icons/io5';
import { FaFilter } from 'react-icons/fa6';
import {
  Popover,
  PopoverHandler,
  PopoverContent,
  Button,
} from '@material-tailwind/react';
import Select from 'react-select';
import Pagination from '../components/Pagination';
import ReactCountryFlag from "react-country-flag"
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {authUser} from "../redux/slices/UserSlice";
import { format } from 'date-fns';
import Loader from "../components/Loader";

import { useDispatch } from 'react-redux';
import { setUser } from '../redux/slices/UserSlice';
import { setEmail } from '../redux/slices/LoginPageSlice';
import { setError } from '../redux/slices/LoginErrorSlice';
import { BiSolidDownArrow } from "react-icons/bi";


const Users = () => {
  const user = useSelector(authUser);
  const [countries, setCountries] = useState([])
  const [cardsData, setCardsData] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);


  const [data, setData] = useState()
  const navigate = useNavigate()
  const dispatch = useDispatch()



  const bColor = 'var(--primary-color)';


  const [selectedOptions, setSelectedOptions] = useState({
    gender: null,
    countryValue: null,
    subscription: null
  });
  const genderOptions = [
    { value: '', label: 'All' },
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'other', label: 'Other' }
  ]

  const subscriptionsOptions = [
    { value: '', label: 'All' },
    { value: 'free', label: 'Free' },
    { value: 'Gold', label: 'Gold' },
    { value: 'Diamond', label: 'Diamond' },
    { value: 'trial', label: 'On trial' }
  ]

  const [selectedGender, setSelectedGender] = useState({ value: '', label: 'All' });
  const [selectedCountry, setSelectedCountry] = useState({ value: '', label: 'All' });
  const [selectedSubscription, setSelectedSubscription] = useState({ value: '', label: 'All' });

  const fetchCountries = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/config/countries`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });

if (response.status === 401 || response.status===400) {
          localStorage.clear();
          dispatch(setError('Authentication failed. Please login again.'));
          dispatch(setUser(null));
          dispatch(setEmail(""));
          navigate("/");
      }
  
      if (response.ok) {
        const result = await response.json();
        setCountries([{ value: '', label: 'All' }, ...result.data]);
        return result.data
      } 
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
  fetchCountries()
},[])



  useEffect(()=> {

    const fetchUsers = async () => {
      setIsLoading(true)
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users?pageNum=${currentPage}${searchValue !== '' ? '&search='+searchValue : ''}${selectedGender.value !== '' ? '&gender='+selectedGender.value : ''}${selectedSubscription.value !== '' ? '&subscription='+selectedSubscription.value : ''}${selectedCountry.value !== '' ? '&country='+selectedCountry.value : ''}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        });

        if (response.status === 401 || response.status===400) {
          localStorage.clear();
          dispatch(setError('Authentication failed. Please login again.'));
          dispatch(setUser(null));
          dispatch(setEmail(""));
          navigate("/");
      }

        if (response.ok) {
          const result = await response.json();
          setData(result.data);
          // return result.data
          const userStats = [
            {
              id: 1,
              value: result?.data?.totalUsers,
              text: 'All Users',
            },
            {
              id: 2,
              value: result?.data?.freeUsers,
              text: 'Free Plan Users',
            },
            {
              id: 3,
              value: result?.data?.goldUsers,
              text: 'Gold Plan Users',
            },
            {
              id: 4,
              value: result?.data?.diamondUsers,
              text: 'Diamond Plan Users',
            },
          ];
          setCardsData(userStats)
          setIsLoading(false)
        }
      } catch (error) {
        console.log(error);
      }
    };

  fetchUsers()


  },[navigate,currentPage])

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSelectChange = (identifier) => (selectedOption) => {
    setSelectedOptions({
      ...selectedOptions,
      [identifier]: selectedOption,
    });
  };

  const handleSubmit =async (e)=>{
  e.preventDefault();

    setIsLoading(true)
    setCurrentPage(1)
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users?pageNum=${currentPage}${selectedGender.value !== '' ? '&gender='+selectedGender.value : ''}${selectedSubscription.value !== '' ? '&subscription='+selectedSubscription.value : ''}${selectedCountry.value !== '' ? '&country='+selectedCountry.value : ''}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });

      if (response.status === 401 || response.status===400) {
        localStorage.clear();
        dispatch(setError('Authentication failed. Please login again.'));
        dispatch(setUser(null));
        dispatch(setEmail(""));
        navigate("/");
    }

      if (response.ok) {
        const result = await response.json();
        setData(result.data);
        // return result.data
        const userStats = [
          {
            id: 1,
            value: result?.data?.totalUsers,
            text: 'All Users',
          },
          {
            id: 2,
            value: result?.data?.freeUsers,
            text: 'Free Plan Users',
          },
          {
            id: 3,
            value: result?.data?.goldUsers,
            text: 'Gold Plan Users',
          },
          {
            id: 4,
            value: result?.data?.diamondUsers,
            text: 'Diamond Plan Users',
          },
        ];
        setCardsData(userStats)
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch(); // Call your search function or perform any action here
    }
  };

  const clearFilters = async () => {
    setSelectedGender(genderOptions[0])
    setSelectedCountry(countries[0])
    setSelectedSubscription(subscriptionsOptions[0]);
  }
  const handleSearch = async () => {
    setIsLoading(true)
    setCurrentPage(1)
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users?pageNum=${currentPage}&search=${searchValue}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        });

        if (response.status === 401 || response.status===400) {
          localStorage.clear();
          dispatch(setError('Authentication failed. Please login again.'));
          dispatch(setUser(null));
          dispatch(setEmail(""));
          navigate("/");
      }
      
        if (response.ok) {
          const result = await response.json();
          setData(result.data);
          // return result.data
          const userStats = [
            {
              id: 1,
              value: result?.data?.totalUsers,
              text: 'All Users',
            },
            {
              id: 2,
              value: result?.data?.freeUsers,
              text: 'Free Plan Users',
            },
            {
              id: 3,
              value: result?.data?.goldUsers,
              text: 'Gold Plan Users',
            },
            {
              id: 4,
              value: result?.data?.diamondUsers,
              text: 'Diamond Plan Users',
            },
          ];
          setCardsData(userStats)
          setIsLoading(false)
        }
      } catch (error) {
        console.log(error);
      }
  };


  ////////////////////////////////////////// Sorting Logic ///////////////////////////////////////////////
  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);
  
    const sortedItems = useMemo(() => {
      let sortableItems = items;
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);
  
    const requestSort = (key) => {
      let direction = 'ascending';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };
  
    return { items: sortedItems, requestSort, sortConfig };
  };


  const { items, requestSort, sortConfig } = useSortableData(data?.users);

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
 

  const [userArrow, setUserArrow] = useState(false)
  const [emailArrow, setEmailArrow] = useState(false)

  return (
      <>
        {isLoading ? (
            <Loader />
        ) : (
    <div className='w-[100vw] md:w-[calc(97.8vw-223px)] lg:w-[calc(97.8vw-217px)] 2xl:w-[calc(98.7vw-220px)] h-[calc(100vh-3.6rem)] md:h-[calc(100vh-4rem)] flex md:ml-60 bg-gray-100 overflow-y-scroll'>
      <div className='w-full h-[calc(100vh-4rem)] px-4 md:px-10 py-4 p-4 flex flex-col gap-7'>
        <div className='flex flex-col gap-6'>


          {/* /////////////////////////// Top part with cards //////////////////////////////// */}
          <p className='font-semibold'>Overview</p>

          {/* /////////////////////////////////////////////////////////////////////////////////// */}
          <div className='cards-container w-full grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-5'>
             {cardsData?.map(({ id, value, text, index }) => {
              return <UsersCard key={id} value={value} text={text} id={id}/>;
            })}
          </div>

          {/* //////////////////////////////// Bottom Part /////////////////////////////////////////// */}
          <div className='flex flex-col gap-3 mt-4 pb-4'>
            <h3 className='font-bold mb-2'>Manager Users</h3>

            <div className='Search  flex items-center justify-between'>
              <div className='relative shadow-md rounded-xl text-gray-600'>
                <input
                  className=' h-11 px-5 pr-8 w-40 sm:w-64 rounded-xl text-sm focus:outline-none'
                  type='search'
                  name='search'
                  placeholder='Search User'
                  value={searchValue}
                  onKeyDown={handleKeyPress}
                  onChange={handleInputChange}
                />
                <button
                  type='submit'
                  className='absolute right-0 top-[25%] mr-4'
                >
                  <IoSearch size={22} />
                </button>
              </div>

              <Popover placement='bottom-start'>
                <PopoverHandler>
                  <Button className='flex gap-4 text-[#959595] items-center rounded-xl py-[13px] bg-white'>
                    <FaFilter /> Filter
                  </Button>
                </PopoverHandler>
                <PopoverContent>
                  <form onSubmit={handleSubmit} className='bg-white flex flex-col gap-2 p-2 rounded-lg'>
                    {/* ////////////////////////////// Gender /////////////////////////////////// */}
                    <div className='flex flex-col w-60 gap-1'>
              <label
                className='text-sm 2xl:text-base text-gray-400'
                htmlFor='gender'
              >
                Gender
              </label>
              <Select
                styles={{
                  control: (base, state) => ({
                    ...base,
                    boxShadow: state.isFocused ? 0 : 0,
                    border: '1px solid #E5E7EB',
                    borderRadius: '8px',
                    padding: '5.5px',
                    borderColor: state.isFocused
                      ? 'brandColor'
                      : base.borderColor,
                    '&:hover': {
                      borderColor: state.isFocused ? bColor : base.borderColor,
                    },
                  }),

                  placeholder: (baseStyles) => ({
                    ...baseStyles,
                    color: '#9CA3AF',
                  }),
                  option: (base, { isFocused }) => {
                    return {
                      ...base,
                      padding: '2',
                      backgroundColor: isFocused ? '#1967D2' : 'white',
                      color: isFocused ? 'white' : 'black',
                    };
                  },
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    padding: '6px',
                  }),
                }}
                name='gender'
                className='rounded-lg text-sm 2xl:text-base focus:shadow-green-500 shadow-sm'
                // defaultValue={genderOptions[0]}
                value={selectedGender}
                onChange={(selectedOption) => setSelectedGender(selectedOption)}
                options={genderOptions}
              />
            </div>


                    {/* ///////////////////////// Country ///////////////////////// */}
                    <div className='flex flex-col w-60 gap-1'>
              <label
                className='text-sm 2xl:text-base text-gray-400'
                htmlFor='country'
              >
                Country
              </label>
              <Select
                styles={{
                  control: (base, state) => ({
                    ...base,
                    boxShadow: state.isFocused ? 0 : 0,
                    border: '1px solid #E5E7EB',
                    borderRadius: '8px',
                    padding: '5.5px',
                    borderColor: state.isFocused
                      ? 'brandColor'
                      : base.borderColor,
                    '&:hover': {
                      borderColor: state.isFocused ? bColor : base.borderColor,
                    },
                  }),

                  placeholder: (baseStyles) => ({
                    ...baseStyles,
                    color: '#9CA3AF',
                  }),
                  option: (base, { isFocused }) => {
                    return {
                      ...base,
                      padding: '2',
                      backgroundColor: isFocused ? '#1967D2' : 'white',
                      color: isFocused ? 'white' : 'black',
                    };
                  },
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    padding: '6px',
                  }),
                }}
                name='country'
                className='rounded-lg text-sm 2xl:text-base focus:shadow-green-500 shadow-sm'
                value={selectedCountry}
                // loadingIndicator={true}
                onChange={(selectedOption) => setSelectedCountry(selectedOption)}
                options={countries}
              />
            </div>

                    {/* ///////////////////////////// Subscription /////////////////////////// */}
                    <div className='flex flex-col w-60 gap-1'>
              <label
                className='text-sm 2xl:text-base text-gray-400'
                htmlFor='subscription'
              >
                Subscription
              </label>
              <Select
                styles={{
                  control: (base, state) => ({
                    ...base,
                    boxShadow: state.isFocused ? 0 : 0,
                    border: '1px solid #E5E7EB',
                    borderRadius: '8px',
                    padding: '5.5px',
                    borderColor: state.isFocused
                      ? 'brandColor'
                      : base.borderColor,
                    '&:hover': {
                      borderColor: state.isFocused ? bColor : base.borderColor,
                    },
                  }),

                  placeholder: (baseStyles) => ({
                    ...baseStyles,
                    color: '#9CA3AF',
                  }),
                  option: (base, { isFocused }) => {
                    return {
                      ...base,
                      padding: '2',
                      backgroundColor: isFocused ? '#1967D2' : 'white',
                      color: isFocused ? 'white' : 'black',
                    };
                  },
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    padding: '6px',
                  }),
                }}
                name='subscription'
                className='rounded-lg text-sm 2xl:text-base focus:shadow-green-500 shadow-sm'
                value={selectedSubscription}
                onChange={(selectedOption) => setSelectedSubscription(selectedOption)}
                options={subscriptionsOptions}
              />
            </div>


            {/* /////////////////////////// Buttons //////////////////////////////// */}
            <div className="flex mt-3 justify-between items-center">
              <button  className='text-gray-400 text-base' onClick={clearFilters} >Clear Filter</button>
              <button
                type='submit'
                className='focus:outline-none text-white btn-color-primary  font-medium rounded-lg text-sm px-9 py-2 btn-color-primary'
              >
                Apply
              </button>
            </div>
                  </form>
                </PopoverContent>
              </Popover>
            </div>


            {/* ////////////////////////////////// Table /////////////////////////////////////// */}
            <div className='overflow-x-auto relative shadow-md sm:rounded-lg'>
              <div className='relative shadow-md sm:rounded-lg'>
                <table className='text-[10px] md:text-sm 2xl:text-base w-full overflow-x-scroll no-scrollbar text-left text-black bg-white'>
                  <thead className='text-xs md:text-sm 2xl:text-lg text-gray-400  capitalize bg-[#CEF0E6]'>
                    <tr>
                      <th scope='col' className='py-3 px-6'>
                        ID
                      </th>
                      <th scope='col' className='py-3'>
                        <span className='mr-2'>Users</span>
                        <button onClick={()=>{
                          requestSort('first_name')
                          setUserArrow(!userArrow)
                          }} className={`${getClassNamesFor('first_name')} transition-all duration-200 ${userArrow&&'rotate-180'} `}><BiSolidDownArrow size={12}/>
                        </button>
                      </th>
                      <th scope='col' className='py-3 px-6'>
                        <span className='mr-2'>Email</span>
                        <button onClick={()=>{
                          requestSort('email')
                          setEmailArrow(!emailArrow)
                          }} className={`${getClassNamesFor('email')} transition-all duration-200 ${emailArrow&&'rotate-180'} `}><BiSolidDownArrow size={12}/></button>
                      </th>
                      <th scope='col' className='py-3 px-6'>
                        Subscription
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  {data?.users.length > 0 ? (
                      <>
                        {items.map(({ id, image, first_name, last_name, country, createdAt, email, plan, gender }, index) => {
                          const displayIndex = (currentPage - 1) * 10 + index + 1;
                          return (
                              <tr
                                  key={index}
                                  className={`${
                                      index % 2 === 0 ? 'bg-gray-100' : 'bg-white'
                                  }`}
                              >
                                <td className='py-4 px-6'>{displayIndex}</td>
                                <td className='py-4 pr-6  text-left'>
                                  <div className='profile-container flex gap-3 items-center'>
                                    <div className={`w-9 h-9 md:w-12 md:h-12 rounded-full flex shrink-0 grow-0 items-center justify-center ${gender !== "" && gender !== null && (gender.toLowerCase() === 'female' ? 'bg-[#EC49A6]' : 'bg-[#00AEF0]')}`}>
                                      <img
                                          className='w-8 h-8 md:w-11 md:h-11 rounded-full border-white border-[2.8px] m-[0.4rem]'
                                          src={image}
                                          alt=''
                                      />
                                    </div>
                                    <div className='div'>
                                      <div className="div flex gap-2 items-center">
                                        <p className='font-bold capitalize'>{`${first_name === null?'john':first_name}  ${last_name === null?'doe':last_name}`}</p>
                                        {country !== null && ( <ReactCountryFlag countryCode={country.initial} svg />)}
                                      </div>
                                      <div className='w-20'><p className='text-xs text-gray-400'>{format(new Date(createdAt), 'MM-dd-yyyy')}</p></div>
                                    </div>
                                  </div>
                                </td>
                                <td className='py-4 px-6'>{email}</td>
                                <td className='py-4 px-6'>
                                  <p
                                      className={`p-2 capitalize  ${plan.toLowerCase() === 'free' && 'bg-[#C1C9C7]'} 
              ${plan.toLowerCase() === 'gold' && 'bg-[#FBC223]'} 
              ${plan.toLowerCase() === 'diamond' && 'bg-[#26c7f2]'} 
              ${plan === 'on trial' && 'bg-primary'} text-white rounded-md px-7 w-28 text-center`}
                                  >
                                    {plan}
                                  </p>
                                </td>
                              </tr>
                          );
                        })}
                      </>
                  ) : (
                      <tr>
                        <td className='py-4 px-6 text-center' colSpan={4}>{'No User Found'}</td>
                      </tr>
                  )}

                  </tbody>
                </table>
              </div>
            </div>
            {data?.users.length > 0 && (
              <div className='pagination flex items-center gap-3 mt-4'>
                <p className='text-xs'>Showing {currentPage}/{data?.totalPages}</p>
                <Pagination
                    className='pagination-bar'
                    currentPage={currentPage}
                    totalCount={data?.totalUsers}
                    pageSize={10}
                    onPageChange={(page) => setCurrentPage(page)}
                />
              </div>)}
          </div>


{/* ///////////////////////////////////// Pagination //////////////////////////////////////////// */}
                        


          {/* //////////////////////////////////////////////////////////////////////////////////// */}
        </div>
      </div>
    </div>
        )}
      </>
  );
};

export default Users;