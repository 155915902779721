import React, { useEffect } from "react";
import { useState } from "react";
import { ReactComponent as LargeStar } from "../images/largeStarBadge.svg";
import { ReactComponent as Diamond } from "../images/diamond.svg";
import { ReactComponent as FreeIcon } from "../images/freePlan.svg";
import { useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {authUser, setUser} from "../redux/slices/UserSlice";
import Loader from "../components/Loader";
import { loadStripe } from "@stripe/stripe-js";
import { format } from 'date-fns';
import {setEmail} from "../redux/slices/LoginPageSlice";
import {setQuestionnaireId} from "../redux/slices/QuestionnaireIdSlice";
import {setError} from "../redux/slices/LoginErrorSlice";

const Subscription = () => {
  let pageRefreshFlag = true
  const user = useSelector(authUser);
  const token = user?.token;
  const dispatch = useDispatch();
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(true);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageRefresh, setPageRefresh] = useState(pageRefreshFlag);
  const [currentDate, setCurrentDate] = useState(null);



  useEffect( () => {
    if(user?.plan === 'Diamond'){
      navigate('/user/subscription/diamond')
    }
  },[])
  useEffect( () => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/payment`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user?.token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const result = await response.json();
          setData(result?.data);
          setIsLoading(false);
        } else {
          navigate("/");
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getCurrentDate = async () => {
      // if(user.plan === 'Gold'){
      //     await fetchUserData()
      //     const today = new Date(data[0].start_date);
      //     const options = {year: 'numeric', month: 'short', day: 'numeric'};
      //     const formattedDate = today.toLocaleDateString('en-US', options);
      //     setCurrentDate(formattedDate);
      // }else{
      const today = new Date();
      const options = { year: "numeric", month: "short", day: "numeric" };
      const formattedDate = today.toLocaleDateString("en-US", options);
      setCurrentDate(formattedDate);

      // }
    };
    fetchUserData();
    getCurrentDate();
  }, [navigate,pageRefresh]);


  const handleCustomerPortal = async () => {

    setIsLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/customer-portal`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user?.token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 401 || response.status===400) {
      localStorage.clear();
      dispatch(setError('Authentication failed. Please login again.'));
      dispatch(setUser(null));
      dispatch(setEmail(""));
      dispatch(setQuestionnaireId(""));
      navigate("/");
    }

    if (response.ok) {
      const result = await response.json();
      window.open(result?.data.url,'_blank')
      pageRefreshFlag = !pageRefreshFlag
      setPageRefresh(pageRefreshFlag)
      setIsLoading(false);
      // navigate('/user/subscription')
      // window.location.href = result?.data.url;
    }
  };


  // Function to get the date with +one month and -one day
  const getDateWithOffset = (offsetMonth, offsetDay) => {
    const currentDateObj = new Date(currentDate);

    // Add one month
    currentDateObj.setMonth(currentDateObj.getMonth() + offsetMonth);

    // Subtract one day
    currentDateObj.setDate(currentDateObj.getDate() + offsetDay);

    const options = { year: "numeric", month: "short", day: "numeric" };
    return currentDateObj.toLocaleDateString("en-US", options);
  };

  const nextMonthOneDayBefore = getDateWithOffset(1, -1);
  const previousMonthOneDayAfter = getDateWithOffset(-1, 1);

  const handleSubscription = async (subscription) => {
    setIsLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/buy/${subscription}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user?.token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      console.error("Error initiating subscription purchase");
      return;
    }

    const result = await response.json();
    const sessionId = result.data.id;

    // Redirect to the Stripe Checkout page
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });

    if (error) {
      // Handle error, e.g., show an error message or redirect to an error page
      console.error("Error redirecting to Checkout:", error);
    }
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    handleCustomerPortal();
  };

  
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-[100vw] md:w-[calc(97.8vw-223px)] lg:w-[calc(97.8vw-217px)] 2xl:w-[calc(98.7vw-220px)] h-[calc(100vh-3.6rem)] md:h-[calc(100vh-4rem)] flex md:ml-60 bg-gray-100 overflow-y-scroll no-scrollbar">
          <div className="w-full h-[calc(100vh-4rem)] px-4 md:px-10 py-4 flex flex-col gap-7">

            {user.plan === "Gold" && (
            <div className="goldHeader w-full bg-[#F9EECE] rounded-xl p-6 ">
              
              <div className="header  flex flex-col lg:flex-row gap-4 justify-between">
                {/* ////////////////////////// Left div /////////////////////////////// */}
                
                <div className="left flex items-center gap-4">
                  <LargeStar />
                  <div className="plan">
                    <p className="text-sm 2xl:text-base">Active Plan</p>
                    <h2 className="font-bold 2xl:text-lg">Gold</h2>
                  </div>
                </div>
                {/* ////////////////////////// Middle div /////////////////////////////// */}
                <div className="middle flex justify-between items-center gap-10 ">
                  <div className="midLeft">
                    <p className="text-sm 2xl:text-base text-gray-500">
                      Start Date
                    </p>
                    <p className="text-base 2xl:text-lg">{format(new Date(data[data.length-1].start_date), 'MMM dd, yyyy')}</p>
                  </div>
                  <div className="midRight">
                    <p className="text-sm 2xl:text-base text-gray-500">
                      {user.active_subscription_id === ''?'End Date':'Next Billing'}
                    </p>
                    <p className="text-base 2xl:text-lg">
                      {format(new Date(data[data.length-1].end_date), 'MMM dd, yyyy')}
                    </p>
                  </div>
                </div>
                {/* ////////////////////////// Right div /////////////////////////////// */}
                <div className="flex sm:flex-col lg:flex-row items-center justify-between gap-5">

                  <button
                    type="button"
                    onClick={() => handleCustomerPortal()}
                    className="focus:outline-none w-full text-white btn-color-primary font-medium rounded-lg text-sm p-2 `}"
                  >
                  Manage Subscription
                  </button>
                </div>
              </div>
              </div>
            )}


            {
                data[0]?.subscription === "Diamond" &&
                <div className="header w-full bg-[#CAEDF7] rounded-xl p-6 flex items-center justify-between">
                <div className="flex flex-col gap-4 items-start md:flex-row md:items-center w-[54%] justify-between">
                  <div className="left flex items-center gap-4">
                    <Diamond />
                    <div className="plan">
                      <p className="text-sm 2xl:text-base">Active Plan</p>
                      <h2 className="font-bold 2xl:text-lg">Diamond</h2>
                    </div>
                  </div>
  
                  <div className="middle flex justify-between items-center gap-10 ">
                    <div className="midLeft">
                      <p className="text-sm 2xl:text-base text-gray-500">
                        Start Date
                      </p>
                      <p className="text-base 2xl:text-lg">{format(new Date(data[data.length-1].start_date), 'MMM dd, yyyy')}</p>
                    </div>
                    <div className="midRight">
                      <p className="text-sm 2xl:text-base text-gray-500">
                        End Date
                      </p>
                      <p className="text-base 2xl:text-lg">Lifetime</p>
                    </div>
                  </div>
                </div>
              </div>
            }





            <div className="pricing-container flex flex-col gap-5">
              <p className="font-semibold">Subscription Plans</p>
              <div className="plans flex overflow-x-scroll no-scrollbar justify-between mb-6 gap-8">

                {/* ////////////////////////////////////// Free Plan /////////////////////////////////////// */}
                <div
                  className={
                    user.plan === "free"
                      ? "plan2  border-primary-color border-2 plan1 h-[300px] bg-white flex flex-col items-center px-8 py-10 min-w-[17rem] sm:w-full gap-4 rounded-xl"
                      : "plan1 bg-white h-[300px] flex flex-col items-center px-8 py-10 min-w-[17rem] sm:w-full gap-4 rounded-xl"
                  }
                >
                  <div><FreeIcon /></div>
                  <h3 className="font-bold text-lg">Free</h3>
                  <p>
                    <span className="font-bold text-lg">$</span><span className="text-3xl font-extrabold">{0}</span><span className="font-bold">.00</span> <span className="pl-1 text-gray-400">/month</span>
                  </p>
                  {user.plan === "free" ? (
                    <button
                      disabled={true}
                      type="button"
                      className="focus:outline-none w-full text-black bg-[#EDF3F1] hover:bg-[${Colors.secondary}] font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                    >
                      Active plan
                    </button>
                  ) : (
                    ""
                  )}
                </div>


{/* /////////////////////////////////////// Gold Plan //////////////////////////////////////////////// */}
                <div
                  className={
                    user.plan === "Gold"
                      ? "plan2 border-primary-color border-2 h-[300px] bg-white  flex flex-col justify-center items-center py-10 px-8 min-w-[17rem] sm:w-full gap-5 rounded-xl"
                      : "bg-white flex flex-col h-[300px] justify-center items-center py-10 px-8 min-w-[17rem] sm:w-full gap-4 rounded-xl"
                  }
                >
                  <div>
                  <LargeStar />
                  </div>
                  <h3 className="font-bold text-lg">Gold</h3>
                  <p>
                  <span className="font-bold text-lg">$</span><span className="text-3xl font-extrabold">{2}</span><span className="font-bold">.00</span> <span className="pl-1 text-gray-400">/month</span>
                  </p>
                  {user.plan !== "Diamond" && (
                    <div className="w-full">
                      <button
                        disabled={
                          user.plan === "Gold" || user.plan === "Diamond"
                        }
                        type="button"
                        onClick={() => handleSubscription("gold")}
                        className={
                          user.plan === "Gold"
                            ? "focus:outline-none w-full text-black bg-[#EDF3F1] hover:btn-color-primary font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                            : "focus:outline-none w-full text-white btn-color-primary hover:bg-[${Colors.secondary}] font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                        }
                      >
                        {user.plan === "Gold" ? "Active plan" : "Subscribe"}
                      </button>

                    </div>
                  )}
                </div>


{/* //////////////////////////////////////// Diamond Plan /////////////////////////////////////////////// */}
                <div
                  className={
                    user.plan === "Diamond"
                      ? " border-primary-color border-2 h-[300px] plan3  bg-white flex flex-col justify-center items-center px-8 py-10 min-w-[17rem] sm:w-full gap-4 rounded-xl"
                      : " bg-white flex flex-col h-[300px] justify-center items-center px-8 py-10 min-w-[17rem] sm:w-full gap-4 rounded-xl"
                  }
                >
                  <div><Diamond /></div>
                  <h3 className="font-bold text-lg">Diamond</h3>
                  <p> <span className="font-bold text-lg">$</span><span className="text-3xl font-extrabold">{24}</span><span className="font-bold">.00</span> <span className="pl-1 text-gray-400">for lifetime</span></p>
                  <button
                    onClick={() =>
                      user.plan === "Diamond"
                        ? navigate("/user/subscription/diamond")
                        : handleSubscription("diamond")
                    }
                    type="button"
                    className={
                      user.plan === "Diamond"
                        ? "focus:outline-none w-full text-black bg-[#EDF3F1] font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                        : "focus:outline-none w-full text-white btn-color-primary hover:bg-[${Colors.secondary}] font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                    }
                  >
                    {user.plan === "Diamond" ? "Active plan" : "Subscribe"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Subscription;
