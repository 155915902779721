import { ReactComponent as Views } from '../images/views.svg';
import { ReactComponent as InsightsIcon } from '../images/insights_ic.svg';
import { ReactComponent as Vesponse } from '../images/response_ic.svg';
import cardUser from '../images/card-user.jpg';
import {
  TETabs,
  TETabsContent,
  TETabsItem,
  TETabsPane,
} from 'tw-elements-react';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { authUser, setUser } from '../redux/slices/UserSlice';
import { useSelector } from 'react-redux';
import Loader from '../components/Loader';
import ModalWindow from '../components/ModalWindow';

const Questionnaire = () => {
  const navigate = useNavigate();
  const user = useSelector(authUser);
  const [isLoading, setIsLoading] = useState(true);
  const [myQuestionnaires, setMyQuestionnaires] = useState([]);
  const [friendsQuestionnaires, setFriendsQuestionnaires] = useState([]);
  const [modal, setModal] = useState(false)


  const onClose = () => {
    setModal(false)
}
  const generatedLink = `${process.env.REACT_APP_PUBLIC_URL}/questionnaire/${myQuestionnaires[0]?.id}`

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/questionnaire`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${user?.token}`,
              'Content-Type': 'application/json',
               
            },
          }
        );

        if (response.ok) {
          const result = await response.json();
          setMyQuestionnaires(result?.data?.myQuestionnaires);
          setFriendsQuestionnaires(result?.data?.friendsQuestionnaire);
        } else {
          navigate('/');
        }
      } catch (error) {
        navigate('/');
      } finally {
        setIsLoading(false);
      }
    };

    // Call the asynchronous function
    fetchUserData();
  }, [navigate]);

  const [basicActive, setBasicActive] = useState('my questionnaire');

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };

  // setEvaluationStatus(myQuestionnaires[0]?.selfEvaluationStatus==='pending'?false:true)

  return (
    <div>
      <ModalWindow show={modal} isShareModal={true} msgHeading={`Let's get Feedback`} generatedLink={generatedLink} onClose={onClose}/>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='w-[100vw] md:w-[calc(97.8vw-223px)] lg:w-[calc(97.8vw-217px)] 2xl:w-[calc(98.7vw-220px)] h-[calc(100vh-3.6rem)] md:h-[calc(100vh-4rem)] flex md:ml-60 bg-gray-100'>
          <div className='w-full h-[calc(100vh-4rem)] px-4 md:px-10 lg:pb-4'>
            <div className='mb-3 w-full'>
              <TETabs className=' border-b-2 '>
                <TETabsItem
                color='info'
                  onClick={() => handleBasicClick('my questionnaire')}
                  active={basicActive === 'my questionnaire'}
                  className=' pe-0 ps-0'
                >
                  <span
                    className={`text-[0.7rem] sm:text-sm capitalize ${
                      basicActive === 'my questionnaire' && 'text-black'
                    }`}
                  >
                    My Quizzes
                  </span>
                </TETabsItem>
                <TETabsItem
                color='info'
                  onClick={() => handleBasicClick('my friends questionnaire')}
                  active={basicActive === 'my friends questionnaire'}
                  className=' pe-0 ps-0 ml-4 sm:ml-8'
                >
                  <span
                    className={`text-[0.7rem] sm:text-sm capitalize ${
                      basicActive === 'my friends questionnaire' && 'text-black'
                    }`}
                  >
                    My Friend&apos;s Quizzes
                  </span>
                </TETabsItem>
              </TETabs>

              {/* ////////////////////////////////// Tabs Content ////////////////////////////////////////////// */}
              <TETabsContent className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 sm:gap-9'>
                {/* ////////////////////////// My questionnaire single card ////////////////////////////////// */}
                {myQuestionnaires?.map((item) => {
                  return (
                    <TETabsPane
                      key={item.id}
                      className=' w-72 sm:w-96 h-60 rounded-lg p-4 relative bg-white'
                      show={basicActive === 'my questionnaire'}
                    >
                      <div className='flex flex-col gap-4 items-start'>
                        <div className='flex w-full justify-between items-center'>
                        {item.selfEvaluationStatus === false ? (
                          <p onClick={()=>navigate(`/user/quiz/my-quiz/${item.id}`)} className='text-[#E9793A] bg-red-100 py-[0.33rem] px-2 rounded-lg text-[0.7rem] md:text-sm text-center font-semibold capitalize cursor-pointer'>
                            Self Quiz Pending
                          </p>
                        ) : (
                          <p onClick={()=>navigate(`/user/quiz/evaluation-done/${item.id}`)} className='text-[rgb(49,182,201)] bg-blue-100 py-[0.33rem] px-2 rounded-lg text-[0.7rem] md:text-sm text-center font-semibold capitalize cursor-pointer'>
                            Self Quiz Done
                          </p>
                        )}

                        {
                          item.selfEvaluationStatus&&
                          <p
                          onClick={()=>navigate(`/user/quiz/insights/${item.id}`)}
                           className='flex justify-center gap-2 border-[1.5px] rounded-lg py-[0.31rem] px-3  border-primary-color items-center text-[0.7rem] md:text-sm text-primary-color duration-200 cursor-pointer'><InsightsIcon/>Show Insights</p>}
                        </div>

                        <p className='text-base md:text-lg font-bold'>{item.name}</p>

                        <div className='progress&Icons  w-full flex flex-col gap-0'>
                          <div className='mb-1 h-[0.35rem] w-full bg-gray-200 dark:bg-neutral-600 rounded-lg'>
                            <div
                              className='h-[0.35rem] btn-color-primary rounded-lg'
                              style={{ width: `${item.completedPercentage}%` }}
                            ></div>
                          </div>

                          <div className='icons h-8 flex gap-6 items-center'>
                            <div className='left group relative flex gap-2 select-none'>
                              <div className='icon'>
                                <Views />
                              </div>
                              <div className='stats flex gap-1'>
                                <span className='font-extrabold text-xs'>
                                  {item.visitorCount}
                                </span>
                                <span className='text-xs'>
                                  ({item.visitorPercentage}%)
                                </span>
                              </div>
                              <div
                                  className="opacity-0 flex flex-col gap-3 items-center p-1 invisible group-hover:opacity-100 group-hover:visible absolute top-5 bg-white text-gray-800 border duration-300 border-gray-300 rounded-md shadow-lg z-10">
                                  <p className='text-center w-20 text-[0.7rem] font-semibold'>Total Views</p>
                                </div>
                            </div>
                            <div className='right group flex relative gap-2 select-none'>
                              <div className='icon'>
                                <Vesponse />
                              </div>
                              <div className='stats flex gap-1'>
                                <span className='font-extrabold text-xs'>
                                  {item.completedCount}
                                </span>
                                <span className='text-xs'>
                                  ({item.completedPercentage}%)
                                </span>
                              </div>
                              <div
                                  className="opacity-0 flex flex-col gap-3 items-center p-1 invisible group-hover:opacity-100 group-hover:visible absolute top-5 bg-white text-gray-800 border duration-300 border-gray-300 rounded-lg shadow-lg z-10">
                                  <p className='text-center w-28 text-[0.7rem] font-semibold'>Total Responses</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='flex gap-2 w-full justify-end mb-0 pb-0 items-center'>
                        <div className='group relative'>
                        <button
                          type='button'
                          disabled={!item.selfEvaluationStatus}
                          onClick={()=>setModal(true)}
                          className={`inline-block rounded-lg text-white ${item.selfEvaluationStatus ? 'bg-[#95D8D0] card-btn-color-primary':'bg-[#7D9494] '} px-6 pb-2 pt-2.5 text-xs 2xl:text-sm font-medium leading-normal  duration-200`}
                        >
                          Get feedback
                        </button>
                        {
                          !item.selfEvaluationStatus&&
                          <div
                            className="opacity-0 flex flex-col gap-3 items-center p-1 invisible group-hover:opacity-100 group-hover:visible absolute top-10 bg-white text-gray-800 border duration-300 border-gray-300 rounded-md shadow-lg z-10">
                            <p className='text-center w-44 text-[0.7rem] font-semibold'>Evaluate yourself first to get feedback</p>
                        </div>}
                        </div>

                        <button
                          type='button'
                          onClick={()=>
                            item.selfEvaluationStatus === false
                          ? navigate(`/user/quiz/my-quiz/${item.id}`)
                          : navigate(`/user/quiz/re-evaluate/${item.id}`)
                          }
                          className={`inline-block rounded-lg text-white btn-color-primary px-6 pb-2 pt-2.5 text-xs 2xl:text-sm font-medium leading-normal  duration-200`}
                        >
                          {
                            item.selfEvaluationStatus?'Retake Quiz':'Take Quiz'
                          }
                        </button>
                        </div>
                      </div>
                    </TETabsPane>
                  );
                })}

                {/* //////////////////////// Friends Questionnaire multiple cards ///////////////////////////// */}
                {friendsQuestionnaires.map((item) => {
                  return (
                    <TETabsPane
                      onClick={() =>
                        item.status === 'pending' &&
                        navigate(
                          `/user/quiz/friends-quiz/${item.id}`
                        )
                      }
                      key={item.id}
                      className=' rounded-lg bg-white p-4 flex flex-col gap-3 lg:gap-4 relative cursor-pointer'
                      show={basicActive === 'my friends questionnaire'}
                    >
                      <div className='flex justify-between items-center text-[0.5rem] md:text-xs font-semibold'>
                        <span className='text-gray-500'>{item.createdAt}</span>
                        <p
                          className={`${
                            item.status === 'pending'
                              ? 'bg-red-100 text-[#E9793A]'
                              : 'text-[var(--primary-color)] bg-[#E4F9F3]'
                          } p-1 md:p-2 rounded-md md:rounded-lg text-[0.5rem] md:text-xs capitalize text-center font-semibold`}
                        >
                          {item.status}
                        </p>
                      </div>

                      <p className='text-xs md:text-sm font-semibold break-words'>
                      {item.name.replace(/\byou\b/gi,` ${item.createdBy.first_name} ${item.createdBy.last_name}`)}
                      </p>

                      <div className='user-container flex gap-2 lg:gap-3 items-center'>
                        <img
                          className='w-5 h-5 md:w-6 md:h-6 lg:w-8 lg:h-8 rounded-full'
                          src={item.createdBy.image}
                          alt='David jason'
                        />
                        <span className='user-name capitalize text-[0.6rem] sm:text-xs md:text-sm overflow-hidden'>
                          {item?.createdBy?.first_name}{' '}
                          {item?.createdBy?.last_name}
                        </span>
                      </div>
                    </TETabsPane>
                  );
                })}


                 {friendsQuestionnaires.length === 0 && basicActive ==='my friends questionnaire' &&
                <div className='w-full h-[calc(100vh-12rem)] col-span-6 flex justify-center items-center'>
                <p className='text-lg xl:text-2xl font-bold text-center'>There is no friends quiz</p> 
              </div>
              }
              
              </TETabsContent>

             
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Questionnaire;
