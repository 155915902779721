import React, { useEffect, useState } from 'react';
import './datePicker.css';
import { ReactComponent as AddAvatar } from '../images/add_avatar.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { authUser } from '../redux/slices/UserSlice';
import { useNavigate } from 'react-router-dom';
import { setUser } from '../redux/slices/UserSlice';
import { useDispatch } from 'react-redux';
import Loader from "../components/Loader";
import { setEmail } from '../redux/slices/LoginPageSlice';
import { setError } from '../redux/slices/LoginErrorSlice';
import { ToastContainer, toast } from 'react-toastify';


const EditProfile = () => {

  const navigate = useNavigate()

  const user = useSelector(authUser);
  const token = user.token;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false)
  

  const [countries, setCountries] = useState([])
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCountryValue, setselectedCountryValue] = useState({value:"",label:""});
  

  const [image, setImage] = useState();

  const bColor = 'var(--primary-color)';

  const [formErrors, setFormError] = useState({});

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    dob: '',
    city: '',
  });


  // ////////////////////////////// Gender Select ///////////////////////////////////
  const [selectedGenderValue, setselectedGenderValue] = useState({value:"",label:""});

  const genderOptions = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'other', label: 'Other' }
  ]


  const handleGenderChange = (selectedGender) => {
    setselectedGenderValue(selectedGender);
  };

// //////////////////////////////// Fetching Countries ///////////////////////////////////
  const fetchCountries = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/config/countries`, {
            method: "GET",
            headers: {
              'Content-Type': 'application/json',
            },
        });

        if (response.status === 401 || response.status===400) {
          localStorage.clear();
          dispatch(setError('Authentication failed. Please login again.'));
          dispatch(setUser(null));
          dispatch(setEmail(""));
          navigate("/");
      }

        if (response.ok) {
            const result = await response.json();
            setCountries([{ value: '', label: 'Select' }, ...result.data]);
            // return result.data<s
        }
    } catch (error) {
        console.log(error);
    }
};


  useEffect(() => {
    fetchCountries()
    setFormData({
      ...formData,
      phone:user.phone,
      city:user.city,
      first_name: user.first_name,
      last_name: user.last_name,
      dob: new Date(user.dob),
      // gender: user.gender
    })
    setImage(user.image)

    if(user.dob !== null){
      const userDobDate = new Date(user.dob);
      if (!isNaN(userDobDate.getTime())) {
        // Check if the parsed date is valid
        setSelectedDate(userDobDate);
      }
    }

    if(user?.country !== null) {
      setselectedCountryValue({
        value: user.country.id,
        label: user.country.name,
      })
    }

    if(user?.gender !== null) {
      setselectedGenderValue({
        value: user.gender,
        label: user.gender.charAt(0).toUpperCase() + user.gender.slice(1)
      })
    }
    
  }, [navigate]);


  const handleSelectChange = (selectedOption) => {
    setselectedCountryValue(selectedOption);
  };

  

  /// ///////////////////// onChange Handling //////////////////////////////////////
  const handleChange = (e) => {
    // Getting filed name & value properties from event

    // if (e.target === undefined) {
    //   formData.countryId = e.value;
    // } else {
      setFormError({});
      const name = e.target.name;
      const value =
          e.target.type === 'checkbox' ? e.target.checked : e.target.value;

      // Setting values respectively to field name
      setFormData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    // }
  };

  /// //////////////////////////// Setting image in useState() //////////////////////////////////
  const handleImageUpload = async (e) => {

    setIsLoading(true);

    if(e.target.files.length !== 0){

      if(e.target.files[0].size / (1024 ** 2) > 5){
        toast('Image size is too large, try to upload image of size less than 5 Mbs')
      }
      else{
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user-image`,
        {
          image:e.target.files[0]
        }, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${user?.token}`,
             
          },
        });
          setImage(response.data.data.url);
      } 
    }
    setIsLoading(false)
  };

  /// //////////////////////// Form Submition //////////////////////////////////
  const handleSubmit = async (e) => {
    e.preventDefault()

    let errors = {}
    if (formData.first_name === '') {
      errors.first_name = 'First Name is required.'
  }else if(formData.first_name.length < 3) {
    errors.first_name_length = 'First name should be at least 3 characters'
  }

  if (formData.last_name === '') {
      errors.last_name = 'Last Name is required.'
  }else if(formData.last_name.length < 3) {
    errors.last_name_length = 'Last name should be at least 3 characters'
  }

  if (formErrors.length !== 0) {
    setFormError(errors)
}

    if (selectedDate) {
      formData.dob = `${selectedDate.getFullYear()}-${selectedDate.toLocaleString('default', { month: 'short' })}-${
          selectedDate.getDate()
      }`;
    }
    else{
      formData.dob = null
    }
    formData.countryId = selectedCountryValue.value;
    formData.gender = selectedGenderValue.value;

    if(Object.keys(errors).length === 0){
      setIsLoading(true)
      const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/user-profile`, formData,{
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.token}`,
        },
      })
      if(response.status === 200){
        let resData = response.data.data
        const updatedData = {...resData, token:token}
        dispatch(setUser(updatedData))
        if(user.role === 'user') {
          navigate('/user/profile')
        }else{
          navigate('/admin/profile')
        }
      }
    }
  };

  return (
      <>
        {isLoading ? (
            <Loader />
        ) : (
      <div className='w-[100vw] md:w-[calc(97.8vw-223px)] lg:w-[calc(97.8vw-217px)] 2xl:w-[calc(98.7vw-220px)] h-[calc(100vh-3.6rem)] md:h-[calc(100vh-4rem)] flex md:ml-60 bg-gray-100 overflow-y-scroll no-scrollbar'>
        <div className='w-full h-[calc(100vh-4rem)] px-4 md:px-10 py-4 flex justify-center'>
        <ToastContainer
        hideProgressBar={true}
        pauseOnHover
        />
          <div className='main w-full h-fit bg-white p-7 rounded-xl relative'>
            {/* //////////////////////////// profile picture ////////////////////////////////// */}

            <div className='flex flex-col items-center justify-center gap-2 mt-14 mb-5'>
              <div className='flex justify-center'>
                <div className='right flex w-fit items-center relative'>
                  {/*{user.image ? (*/}
                      <img
                          className='w-[4.5rem] h-[4.5rem] rounded-full'
                          src={image}
                          alt=''
                          id='profile-pic'
                      />
                  {/*) : (*/}
                  {/*    <img*/}
                  {/*        className='w-[4.5rem] h-[4.5rem] rounded-full'*/}
                  {/*        src={carduser}*/}
                  {/*        alt=''*/}
                  {/*        id='profile-pic'*/}
                  {/*    />*/}
                  {/*)}*/}
                  <label
                      type='file'
                      htmlFor='input-file'
                      className='absolute w-2 h-2 rounded-full right-4 bottom-4 cursor-pointer'
                  >
                    <AddAvatar />
                  </label>
                  <input
                      onChange={handleImageUpload}
                      className='hidden'
                      type='file'
                      accept='image/jpeg, image/png, image/jpg'
                      id='input-file'
                  />
                </div>
              </div>
              <h3 className='text-2xl font-bold'>{`${user.first_name} ${user.last_name}`}</h3>
            </div>
            {/* ///////////////////////////////// Form ///////////////////////////////////////////  */}
            <form
                onSubmit={handleSubmit}
                className='form grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4'
            >
              <button
                  type='submit'
                  className='absolute top-6 right-5 focus:outline-none w-28 text-white btn-color-primary btn-color-primary font-medium rounded-lg text-sm 2xl:text-base px-5 py-2.5 me-2'
              >
                Update
              </button>

              {/* /////////////////////////////////// First Name ///////////////////////////////////// */}
              <div className='inp flex flex-col gap-1'>
                <label
                    className='text-sm 2xl:text-base text-gray-400'
                    htmlFor='first_name'
                >
                  First Name <span className='text-red-500 font-bold ml-1'>*</span>
                </label>
                <input
                    value={formData.first_name}
                    onChange={handleChange}
                    className={`p-3 outline-none border-[1px] rounded-lg text-sm 2xl:text-base  fill-none ${
                      formErrors.first_name ? "border-red-500 focus:border-red-500":'focus-border-primary'
                    }`}
                    type='text'
                    placeholder='Enter your first name'
                    name='first_name'
                    maxLength={20}
                />
                <span className="text-xs font-semibold text-red-500">
                {formErrors.first_name}
              </span>
              <span className="text-xs font-semibold text-red-500">
                {formErrors.first_name_length}
              </span>
              </div>
              {/* //////////////////////////////////// Last Name ///////////////////////////////////// */}
              <div className='inp flex flex-col gap-1'>
                <label
                    className='text-sm 2xl:text-base text-gray-400'
                    htmlFor='last_name'
                >
                  Last Name <span className='text-red-500 font-bold ml-1'>*</span>
                </label>
                <input
                    value={formData.last_name}
                    onChange={handleChange}
                    className={`p-3 outline-none border-[1px] rounded-lg text-sm 2xl:text-base ${
                      formErrors.last_name ? "border-red-500 focus:border-red-500":'focus-border-primary'
                    }`}
                    type='text'
                    placeholder='Enter your last name'
                    name='last_name'
                    maxLength={20}
                />
                <span className="text-xs font-semibold text-red-500">
                {formErrors.last_name}
              </span>
              <span className="text-xs font-semibold text-red-500">
                {formErrors.last_name_length}
              </span>
              </div>
            {/* ///////////////////////// Country select /////////////////////////////////////////// */}
            <div className='flex flex-col gap-1'>
                <label
                    className='text-sm 2xl:text-base text-gray-400'
                    htmlFor='country'
                >
                  Country
                </label>
                <Select
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        boxShadow: state.isFocused ? 0 : 0,
                        border: '1px solid #E5E7EB',
                        borderRadius: '8px',
                        padding: '5.5px',
                        borderColor: state.isFocused
                            ? 'brandColor'
                            : base.borderColor,
                        '&:hover': {
                          borderColor: state.isFocused ? bColor : base.borderColor,
                        },
                      }),

                      placeholder: (baseStyles) => ({
                        ...baseStyles,
                        color: '#9CA3AF',
                      }),
                      option: (base, { isFocused }) => {
                        return {
                          ...base,
                          padding: '2',
                          backgroundColor: isFocused ? '#1967D2' : 'white',
                          color: isFocused ? 'white' : 'black',
                        };
                      },
                      menu: (baseStyles) => ({
                        ...baseStyles,
                        padding: '6px',
                      }),
                    }}

                    name='country'
                    className='rounded-lg text-sm 2xl:text-base border-primary-color'
                    defaultValue={formData.country}
                    onChange={handleSelectChange}
                    value={selectedCountryValue}
                    options={countries.sort((a, b) => a.label.localeCompare(b.label))}
                    maxMenuHeight={170}
                />
              </div>
              

               {/* /////////////////////////////////// Email //////////////////////////////////// */}
               <div className='inp flex flex-col gap-1'>
                <label
                    className='text-sm 2xl:text-base text-gray-400'
                    htmlFor='email'
                >
                  Email
                </label>
                <input
                    disabled={true}
                    value={user.email}
                    className='p-3 outline-none border-[1px] rounded-lg text-sm 2xl:text-base focus:shadow-green-500 shadow-sm'
                    type='email'
                    placeholder='Enter your email'
                    name='email'
                />
              </div>
              {/* ///////////////////////// Gender select /////////////////////////////////////////// */}


              <div className='flex flex-col gap-1'>
                <label
                    className='text-sm 2xl:text-base text-gray-400'
                    htmlFor='gender'
                >
                  Gender
                </label>
                <Select
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        boxShadow: state.isFocused ? 0 : 0,
                        border: '1px solid #E5E7EB',
                        borderRadius: '8px',
                        padding: '5.5px',
                        borderColor: state.isFocused
                            ? 'brandColor'
                            : base.borderColor,
                        '&:hover': {
                          borderColor: state.isFocused ? bColor : base.borderColor,
                        },
                      }),

                      placeholder: (baseStyles) => ({
                        ...baseStyles,
                        color: '#9CA3AF',
                      }),
                      option: (base, { isFocused }) => {
                        return {
                          ...base,
                          padding: '2',
                          backgroundColor: isFocused ? '#1967D2' : 'white',
                          color: isFocused ? 'white' : 'black',
                        };
                      },
                      menu: (baseStyles) => ({
                        ...baseStyles,
                        padding: '6px',
                      }),
                    }}

                    name='gender'
                    className='rounded-lg text-sm 2xl:text-base focus-border-primary'
                    defaultValue={formData?.gender}
                    onChange={handleGenderChange}
                    value={selectedGenderValue}
                    options={genderOptions}
                    maxMenuHeight={170}
                />
              </div>
              {/* /////////////////////////// date picker //////////////////////////////////// */}
              <div className='date flex flex-col gap-1'>
                <label
                    className='text-sm 2xl:text-base text-gray-400'
                    htmlFor='dob'
                >
                  Date of Birth
                </label>
                <DatePicker
                    name='dob'
                    className='p-3 outline-none border-[1px] w-full rounded-lg text-sm 2xl:text-base focus-border-primary'
                    onChange={(date) => setSelectedDate(date)}
                    selected={selectedDate}
                    showYearDropdown
                    scrollableYearDropdown={true}
                    yearDropdownItemNumber={30}
                    dateFormat='yyyy-MM-dd'
                    placeholderText='YYYY-MM-dd'
                    maxDate={new Date()}
                    
                />
              </div>
              {/* //////////////////////////////////// City //////////////////////////////////// */}
              {/* <div className='inp flex flex-col gap-1'>
                <label
                    className='text-sm 2xl:text-base text-gray-400'
                    htmlFor='city'
                >
                  City
                </label>
                <input
                    value={formData.city}
                    onChange={handleChange}
                    className='p-3 outline-none border-[1px] rounded-lg text-sm 2xl:text-base focus-border-primary'
                    type='text'
                    placeholder='Enter your city'
                    name='city'
                    maxLength={25}
                />
              </div> */}

              
              {/* //////////////////////////////////// Phone ///////////////////////////////////// */}
                {/* <div className='inp flex flex-col gap-1'>
                <label
                    className='text-sm 2xl:text-base text-gray-400'
                    htmlFor='phone'
                >
                  Phone
                </label>
                <input
                    value={formData.phone}
                    onChange={handleChange}
                    className='p-3 outline-none border-[1px] rounded-lg text-sm 2xl:text-base focus-border-primary'
                    type='phone'
                    placeholder='Enter your phone'
                    name='phone'
                    maxLength={12}
                />

              </div> */}

            </form>
          </div>
        </div>
      </div>
        )}
      </>)
};

export default EditProfile;
