import React, {useEffect, useState} from "react";
import Logo from "../images/Logo.png";
import {Link, useNavigate} from "react-router-dom";
import { ReactComponent as SentIcon } from "../images/Group 109.svg";
import {selectEmail} from "../redux/slices/LoginPageSlice";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";

const EmailSent = () => {
  const email = useSelector(selectEmail);
  const navigate = useNavigate();


  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if(email === "" || email === null){
      navigate('/')
    }
  }, [email, navigate]);

  const handleResetEmail = async () => {
    setIsLoading(true)
    const data = {
      email:email
    }

    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
         
      },
      body: JSON.stringify(data),
    });

    if(response.ok){
      setIsSuccess(true);
      setIsLoading(false);
    }
  }


  return (
      <div>
        {isLoading ? <Loader />:``}
    <div className="signup-main h-screen w-full flex flex-col md:flex-row ">
      {/* ////////////////////////////////// Left portion //////////////////////////////////// */}
      <div className="flex-col items-end h-screen w-2/4 bg-[url('/src/images/signup-image.jpg')] bg-cover bg-center justify-center hidden md:block relative">
        <p className="text-white text-2xl mx-20 absolute bottom-20">
          Gather the feedback you need from the people you trust most
        </p>
      </div>

      <div className="signup-form w-full md:w-2/4  flex flex-col h-screen justify-around py-4 items-center">
        {/* ////////////////// LOGO Portion ////////////////////// */}
        <div className="logo-container w-[9.063rem] h-[2.5rem]">
          <img className="w-full" src={Logo} alt="" />
        </div>

        {/* ////////////////////////////////// Main form protion //////////////////////////////// */}
        <div className="form-container w-2/3 flex flex-col gap-10 mb-28">
          <div className="heading-container w-full flex flex-col items-center">
            <SentIcon className="mb-7" />
            <h3 className="text-[1.6rem] font-bold pb-2">Login Link Sent</h3>
            <p className="text-sm w-[70%] md:w-[80%] md:text-base text-center">
              Please click on the link in the email we just sent you to start
              your experience at our platfrom
            </p>
          </div>

          <div className="flex flex-col gap-6 items-center">
            <button
              onClick={() => handleResetEmail()}
              type="button"
              className={`focus:outline-none w-full text-white btn-color-primary  font-medium rounded-lg text-sm p-2 btn-color-primary`}
            >
              Resend Email
            </button>
          </div>
          {isSuccess &&
              (<div className="flex flex-col border border-solid rounded border-primary-color text-primary-color text-white items-center px-5">
            Login email resent successfully!!
          </div>)}
        </div>

        {/* /////////////////////// Terms and conditons portion ///////////////////////// */}
        <div className="privacy-container">
          <p className="text-[0.75rem]">
            <Link>
              <span className="font-bold">Privacy Policy</span>
            </Link>{''}
            .{''}
            <Link>
              <span>Terms and Conditions</span>
            </Link>{''}
            .{''}
            <Link>
              <span>GDPR</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
      </div>
  );
};

export default EmailSent
