import React from "react";
import { ReactComponent as AddAvatar } from "../images/add_avatar.svg";
import carduser from "../images/profilePlaceholder.png";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { authUser, setUser } from "../redux/slices/UserSlice";
import Loader from "../components/Loader";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Colors } from "../colors/Colors";
const CompleteProfileModalWindow = ({ show }) => {
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const user = useSelector(authUser);
  const [formErrors, setFormError] = useState({});
  const questionnaireId = localStorage.getItem('questionnaireId');
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    privacyPolicy: true,
  });

  const dispatch = useDispatch();
  /////////////////////////////// Setting image in useState() ////////////////////////////////////
  const handleImageUpload = async (e) => {

if(e.target.files.length !== 0){

if(e.target.files[0].size / (1024 ** 2) > 5){
  toast('Image size is too large, try to upload image of size less than 5 Mbs')
}else{
  setImage(e.target.files[0]);
  await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/user-image`,
    {
      image: e.target.files[0],
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user?.token}`,
      },
    }
  );
}
}else{
  toast('Please select image')
}
  };

  //////////////////////// onChange Handling //////////////////////////////////////
  const handleChange = (e) => {
    // Getting field name & value properties from event

    setFormError({});
    const name = e.target.name;
    if (name === "privacyPolicy") {
      const value =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;

      // Setting values respectively to field name
      setFormData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    } else {
      const value = e.target.value;

      // Setting values respectively to field name
      setFormData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };


//   ////////////////////////////////////// Form Submittion //////////////////////////////////////////////
const handleSubmit = async (e) => {
    e.preventDefault();

    let errors = {};
    if (formData.first_name === '') {
        errors.first_name = 'First Name is required.'
    }else if(formData.first_name.length < 3) {
      errors.first_name_length = 'First name should be at least 3 characters'
    }

    if (formData.last_name === '') {
        errors.last_name = 'Last Name is required.'
    }else if(formData.last_name.length < 3) {
      errors.last_name_length = 'Last name should be at least 3 characters'
    }

  
    if (formData.privacyPolicy === false) {
        errors.privacyPolicy = 'You must agree to our privacy policy.'
    }

    if (formErrors.length !== 0) {
        setFormError(errors)
    }

    if (Object.keys(errors).length === 0) {
        
        setIsLoading(true);
        const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/user-profile`,
            formData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.token}`,
                }
            });

        if (response.status === 200) {
            if(user.first_name === null){
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-profile`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${user?.token}`,
                        "Content-Type": "application/json",
                         
 
                    },
                });

                if (response.ok) {
                    const result = await response.json();
                    const data = {
                        ...result.data,
                        token: user?.token,
                    };
                    localStorage.setItem("user", JSON.stringify(data));
                    dispatch(setUser(data));
                }
            }
            if(questionnaireId !== null){
                navigate(`/user/questionnaire/${questionnaireId}`)
            } else {
                navigate('/user/dashboard');
            }
        }

        if(response.status === 401 || response.status===400){
            navigate('/');
        }
    }

};


  if (show) {
    return (
        
      <div className="modal-main fixed top-0 left-0 z-50 right-0 bottom-0 bg-black bg-opacity-30 flex justify-center items-center">
        {isLoading ? <Loader />:``}
        <ToastContainer
        hideProgressBar={true}
        pauseOnHover
        />
        <div className="modal w-96 p-9 rounded-xl flex flex-col gap-6 m-4 bg-white relative">
          <h2 className="text-2xl font-bold text-center">Yes, you&apos;re almost there!</h2>

          {/* ////////////////////////////// Profile Picture //////////////////////////////////// */}
          <div className="flex justify-center">
            <div className="right flex w-fit items-center relative">
              {image ? (
                <img
                  className="w-[4.5rem] h-[4.5rem] rounded-full"
                  src={URL.createObjectURL(image)}
                  alt=""
                  id="profile-pic"
                />
              ) : (
                <img
                  className="w-[4.5rem] h-[4.5rem] rounded-full"
                  src={carduser}
                  alt=""
                  id="profile-pic"
                />
              )}
              <label
                type="file"
                htmlFor="input-file"
                className="absolute w-2 h-2 rounded-full right-4 bottom-4 cursor-pointer"
              >
                <AddAvatar />
              </label>
              <input
                onChange={handleImageUpload}
                className="hidden"
                type="file"
                accept="image/jpeg, image/png, image/jpg"
                id="input-file"
              />
            </div>
          </div>


{/* ////////////////////////////////// Form /////////////////////////////////// */}
          <form onSubmit={handleSubmit} className=" w-full flex flex-col gap-4">
            {/* //////////////////////////////////// First Name ///////////////////////////////////////////// */}
            <div className="inp flex flex-col gap-1">
              <label className="text-sm" htmlFor="first_name">
                First Name *
              </label>
              <input
                onChange={handleChange}
                className={`p-3 outline-none border-[1px] rounded-lg text-sm fill-none  ${
                  formErrors.first_name ? "border-red-500 focus:shadow-red-500":'focus-border-primary'
                }`}
                type="text"
                placeholder="Enter your first name"
                name="first_name"
                maxLength={15}
              />
              <span className="text-xs font-semibold text-red-500">
                {formErrors.first_name}
              </span>
              <span className="text-xs font-semibold text-red-500">
                {formErrors.first_name_length}
              </span>
            </div>

            {/* ///////////////////////////////// Last Name /////////////////////////////////////// */}
            <div className="inp flex flex-col gap-1">
              <label className="text-sm" htmlFor="last_name">
                Last Name *
              </label>
              <input
                onChange={handleChange}
                className={`p-3 outline-none border-[1px] ${
                  formErrors.last_name && "border-red-500"
                } rounded-lg text-sm focus-border-primary`}
                type="text"
                placeholder="Enter your last name"
                name="last_name"
                maxLength={15}
              />
              <span className="text-xs font-semibold text-red-500">
                {formErrors.last_name}
              </span>
              <span className="text-xs font-semibold text-red-500">
                {formErrors.last_name_length}
              </span>
            </div>

            {/* ////////////////////////////// Privacy Check /////////////////////////////////// */}
            <div className="flex flex-col">
              <div className="terms&conditions-container flex gap-3 items-center ">
                <div className="">
                  <input
                    className="accent-[var(--primary-color)] w-4 h-4"
                    type="checkbox"
                    name="privacyPolicy"
                    checked={true}

                  />
                </div>
                <p className="text-xs">
                  I&apos;ve read and agreed to{" "}
                  <Link>
                    <span className="text-primary-no-hover">Privacy Policy</span>
                  </Link>
                  ,{" "}
                  <Link>
                    <span className="text-primary-no-hover">Terms and Conditions</span>
                  </Link>{" "}
                  and{" "}
                  <Link>
                    <span className="text-primary-no-hover">GDPR</span>
                  </Link>
                  .
                </p>
              </div>
              <span className="text-xs font-semibold text-red-500">
                {formErrors.privacyPolicy}
              </span>
            </div>

            {/* //////////////////////////// Save button ///////////////////////////////// */}
              <button
                type="submit"
                className={`focus:outline-none w-full text-white btn-color-primary font-medium rounded-lg text-sm p-2  `}
              >
                Save and Continue
              </button>
          </form>
        </div>
      </div>
    );
  }
};

CompleteProfileModalWindow.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};
export default CompleteProfileModalWindow;
