import React, {useEffect} from "react";
import Logo from "../images/Logo.png";
import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";
import Loader from "../components/Loader";
import {useDispatch, useSelector} from "react-redux";
import { setEmail } from "../redux/slices/LoginPageSlice";
import {authUser, setUser} from "../redux/slices/UserSlice";
import {selectError} from "../redux/slices/LoginErrorSlice";
import ReCAPTCHA from "react-google-recaptcha";



const Signup = () => {
    const dispatch = useDispatch();
    const user = useSelector(authUser);
    const error = useSelector(selectError);

    const [userEmail, setUserEmail] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [location, setLocation] = useState({});
    const [verified, setVerified] = useState(false);

    useEffect(() => {
        if(user !== null){
            if(user?.role === 'user') {
                navigate('/user/dashboard')
            }else if(user?.role === 'admin') {
                navigate('/admin/dashboard')
            }
        }
        // dispatch(setUser(""))
        // dispatch(setQuestionnaireId(null))
    }, []);

    const onChange = (value) => {

        setVerified(true)
    }
    const validateEmail = () => {
        if (userEmail === "") {
            setErrorMsg("Please enter email");
            return (false)
        }
        if (userEmail.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
            setErrorMsg('')
            return (true)
        }
        setErrorMsg('You have entered an invalid email address!')
        return (false)
    };
    const navigate = useNavigate();
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateEmail()) {
            setIsLoading(true)
            const postData = {
                email:userEmail.toLowerCase(),
            }

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                     
                     
                },
                body: JSON.stringify(postData),
            });

        
            if(response.ok){
                dispatch(setEmail(userEmail))
                navigate('/email-sent')
            }else{
              setIsLoading(false)
             const res = await response.json()
              setErrorMsg(res.message)
            }
        }
    }


    return (
        <div>
          {isLoading ? <Loader /> : null}
          <div className="signup-main h-screen w-full flex flex-col md:flex-row overflow-y-scroll">
            <div className="signup-main h-screen w-full flex flex-col md:flex-row ">
              {/* ////////////////////////////////// Left portion //////////////////////////////////// */}
              <div className="flex-col items-end h-screen w-2/4 bg-[url('/src/images/signup-image.jpg')] bg-cover bg-center justify-center hidden md:block relative">
                <p className="text-white text-2xl mx-20 absolute bottom-20">
                  Gather the feedback you need from the people you trust most
                </p>
              </div>
              {/* ///////////////////////////////// Right portion  ///////////////////////////////////////////// */}
              <div className="signup-form w-full md:w-2/4 lg:px-16 flex flex-col h-screen gap-4 justify-around py-4 items-center">
                {/* ////////////////// LOGO Portion ////////////////////// */}
                <div className="logo-container w-[10rem] lg:w-[12rem]">
                  <img className="w-full" src={Logo} alt="" />
                </div>
                <div className="heading-container w-full flex flex-col items-center">
                  <h3 className="text-[1.4rem] lg:text-[1.6rem] font-bold tracking-wide">Welcome! 👋</h3>
                  <p className="text-sm w-[60%] md:w-[60%] md:text-base text-center">
                  It&apos;s great to have you here. An amazing experience awaits you
                  </p>
                </div>
                  {error !== "" && <span className="text-red-500 text-sm md:text-base">{error}</span>}
                {/* ////////////////////////////////// Main form portion //////////////////////////////// */}
                <div className="form-container w-full px-6 lg:px-0 md:w-9/12 lg:w-2/3 flex flex-col mb-16 lg:mb-28">
                  <form onSubmit={handleSubmit} className="flex flex-col gap-6">
                    <div className="input">
                      <label
                        htmlFor="email"
                        className="block mb-2 text-base font-medium text-gray-900 dark:text-white"
                      >
                        Email*
                      </label>
                      <input
                        type="text"
                        id="email"
                        className={`p-2.5 block w-full rounded-lg outline-none border  ${errorMsg!==''?'border-red-500':'focus-border-primary'}`}
                        placeholder="Enter your email"
                        onChange={(e) => {
                          setUserEmail(e.target.value);
                        }}
                      />

                      <span className="text-xs font-semibold text-red-500">{errorMsg}</span>
                    </div>
                      <div className="input">
                      <ReCAPTCHA sitekey='6Lc23EkpAAAAAD-68RPqgHpwJaFcUDH-e9RxtcPq'   onChange={onChange} style={{ display: 'flex', justifyContent: 'center' }} />
                      </div>
                    <button
                      type="submit" disabled={!verified}
                      className={ `focus:outline-none w-full text-white btn-color-primary font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 btn-color-primary ${!verified && 'opacity-50 cursor-not-allowed'}`  }
                    >
                      Let’s get started
                    </button>
                  </form>
                </div>
                {/* /////////////////////// Terms and conditions portion ///////////////////////// */}
                <div className="privacy-container">
                  <p className="text-[0.75rem]">
                    <Link>
                      <span className="font-bold">Privacy Policy</span>
                    </Link>{" "}
                    .{" "}
                    <Link>
                      <span>Terms and Conditions</span>
                    </Link>{" "}
                    .{" "}
                    <Link>
                      <span>GDPR</span>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
                    }
export default Signup
