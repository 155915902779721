import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../components/Loader";
import {setUser} from "../redux/slices/UserSlice";
import navbarGradient from "@material-tailwind/react/theme/components/navbar/navbarGradient";
import {setEmail} from "../redux/slices/LoginPageSlice";
import {setQuestionnaireId} from "../redux/slices/QuestionnaireIdSlice";
import {setError} from "../redux/slices/LoginErrorSlice";


const Auth = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    dispatch(setError(''));

    const [isLoading, setIsLoading] = useState(true);

    const itemKey = 'questionnaireId';
    const itemExists = localStorage.getItem(itemKey) !== null;

    const questionnaireId = localStorage.getItem('questionnaireId');

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-profile`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                });
                if (response.status === 401 || response.status===400) {
                    localStorage.clear();
                    dispatch(setError('Authentication failed. Please login again.'));
                    dispatch(setUser(null));
                    dispatch(setEmail(""));
                    dispatch(setQuestionnaireId(""));
                    navigate("/");
                }
                
                if (response.ok) {
                    const result = await response.json();
                    const data = {
                        ...result.data,
                        token: token,
                    };

                    localStorage.setItem("user", JSON.stringify(data));
                    dispatch(setUser(data));
                    if(data.role === 'admin'){
                        navigate('/admin/dashboard')
                    }
                    else if(result.data.first_name === null) {
                        navigate("/complete-profile");
                    } else {
                        if(!itemExists){
                            navigate("/user/dashboard");
                        } else {
                            navigate(`/user/questionnaire/${questionnaireId}`)
                        }
                    }

                } else {
                    navigate("/");
                }
            } catch (error) {
                // navigate("/");
                console.log(error,'error')
            } finally {
                // This block will execute regardless of success or failure
                setIsLoading(false);
            }
        };
// console.log(token,'token');
        // Call the asynchronous function
        fetchUserData();

    }, [navigate, dispatch, token]);

    return (
        <div>
            {isLoading && <Loader />}

        </div>
    );
};

export default Auth;
