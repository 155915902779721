import React, { useEffect, useState } from 'react';
import './datePicker.css';
import carduser from '../images/profilePlaceholder.png';
import freeBadge from '../images/free_ic.svg';
import goldBadge from '../images/gold_ic.svg';
import diamondBadge from '../images/diamond_ic.svg';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authUser } from '../redux/slices/UserSlice';

const Profile = () => {

  const [user, setUser] = useState({
    email: '',
    phone: '',
    gender: '',
    dob: '',
    city: '',
    country: '',
    image: ''
  })
 
  // const currentUser = useSelector(authUser);
  // ///////////////////////// Getting current user from redux store ///////////////////////////// 
  const currentUser = useSelector((state)=>state.user)

  const navigate = useNavigate();


  /////////////////////////////// Setting image in useState() ////////////////////////////////////
  const handleImageUpload = (e) => {
    setImage(e.target.files[0]);
  };


  // //////////// Updating user data in the state to populate on the fields
  useEffect(()=>{
    setUser({
      ...user,
      email: currentUser.email,
      phone: currentUser.phone,
      gender: currentUser.gender,
      dob: currentUser.dob,
      city: currentUser.city,
      country: currentUser.country,
      image: currentUser.image,
    });
  }, [])


  return (
      <div className='w-[100vw] md:w-[calc(97.8vw-223px)] lg:w-[calc(97.8vw-217px)] 2xl:w-[calc(98.7vw-220px)] h-[calc(100vh-3.6rem)] md:h-[calc(100vh-4rem)] flex md:ml-60 bg-gray-100 overflow-y-scroll md:overflow-y-hidden'>
        <div className='w-full h-[calc(100vh-4rem)] px-4 md:px-10 py-4 flex justify-center'>
          <div className='main w-full h-fit bg-white p-7 lg:pl-[6rem] rounded-xl'>
            <div className='flex justify-end items-center'>
              <button
                  type='button'
                  onClick={() => currentUser.role === 'user' ? navigate('/user/profile/edit'): navigate('/admin/profile/edit')}
                  className='text-sm 2xl:text-base border-[1.5px] rounded-lg py-1 px-4  border-primary-color text-primary-color '
                  data-te-ripple-init
              >
                Edit Profile
              </button>
            </div>

            {/* //////////////////////////// profile picture ////////////////////////////////// */}
            <div className='flex flex-col items-center justify-center mt-[1.4rem] mb-5 z-0'>
              <div className='right flex w-fit items-center relative'>
                <img
                    className='w-[4.5rem] h-[4.5rem] rounded-full'
                    src={user.image}
                    alt=''
                    id='profile-pic'
                />
                {currentUser.role !== 'admin' && (
                    <>
                      {currentUser.plan === 'free' && (
                          <img
                              className='w-5 h-5 absolute right-0 bottom-0'
                              src={freeBadge}
                              alt=''
                          />
                      )}
                      {currentUser.plan === 'Gold' && (
                          <img
                              className='w-5 h-5 absolute right-0 bottom-0'
                              src={goldBadge}
                              alt=''
                          />
                      )}
                      {currentUser.plan === 'Diamond' && (
                          <img
                              className='w-5 h-5 absolute right-0 bottom-0'
                              src={diamondBadge}
                              alt=''
                          />
                      )}
                    </>
                )}

                <input
                    onChange={handleImageUpload}
                    className='hidden'
                    type='file'
                    accept='image/jpeg, image/png, image/jpg'
                    id='input-file'
                />
              </div>
              <h3 className='text-2xl font-bold'>{`${currentUser.first_name} ${currentUser.last_name}`}</h3>
            </div>
            {/* ///////////////////////////////// Form ///////////////////////////////////////////  */}

            <div className='form grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3  gap-7'>
              <div className='inp flex justify-between sm:flex-col gap-1'>
                <label
                    className='text-sm 2xl:text-base text-gray-400'
                    htmlFor='email'
                >
                  Email
                </label>
                <input
                    value={user.email}
                    readOnly
                    className='outline-none text-xs 2xl:text-sm  text-right sm:text-left cursor-default '
                    name='email'
                />
              </div>
              {/* ///////////////////////////////////////////////////////////////////////// */}
              {/* <div className='inp flex justify-between sm:flex-col gap-1'>
                <label
                    className='text-sm 2xl:text-base text-gray-400'
                    htmlFor='phone'
                >
                  Phone
                </label>
                <input
                    value={user.phone !== "" ? user.phone : 'N/A'}
                    readOnly
                    className='outline-none text-xs 2xl:text-sm  text-right sm:text-left cursor-default'
                    name='phone'
                />
              </div> */}
              {/* ///////////////////////// Gender select /////////////////////////////////////////// */}
              <div className='flex justify-between sm:flex-col gap-1'>
                <label
                    className='text-sm 2xl:text-base text-gray-400'
                    htmlFor='gender'
                >
                  Gender
                </label>
                <input
                    readOnly
                    value={user.gender !== "" ?  user.gender : 'N/A'}
                    className='outline-none text-xs 2xl:text-sm  text-right sm:text-left cursor-default capitalize'
                    name='gender'
                ></input>
              </div>
              {/* /////////////////////////// date picker //////////////////////////////////// */}
              <div className='flex justify-between sm:flex-col gap-1'>
                <label
                    className='text-sm 2xl:text-base text-gray-400'
                    htmlFor='dateofbirth'
                >
                  Date of Birth
                </label>
                <input
                    value={user.dob !== "" ? user.dob : 'N/A'}
                    readOnly
                    name='dateofbirth'
                    className='outline-none text-xs 2xl:text-sm  text-right sm:text-left cursor-default'
                />
              </div>
              {/* //////////////////////////////////////////////////////////////////////// */}
              {/* <div className='inp flex justify-between sm:flex-col gap-1'>
                <label
                    className='text-sm 2xl:text-base text-gray-400'
                    htmlFor='city'
                >
                  City
                </label>
                <input
                    value={user.city ? user.city : 'N/A'}
                    readOnly
                    className='outline-none text-xs 2xl:text-sm  text-right sm:text-left cursor-default capitalize'
                    name='city'
                />
              </div> */}

              {/* ///////////////////////// Country select /////////////////////////////////////////// */}
              <div className='inp flex justify-between sm:flex-col gap-1'>
                <label
                    className='text-sm 2xl:text-base text-gray-400'
                    htmlFor='country'
                >
                  Country
                </label>
                <input
                    readOnly
                    value={user?.country !== null ? user?.country?.name : 'N/A'}
                    name='country'
                    className='outline-none text-xs 2xl:text-sm text-right sm:text-left cursor-default capitalize'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Profile;
